import React, { useState, useEffect } from "react";
import "./Signup.css";
import { FormGroup, FormControl, ControlLabel, Collapse, Well } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import axios from 'axios';

export default function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // const [confirmation, setConfirmation] = useState("");
    // const [newUser, setNewUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [signupRequirements, setSignupRequirements] = useState("");

    function validateForm() {
        return (
            email.length > 0 && 
            password.length > 7 &&
            confirmPassword === password 
        );
    }

    // props.setShowError(false);

    // function validateConfirmationForm() {
    //     return confirmation.length>0;
    // }

    useEffect(() => {
        if(email.length>0 && password.length > 0 && password.length < 8 && confirmPassword===password) {
            setSignupRequirements("Password must be at least 8 characters");
        }
        else if(password.length > 7 && confirmPassword===password && email.length===0) {
            setSignupRequirements("Please enter an email address for your login");
        }
        else if(password.length > 7 && email.length > 0 && confirmPassword!==password) {
            setSignupRequirements("Please make sure your passwords match");
        }
        else if(password.length >7 && email.length > 0 && confirmPassword===password) {
            setSignupRequirements("");
        }
    },[email,password,confirmPassword]);

    async function handleSignup(event) {
        event.preventDefault();

        setIsLoading(true);

        // setNewUser("test");
        const fixedEmail = email.trim().toLocaleLowerCase();

        axios.post(
            `${process.env.REACT_APP_API_URL}/users`,
            {"email":fixedEmail,"password":password},
            {headers:{'Content-Type':'application/json','accept':'application/json'}}
        ).then(response => {
            props.setErrorStatus("success-message");
            props.setErrorMessage("Account Created, please login")
            props.setShowError(true);
            props.history.push('/login');
        }).catch(error => {
            if(error.response.status === 500) {
                props.setErrorMessage("User already exists, please try logging in");
                props.history.push('/login');
            } else {
                props.setErrorMessage("Unknown error, please try again later");
            }
            props.setErrorStatus("error-message");
            props.setShowError(true);
            console.log(error);
            setIsLoading(false);
        })

        setIsLoading(false);
    }

    // async function handleConfirmationSubmit(event) {
    //     event.preventDefault();
        
    //     setIsLoading(true);
    // }

    // function renderConfirmationForm() {
    //     return (
    //         <form onSubmit={handleConfirmationSubmit}>
    //             <FormGroup controlId="confirmationCode" bsSize="large">
    //                 <ControlLabel>Confirmation Code</ControlLabel>
    //                 <FormControl
    //                     autoFocus
    //                     type="tel"
    //                     onChange={e => setConfirmation(e.target.value)}
    //                     value={confirmation}
    //                 />
    //                 <HelpBlock>Please check your email for the confirmation code.</HelpBlock>
    //             </FormGroup>
    //             <LoaderButton
    //                 block
    //                 type="submit"
    //                 bsSize="large"
    //                 isLoading={isLoading}
    //                 disabled={!validateConfirmationForm()}
    //             >
    //                 Verify
    //             </LoaderButton>
    //         </form>
    //     )
    // }
    
    function renderForm() {
        return (
            <form onSubmit={handleSignup}>
                <FormGroup controlId="email" bsSize="large">
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                        autoFocus
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="confirmPassword" bsSize="large">
                    <ControlLabel>Confirm Password</ControlLabel>
                    <FormControl
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </FormGroup>
                <LoaderButton
                    block
                    type="submit"
                    bsSize="large"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Signup
                </LoaderButton>
                <Collapse in={signupRequirements.length>0}>
                    <Well style={{backgroundColor:"#FF7272", fontWeight:"bold",color:"white"}}>
                        {signupRequirements}
                    </Well>
                </Collapse>
            </form>
        )
    }

    return (
        <div className="Signup">
            {renderForm()}
        </div>
    )
}