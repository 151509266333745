import React, {useState, useEffect} from "react";
import { 
    PageHeader,
    Table,
    FormGroup,
    Checkbox,
    Glyphicon
} from "react-bootstrap";
import {
    patchUser,
    getUserMe,
} from "../utils/bedrockAPI";
import { getUser, getToken, mobileCheck, setUserSession } from "../utils/Common";
import { View } from "react-native";
import { getFCMToken } from "../Firebase";
import Denied from "../images/permissions-denied.png";

export default function MasterNotifications({setAppNotificationsParent, ...props}) {
    const [permissionState, setPermissionState] = useState();
    const [appNotifications, setAppNotifications] = useState(false);
    const [desktopNotifications, setDesktopNotifications] = useState(false);
    const [settings, setSettings] = useState(null);
    const [userId, setUserId] = useState(null);
    const [buttonSize, setButtonSize] = useState("large");
    const [showUnblock,setShowUnblock] = useState(false);
    const [pwmApp, setPwmApp] = useState(false);
    const [showDesktopFromMobile, setShowDesktopFromMobile] = useState(false);
    const [showMobileFromDesktop, setShowMobileFromDesktop] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        let isMounted = true;
        navigator.permissions
        .query({ name: 'notifications' })
        .then(permission => {if(isMounted) setPermissionState(permission.state)});

        if(window.innerWidth<767) {
            setButtonSize("small");
        }

        if (window.matchMedia('(display-mode: standalone)').matches) {
            setPwmApp(true);
        }

        async function fetchUser() {
            const uu = await getUserMe();
            if(isMounted) {
                setUserSession(getToken(),uu);
                setUser(uu);
            }
        }

        fetchUser();
        return () => { isMounted = false; };
    },[]);

    useEffect(() => {
        let isMounted = true;
        if(user) {
            let updateUser = false;
            if(user.userId!=null) setUserId(user.userId);
            if(user.settings!=null) {
                if(user.settings.powerSummaryNotifications == null) {
                    user.settings.powerSummaryNotifications = false;
                    updateUser = true;
                }
                if(user.settings.email == null) {
                    user.settings.email = true;
                    updateUser = true;
                }
                if(isMounted)
                    setSettings(user.settings);
            } else {
                const tempSettings = {powerSummaryNotifications:false};
                user.settings = tempSettings;
                if(isMounted) {
                    setSettings(tempSettings);
                    updateUser = true;
                }
            }
            if(updateUser) {
                const newUser = {userId:user.userId,settings:user.settings};
                patchUser(user.userId,newUser)
            }
            if(user.settings && user.settings.appEnabled) { // Mobile Device Notifications
                if(Notification.permission==="granted") {
                    if(isMounted) {
                        setAppNotifications(true);
                        setAppNotificationsParent(true);
                    }
                }
            } else if(user.settings && user.settings.desktopEnabled) { // Desktop Device Notifications
                if(Notification.permission==="granted") {
                    if(isMounted)
                        setDesktopNotifications(true);
                }
            }
        }
        return () => { isMounted = false; };
    },[user]);

    useEffect(() => {
        let isMounted = true;
        if(permissionState==="granted") {
            const u = getUser();
            if(u.settings) {
                if(u.settings.appEnabled && mobileCheck()) {
                    if(isMounted) {
                        setAppNotifications(true);
                        setAppNotificationsParent(true);
                        setShowUnblock(false);
                    }
                }
                if(isMounted && u.settings.desktopEnabled && !mobileCheck()) {
                    if(isMounted) {
                        setDesktopNotifications(true);
                        setShowUnblock(false);
                    }
                }
            }
        } else if (permissionState==="denied") {
            if(mobileCheck()) {
                if(isMounted) {
                    setAppNotifications(false);
                    setAppNotificationsParent(false);
                    setShowUnblock(true);
                }
            } else {
                if(isMounted) {
                    setDesktopNotifications(false);
                    setShowUnblock(true);
                }
            }
        } else {
            console.log("permission state:",permissionState);
        }
        return () => { isMounted = false; };
    },[permissionState]);

    async function handleEmailOverride() {
        var newSettings = JSON.parse(JSON.stringify(settings));
        newSettings.email = !newSettings.email;
        setSettings(newSettings);
        const newUser = {userId:userId,settings:newSettings};
        await patchUser(userId,newUser);
    }

    async function handleDesktopOverride() {
        let u = getUser();
        // If you're on desktop and blocked, deny clicking the button and show guidance
        if(!mobileCheck() && Notification.permission==="denied") {
            setShowUnblock(true);
        } // For Desktop, remove key and over-override the notifications; For mobile, just over-override (no key to match)
        else if(u && desktopNotifications) {
            getFCMToken(u,getToken(),false,true);
            setDesktopNotifications(false);
            if(!mobileCheck()) {
                setShowUnblock(false);
            }
        } // Desktop stores key, mobile says "store the key the next time you login on desktop"
        else if(u) { 
            getFCMToken(u,getToken(),true,true);
            setDesktopNotifications(true);
            if(!mobileCheck()) {
                setShowUnblock(false);
            } else {
                setShowDesktopFromMobile(true);
            }
        }
    }

    function handleAppOverride() {
        let u = getUser();
        if(mobileCheck() && Notification.permission==="denied") {
            setShowUnblock(true);
        }
        else if(u && appNotifications) {
            getFCMToken(u,getToken(),false);
            setAppNotifications(false);
            setAppNotificationsParent(false);
            if(mobileCheck()) {
                setShowUnblock(false);
            }
        } else if(u) {
            getFCMToken(u,getToken());
            setAppNotifications(true);
            setAppNotificationsParent(true);
            if(mobileCheck()) {
                setShowUnblock(false);
            } else {
                setShowMobileFromDesktop(true);
            }
        }
    }

    return (
        <View>
            <PageHeader>
                <div style={{fontSize:buttonSize==="small"?"24px":"36px"}}>
                    Notification Settings
                </div>
                <div style={{paddingTop:"10px",fontSize:buttonSize==="small"?"12px":"16px",fontWeight:"normal",textAlign:"center"}}>
                   (These will override all power and motion notification settings)
                </div>
            </PageHeader>
            <View>
                <Table condensed>
                    <tbody>
                        <tr>
                            <th style={{paddingLeft:buttonSize==="large"?"0px":"25px",fontSize:buttonSize==="large" ? "24px" : "16px"}}>
                                Allow us to send you emails
                            </th>
                            <td style={{fontSize:"24px"}}>
                                <form>
                                    <FormGroup>
                                        <Checkbox checked={settings ? settings.email!= null ? settings.email : false : false} onChange={() => handleEmailOverride()}>
                                            <Glyphicon glyph="envelope" />
                                        </Checkbox>
                                    </FormGroup>
                                </form>
                            </td>
                        </tr>
                        {'Notification' in window && ( // ADMIN CONTROL -- app notifications override toggle (+ permissions request)
                        <>
                            <tr>
                                <th style={{paddingLeft:buttonSize==="large"?"0px":"25px",fontSize:buttonSize==="large" ? "24px" : "16px"}}>
                                    Allow us to send you mobile app notifications
                                </th>
                                <td style={{fontSize:"24px"}}>
                                    <form>
                                        <FormGroup>
                                            <Checkbox checked={appNotifications} onChange={() => handleAppOverride()}>
                                                <Glyphicon glyph="phone" />
                                            </Checkbox>
                                        </FormGroup> 
                                    </form>
                                    {/* <Button
                                        bsSize="large"
                                        bsStyle={appNotifications ? 'danger' : 'info'}
                                        // disabled={permissionState==="denied" ? true : false}
                                        onClick={e => handleNotifications()}
                                    >{appNotifications ? "Notifications are Enabled, Click to Disable" : "Click to Allow Notifications"}</Button> */}
                                </td>
                            </tr>
                            {showMobileFromDesktop && (
                                <tr>
                                    <td colSpan={2} style={{textAlign:"right"}}>
                                        (You may need to unblock notifications on those devices)
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th style={{paddingLeft:buttonSize==="large"?"0px":"25px",fontSize:buttonSize==="large" ? "24px" : "16px"}}>
                                    Allow us to send you desktop app notifications
                                </th>
                                <td style={{fontSize:"24px"}}>
                                    <form>
                                        <FormGroup>
                                            <Checkbox checked={desktopNotifications} onChange={() => handleDesktopOverride()}>
                                                <Glyphicon glyph="modal-window" />
                                            </Checkbox>
                                        </FormGroup>
                                    </form>
                                </td>
                            </tr>
                            {showDesktopFromMobile && (
                                <tr>
                                    <td colSpan={2}>
                                        (You may need to unblock notifications on those devices)
                                    </td>
                                </tr>
                            )}
                            {showUnblock && (pwmApp ? (<tr style={{width:"100%",paddingTop:"30px",maxWidth:"850px"}}>
                                <td colSpan="2" style={{wordWrap:"break-word",whiteSpace:"pre-wrap", border:"1px solid red"}}>
                                    It looks like you've blocked notifications in your app.<br /><br />
                                    In order to allow us to send you notifications, you will need to open this app's 
                                    settings and allow access to notifications.<br /><br />
                                    Once you've done that, come back to your account settings here and click the button above.
                                </td>
                            </tr>) : (
                                <>
                                    <tr style={{width:"100%",paddingTop:"30px",maxWidth:"850px"}}>
                                        <td colSpan="2" style={{wordWrap:"break-word",whiteSpace:"pre-wrap", border:"1px solid red"}}>
                                            It looks like you've blocked notifications in the browser.<br /><br />
                                            In order to allow us to send you notifications, you will 
                                            need to click the button next to the address bar and allow 
                                            this site to show notifications.<br /><br />
                                            Once you've done that, clicke the button above again.<br /><br />
                                            
                                        </td>
                                    </tr>
                                    <tr style={{width:"100%",maxWidth:"850px",textAlign:"center"}}>
                                        <td colSpan="2">
                                            <img src={Denied} alt="permissions button" />
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </>
                        )}
                    </tbody>
                </Table>
            </View>
        </View>
    );
}