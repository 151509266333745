import React, {useEffect, useState} from "react";
import { 
    ControlLabel, 
    FormControl, 
    FormGroup, 
    PageHeader
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { View } from "react-native";
import {
    changePassword, 
} from "../utils/bedrockAPI";
import {
    getUser, 
    removeUserSession
} from "../utils/Common";

export default function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[])

    function validateForm() {
        // TODO - FIX ON API CORRECTION
        return newPassword.length > 7 && confirmPassword === newPassword;
    }

    async function handlePasswordChange(event) {
        event.preventDefault();
        setIsLoading(true);
        await changePassword(getUser().userId,oldPassword,newPassword);
        removeUserSession();
        props.setErrorMessage("Password changed successfully, Please login with your new password");
        props.setErrorStatus("success-message");
        props.setShowError(true);
        props.history.push('/login');
        setIsLoading(false);
    }

    return (
        <View>
            <PageHeader style={{textAlign:"center"}}>
                <div style={{fontSize:buttonSize==="small"?"24px":"36px"}}>
                    Change Your Password
                </div>
            </PageHeader>
            <form className="password" onSubmit={handlePasswordChange}>
                <FormGroup controlId="oldPassword" bsSize="large">
                    <ControlLabel>Old Password</ControlLabel>
                    <FormControl
                        autoFocus
                        type="password"
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="newPassword" bsSize="large">
                    <ControlLabel>New Password</ControlLabel>
                    <FormControl
                        type="password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="confirmPassword" bsSize="large">
                    <ControlLabel>Confirm New Password</ControlLabel>
                    <FormControl
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </FormGroup>
                <LoaderButton
                    block
                    type="submit"
                    bsSize="large"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Change Password
                </LoaderButton>
            </form>
        </View>
    );
}