import React, {useEffect, useState} from "react";
import { Modal, ControlLabel, FormControl, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { View } from "react-native";
import {
    getAllBuilders, 
    getAllNeighborhoods,
    patchCapBuilder,
    patchCapHealth,
    changeCapNeighborhood,
    patchCapStatus,
    patchCapBandwidth
} from "../utils/bedrockAPI";

const HEALTH_OPTIONS = ['POWER_LOSS','ERROR','BATTERY','ONLINE'];
const STATUS_OPTIONS = ['ACTIVE','BUILD','DECOMISSIONED'];
const BANDWIDTH_OPTIONS = ['HIGH','LOW'];

export default function CapModal({show,cap,setShowCapModal,...props}) {
    const [builders,setBuilders] = useState(null);
    const [currentBuilder,setCurrentBuilder] = useState(null);
    const [neighborhoods,setNeighborhoods] = useState(null);
    const [currentNeigborhood,setCurrentNeighborhood] = useState(null);
    const [capHealth,setCapHealth] = useState(cap ? cap.health : "POWER_LOSS");
    const [capStatus,setCapStatus] = useState(cap ? cap.status : "ACTIVE");
    const [bandwidth,setBandwidth] = useState(cap ? cap.bandwidth || "HIGH" : "HIGH");

    useEffect(() => {
        // console.log(cap);
        setCapHealth(cap.health);
        setCapStatus(cap.status);
        setBandwidth(cap.bandwidth || "HIGH");
        // console.log(cap.status);
    },[cap]);

    useEffect(() => {
        console.log(bandwidth);
    },[bandwidth]);

    useEffect(() => {
        async function accessBuilders() {
            const tempBuilders = await getAllBuilders();
            let tempBuilderObject = {}
            for(var i=0; i<tempBuilders.length; i++) {
                tempBuilderObject[tempBuilders[i].builderId] = tempBuilders[i];
            }
            tempBuilderObject[null] = {builderId:null,name:"NO BUILDER"};
            setBuilders(tempBuilderObject);
        }
        async function accessNeighborhoods() {
            const tempNeighborhoods = await getAllNeighborhoods();
            let tempNeighborhoodObject = {}
            for(var i=0; i<tempNeighborhoods.length; i++) {
                tempNeighborhoodObject[tempNeighborhoods[i].neighborhoodId] = tempNeighborhoods[i];
            }
            tempNeighborhoodObject[null] = {neighborhoodId:null,name:"NO NEIGHBORHOOD"};
            setNeighborhoods(tempNeighborhoodObject);
        }
        if(show) {
            accessBuilders();
            accessNeighborhoods();
        }
    },[show]);

    useEffect(() => {
        if(builders) {
            setCurrentBuilder(builders[cap.builderId]);
        }
    },[builders])

    useEffect(() => {
        if(neighborhoods) {
            setCurrentNeighborhood(neighborhoods[cap.neighborhoodId]);
        }
    },[neighborhoods])

    async function updateBuilder(e) {
        for(var builder in builders) {
            if(builders[builder].name === e.target.value) {
                setCurrentBuilder(builders[builder]);
            }
        }
    }

    async function updateNeighborhood(e) {
        for(var neighborhood in neighborhoods) {
            if(neighborhoods[neighborhood].name===e.target.value) {
                setCurrentNeighborhood(neighborhoods[neighborhood]);
                console.log(neighborhoods[neighborhood])
            }
        }
    }

    async function updateCapHealth(e) {
        setCapHealth(e.target.value);
    }

    async function modifyCapAndBuilder(e) {
        e.preventDefault();
        if(currentBuilder.builderId!==cap.builderId) {
            patchCapBuilder(cap.capId,currentBuilder.builderId);
        }
        if(currentNeigborhood.neighborhoodId!==cap.neighborhoodId) {
            console.log(cap.capId,currentNeigborhood.neighborhoodId)
            changeCapNeighborhood(cap.capId,currentNeigborhood.neighborhoodId);
        }
        if(capHealth!==cap.health) {
            patchCapHealth(cap.capId,capHealth);
        }
        if(capStatus!==cap.status) {
            patchCapStatus(cap.capId,capStatus);
        }
        if(bandwidth!==cap.bandwidth) {
            patchCapBandwidth(cap.capId,bandwidth);
        }
        setShowCapModal(false);
    }

    return (
        <Modal
            show={show}
            onHide={() => setShowCapModal(false)}
            container={this}
            aria-labelledby="contained-modal-title"
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit CAP metadata
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    {builders && 
                    currentBuilder && 
                    neighborhoods && 
                    currentNeigborhood && 
                    HEALTH_OPTIONS && 
                    capHealth && (
                        <form onSubmit={(e) => modifyCapAndBuilder(e)}>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end",paddingBottom:"5px"}}>
                                <ControlLabel style={{width:"200px",paddingRight:"10px",paddingTop:"15px"}}>
                                    Builder
                                </ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    defaultValue={currentBuilder.name}
                                    onChange={(e) => updateBuilder(e)}
                                    style={{height:"50px"}}
                                >
                                    {builders && (Object.keys(builders)).map((builder,i) => (
                                        <option key={i}>
                                            {builders[builder].name}
                                        </option>
                                    ))}
                                </FormControl>
                            </View>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end",paddingBottom:"5px"}}>
                                <ControlLabel style={{width:"200px",paddingRight:"10px",paddingTop:"15px"}}>
                                    Neighborhood
                                </ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    defaultValue={currentNeigborhood.name}
                                    onChange={(e) => updateNeighborhood(e)}
                                    style={{height:"50px"}}
                                >
                                    {neighborhoods && (Object.keys(neighborhoods)).map((neighborhood,i) => (
                                        <option key={i}>
                                            {neighborhoods[neighborhood].name}
                                        </option>
                                    ))}
                                </FormControl>
                            </View>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end",paddingBottom:"5px"}}>
                                <OverlayTrigger placement="right" overlay={(
                                    <Tooltip id="warningHealth">
                                        DO NOT CHANGE UNLESS YOU KNOW WHAT YOU'RE DOING
                                    </Tooltip>
                                )}>
                                    <ControlLabel style={{width:"200px",paddingRight:"10px",paddingTop:"15px"}}>
                                        Cap Health 
                                    </ControlLabel>
                                </OverlayTrigger>
                                <FormControl
                                    componentClass="select"
                                    defaultValue={capHealth}
                                    onChange={(e) => updateCapHealth(e)}
                                    style={{height:"50px"}}
                                >
                                    {HEALTH_OPTIONS.map((health,i) => (
                                        <option key={i}>
                                            {health}
                                        </option>
                                    ))}
                                </FormControl>
                            </View>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end",paddingBottom:"5px"}}>
                                <OverlayTrigger placement="right" overlay={(
                                    <Tooltip id="statusHelp">
                                        The STATUS can be one of three things:<br />
                                        -ACTIVE (activated caps in use by a customer)<br />
                                        -BUILD (built CAP that's not yet activated)<br />
                                        -DECOMISSIONED (CAPs that aren't in use - yes I spelled it wrong)<br />
                                        DO NOT CHANGE THIS UNLESS YOU KNOW WHAT YOU'RE DOING
                                    </Tooltip>
                                )}>
                                    <ControlLabel style={{width:"200px",paddingRight:"10px",paddingTop:"15px"}}>
                                        Cap Status 
                                    </ControlLabel>
                                </OverlayTrigger>
                                <FormControl
                                    componentClass="select"
                                    defaultValue={capStatus}
                                    onChange={(e) => setCapStatus(e.target.value)}
                                    style={{height:"50px"}}
                                >
                                    {STATUS_OPTIONS.map((status,i) => (
                                        <option key={i}>
                                            {status}
                                        </option>
                                    ))}
                                </FormControl>
                            </View>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end",paddingBottom:"5px"}}>
                                <ControlLabel style={{width:"200px",paddingRight:"10px",paddingTop:"15px"}}>
                                    Cap Bandwdith
                                </ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    defaultValue={bandwidth}
                                    onChange={(e) => setBandwidth(e.target.value)}
                                    style={{height:"50px"}}
                                >
                                    {BANDWIDTH_OPTIONS.map((band,i) => (
                                        <option key={i}>
                                            {band}
                                        </option>
                                    ))}
                                </FormControl>
                            </View>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                                <Button
                                    type="submit"
                                    bsStyle="info"
                                    bsSize="large"
                                    disabled={
                                        currentBuilder.builderId===cap.builderId && 
                                        currentNeigborhood.neighborhoodId===cap.neighborhoodId &&
                                        capHealth===cap.health &&
                                        capStatus===cap.status &&
                                        bandwidth===cap.bandwidth
                                    }
                                >
                                    Save
                                </Button>
                            </View>
                        </form>
                    )}
            </Modal.Body>
            {props.children}
        </Modal>
    )
}