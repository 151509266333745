import React, { useState, useEffect } from "react";
import { PageHeader } from "react-bootstrap";
import { View } from "react-native";
import IOSSettings from "../../images/ios-settings.png";
import IOSNotifications from "../../images/ios-notifications.png";
import IOSBedrockNotifications from "../../images/ios-bedrock-notifications.png";
import BedrockApp from "../../images/bedrock-app.png";
import BedrockAppInfo from "../../images/bedrock-app-info.png";
import BedrockSettings from "../../images/bedrock-settings.png";
import BedrockNotifications from "../../images/bedrock-notifications.png";
import { iosDevice, jumpToInstructions } from "../../utils/Common";


export default function EnableMobileNotifications() {
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        if(iosDevice()) {
            setIsIOS(true);
        }
    },[]);

    return (
        <View>
            <PageHeader>How to Enable Mobile Notifications</PageHeader>
            <View>
                {jumpToInstructions(!isIOS,"android","mobile","-notifications")}
                <h2 id="ios">
                    iPhone (or iPad)
                </h2>
                <h3>
                    <ol>
                        <li className="helpList">
                            Make sure you have the app installed (<a href="app#ios">See Installation Instructions</a>), then access your device's settings<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={IOSSettings} alt="IOS Settings" />
                            </div>
                        </li>
                        <li className="helpList">
                            Go to notifications and find Bedrock Wireless<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={IOSNotifications} alt="IOS Notifications" />
                            </div>
                        </li>
                        <li className="helpList">
                            Enable the types of notifications you want to receive<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={IOSBedrockNotifications} alt="IOS Bedrock Notifications" />
                            </div>
                        </li>
                    </ol>
                </h3>
                <h2 id="android-notifications">
                    Android Phone
                </h2>
                <h3>
                    <ol>
                        <li className="helpList">
                            Make sure you have the app installed (<a href="app#android">See Installation Instructions</a>), then find your Bedrock Wireless app<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={BedrockApp} alt="Bedrock App" />
                            </div>
                        </li>
                        <li className="helpList">
                            Press and hold the app icon until a menu pops up and select 'App Info'<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={BedrockAppInfo} alt="Bedrock App Info" />
                            </div>
                        </li>
                        <li className="helpList">
                            Select 'Notifications'<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={BedrockSettings} alt="Bedrock Settings" />
                            </div>
                        </li>
                        <li className="helpList">
                            Make sure Notifications are enabled<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={BedrockNotifications} alt="Bedrock Notifications" />
                            </div>
                        </li>
                    </ol>
                </h3>
            </View>
        </View>
    )
}