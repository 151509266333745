import React, {useEffect, useState} from "react";
import { 
    TabContainer,
    Col,
    Row,
    Nav,
    NavItem,
    Tab
} from "react-bootstrap";
import { View } from "react-native";
import { 
    InstallMobileApp,
    EnableDesktopNotifications,
    EnableMobileNotifications,
    AdjustMotionZones,
    OtherFAQ
} from "./Help/";
import {
    recordUserAction
} from "../utils/bedrockAPI";
import {
    getUser,
    getUserInformation
} from "../utils/Common";
import "./Help/help.css";

export default function Help(props) {
    const [buttonSize, setButtonSize] = useState("large");
    const [helpKey, setHelpKey] = useState("faq");

    useEffect(() => {
        if(props.match.params.helpkey) {
            setHelpKey(props.match.params.helpkey);
        }
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        recordUserAction(getUser().userId,"help",null,{'userData':getUserInformation()});
    },[]);

    async function handleTabSelect(k) {
        props.history.push('/help/'+k); 
    }

    return (
        <View>
            <TabContainer id="HelpDocs" defaultActiveKey={"faq"} activeKey={helpKey} onSelect={handleTabSelect}>
                <View style={{flex:1,flexDirection:buttonSize==="small"?"column":"row"}}>
                    <View style={{flex:1,flexDirection:"column", maxWidth:buttonSize==="small"?"100%":"25%", paddingRight:buttonSize==="large"?"10px":"0px"}}>
                        <Nav bsStyle="pills" stacked={buttonSize==="large"} justified={buttonSize==="small"}>
                            <NavItem eventKey="faq">FAQ</NavItem>
                            <NavItem eventKey="app">Install the Mobile App</NavItem>
                            <NavItem eventKey="desktop">Enable Desktop Notifications</NavItem>
                            <NavItem eventKey="mobile">Enable Mobile Notifications</NavItem>
                            <NavItem eventKey="zones">Adjust Motion Zones</NavItem>
                        </Nav>
                    </View>
                    <View style={{flex:1,flexDirection:"column"}}>
                        <Tab.Content>
                            <Tab.Pane eventKey="app">{InstallMobileApp()}</Tab.Pane>
                            <Tab.Pane eventKey="desktop">{EnableDesktopNotifications()}</Tab.Pane>
                            <Tab.Pane eventKey="mobile">{EnableMobileNotifications()}</Tab.Pane>
                            <Tab.Pane eventKey="zones">{AdjustMotionZones()}</Tab.Pane>
                            <Tab.Pane eventKey="faq">{OtherFAQ()}</Tab.Pane>
                        </Tab.Content>
                    </View>
                </View>
            </TabContainer>
        </View>
    )
}