import React, {useState, useEffect, useRef} from "react";
import { getCap, storeCap, getUser, getUserInformation } from "../utils/Common";
import { Button, Glyphicon, OverlayTrigger, PageHeader, Tooltip } from "react-bootstrap";
import { View } from "react-native";
import "./Camera.css";
import { loadCap, getEvent, getEventImage, putEventName, recordUserAction, deleteEventVideo } from "../utils/bedrockAPI";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ContentEditable from "react-contenteditable";

// const UPDATE_INTERVAL = 10;
// const TIMEOUT = 90;
// const ORDER = ['house','neighbor','street','driveway'];

export default function Snapshot(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [cap,setCap] = useState(null);
    const [buttonSize, setButtonSize] = useState("large");
    const [event, setEvent] = useState(null);
    const [eventImage, setEventImage] = useState(null);
    const nameElement = useRef(null);
    var eventName = useRef("");

    // 1 - Screen size
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    // 2a - Load the cap from memory 1st then api
    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);                    
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"snapshot",props.match.params.capid,{'eventId':props.match.params.eventid,'userData':getUserInformation()});
    },[props.match.params.capid])

    // 2b - Event Info
    useEffect(() => {
        async function accessEvent() {
            try {
                setIsLoading(true);
                var tempEvent = await getEvent(props.match.params.eventid);
                eventName.current = tempEvent.name;
                setEvent(tempEvent);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessEvent();
    },[props.match.params.eventid]);

    // 3 - Load Img from event
    useEffect(() => {
        async function accessEventImage() {
            try {
                var tempImage = await getEventImage(props.match.params.eventid);
                setEventImage(tempImage);
            } catch (e) {
                console.log(e);
            }
        }
        if(event && event.imageId) {
            accessEventImage();
        }
    },[event]);

    var highlightAll = function(e) {
        var range = document.createRange();
        range.selectNodeContents(nameElement.current.getEl());
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    function renderEventImage() {
        var handleNameChange = async function(e) {
            const name = e.target.value.replace(/(<([^>]+)>)/ig,"");
            eventName.current = name;
            // putEventName(event.eventId, name)
        };

        var saveToServer = function(e) {
            console.log(eventName);
            putEventName(event.eventId, eventName.current);
        }
      
        var pasteAsPlainText = function(e) {
            e.preventDefault();
        
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertHTML',false,text);
        };
      
        var disableNewLines = function(e) { 
            const keyCode = e.keyCode || e.which;
    
            if(keyCode === 13) {
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
                const element = (e.currentTarget || e.srcElement);
                element.blur();
            }
        };

        const zoomTooltip = (
            <Tooltip id="zoom" style={{fontSize:"20px"}} disabled={buttonSize==="small"}>
                Scroll to Zoom
            </Tooltip>
        );

        return (
            <View style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <PageHeader style={{marginTop:"-10px"}}>
                    <View style={{flex:1, flexDirection:"row",justifyContent:'space-between'}}>
                        {/* {console.log(getUser().caps[props.match.params.capid])} */}
                        <ContentEditable
                            html={eventName.current}
                            // className="ContentEditable-header"
                            disabled={isLoading || getUser().caps[props.match.params.capid].accessType!=="OWNER"}
                            onPaste={pasteAsPlainText}
                            onKeyPress={disableNewLines}
                            onFocus={highlightAll}
                            onChange={handleNameChange}
                            onBlur={saveToServer}
                            ref={nameElement}
                        />
                    </View>
                </PageHeader>
                <View style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
                    {eventImage && eventImage.url && (
                        <TransformWrapper>
                            {({scale,...rest}) => (
                                <OverlayTrigger placement="top" overlay={zoomTooltip} delayShow={scale===1 ? 100 : 100000 /*dumb hack with long delay to not show twice? */}>
                                    <View style={{width:"100%", maxWidth: "2592px", height: undefined, aspectRatio:1.4667}}>
                                        <TransformComponent>
                                            <img src={eventImage.url} alt="" />
                                        </TransformComponent>
                                    </View>
                                </OverlayTrigger>
                            )}
                        </TransformWrapper>            
                    )}
                </View>
            </View>
        )
    }

    function renderHeader() {

        async function removeEvent() {
            setIsLoading(true);
            if(window.confirm(`Are you sure you wish to delete ${event.name}?`)) {
                await deleteEventVideo(event.eventId);
                props.history.push(`/caps/${cap.capId}/events`)
            }
            setIsLoading(false);
        }

        const motionTooltip = (
            <Tooltip id="motion" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Motion Video
            </Tooltip>
        );

        const editTooltip = (
            <Tooltip id="edit" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Edit Event Name
            </Tooltip>
        );

        const deleteTooltip = (
            <Tooltip id="delete" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Delete Event
            </Tooltip>
        )

        // const refreshTooltip = (
        //     <Tooltip id="refresh" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
        //         Reload Page
        //     </Tooltip>
        // );

        const downloadTooltip = (
            <Tooltip id="download" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Download Image
            </Tooltip>
        );

        return (
            <div>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between',padding:10}}>
                    <Button
                        bsStyle="primary"
                        bsSize={buttonSize}
                        // disabled={isLoading}
                        onClick={() => props.history.push(`/caps/${cap.capId}/events`)}
                    >
                        Event Dashboard
                    </Button>
                    <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                        {/* <OverlayTrigger placement="bottom" overlay={refreshTooltip}>
                            <Button
                                bsStyle="info"
                                bsSize={buttonSize}
                                // disabled={isLoading}
                                onClick={() => window.location.reload(false)}
                            >
                                <Glyphicon glyph="refresh"></Glyphicon>
                            </Button>
                        </OverlayTrigger> */}
                        {getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                            <React.Fragment>
                                <View style={{paddingRight: "5px"}}>
                                    <OverlayTrigger placement="bottom" overlay={deleteTooltip}>
                                        <Button
                                            bsStyle="danger"
                                            bsSize="large"
                                            onClick={() => removeEvent()}
                                        >
                                            <Glyphicon glyph="remove" />
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                                <View style={{paddingRight: "5px"}}>
                                    <OverlayTrigger placement="bottom" overlay={editTooltip}>
                                        <Button
                                            bsStyle="success"
                                            bsSize={buttonSize}
                                            onClick={() => highlightAll()}
                                        >
                                            <Glyphicon glyph="edit" />
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                                {eventImage && (
                                    <OverlayTrigger placement="bottom" overlay={downloadTooltip}>
                                        <View style={{paddingRight:"5px"}}>
                                            <Button
                                                bsStyle="info"
                                                bsSize={buttonSize}
                                                href={eventImage.url}
                                            >
                                                <Glyphicon glyph="download-alt" />
                                            </Button>
                                        </View>
                                    </OverlayTrigger>
                                )}
                                <OverlayTrigger placement="bottom" overlay={motionTooltip}>
                                    <View style={{paddingRight: "5px"}}>
                                        <Button
                                            bsStyle="warning"
                                            bsSize={buttonSize}
                                            onClick={() => props.history.push(`/caps/${cap.capId}/events/${props.match.params.eventid}`)}
                                        >
                                            <Glyphicon glyph="film" />
                                        </Button>
                                    </View>
                                </OverlayTrigger>
                            </React.Fragment>
                        )}
                    </View>
                </View>
                {event && eventImage && renderEventImage()}
            </div>
        )
    }
    
    return (
        <div className="Cameras">
            {!isLoading && renderHeader()}
        </div>
    );
}