import React, {useEffect, useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { View } from "react-native";
import {
    getMonitors,
    getLatestStoredImages,
    addCCCapProject
} from "../utils/bedrockAPI";
import "./MonitorSelection.css";

export default function MonitorSelectionModal({show,cap,project,integration,setShowMonitorSelectionWindow,...props}) {
    const [monitorCap, setMonitorCap] = useState(cap);
    const [cams, setCams] = useState(null);
    const [url, setUrls] = useState(null);
    const [selectedMonitors, setSelectedmonitors] = useState([]);

    useEffect(() => {
        if(cap) {
            setMonitorCap(cap);
            console.log(cap);
        }
    },[cap]);

    useEffect(() => {
        async function savedImages() {
            const tempCams = await getMonitors(monitorCap);
            setCams(tempCams);
            var tempSavedUrls = await getLatestStoredImages(monitorCap);
            setUrls(tempSavedUrls);
            console.log(tempSavedUrls);
        }
        if(monitorCap) {
            savedImages();
        }
    },[monitorCap]);

    async function addMonitorToSelected(monitor) {
        if(selectedMonitors.length>0) {
            let added = false;
            for(var i=0; i<selectedMonitors.length; i++) {
                if(selectedMonitors[i]===monitor) {
                    added = true;
                    break;
                }
            }
            if(added) {
                // delete the item from the array
                setSelectedmonitors(selectedMonitors.filter(m => m !== monitor));
            }
            else {
                setSelectedmonitors([...selectedMonitors,monitor]);
            }
        } else {
            setSelectedmonitors([monitor]);
        }
    }

    function renderImg(camera) {
        return (
            <img 
                src={url[camera.medium.monitorId].url}
                alt={`${camera.medium.name}`}
                className={selectedMonitors.includes(camera.high.monitorId) ? "selected" : "unselected"}
                onClick={() => addMonitorToSelected(camera.high.monitorId)}
            />
        );
    }

    async function addUserIntegration(e) {
        e.preventDefault();
        if(cap && project) {
            if(integration.integration) {
                await addCCCapProject(integration,cap,project,selectedMonitors);
            }
        }
        setShowMonitorSelectionWindow(false);
    }

    return (
        <Modal 
            show={show}
            onHide={() => setShowMonitorSelectionWindow(false)}
            container={this}
            aria-labelledby="contained-modal-title"
            {...props}
        >
            <Modal.Header closeButton>
                Select the views you want to sync with Company Cam
            </Modal.Header>
            <Modal.Body>
                <View style={{flex:1}}>
                    {url && url[cams.house.medium.monitorId] && cams && (
                        <View style={{flex:1,flexDirection:"column"}}>
                            <View style={{flex:1,flexDirection:"row",alignItems:"flex-start",justifyContent:"space-around"}}>
                                {renderImg(cams.house)}
                                {renderImg(cams.neighbor)}
                            </View>
                            <View style={{flex:1,flexDirection:"row",alignItems:"flex-start",justifyContent:"space-around"}}>
                                {renderImg(cams.street)}
                                {renderImg(cams.driveway)}
                            </View>
                        </View>
                    )}
                    <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                        <Button
                            bsStyle="info"
                            bsSize="large"
                            onClick={(e) => addUserIntegration(e)}
                            disabled={selectedMonitors.length===0}
                        >
                            Start Daily Image Sync
                        </Button>
                    </View>
                </View>
            </Modal.Body>
            {props.children}
        </Modal>
    );
}