import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';

// handle the public routes
function PublicRoute({ component: Component, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      // render={(props) => !getToken() ? <Component {...props} {...appProps} /> : <Redirect to={{ pathname: '/dashboard' }} />}
      render={(props) => <Component {...props} {...appProps} />}
    />
  )
}

export default PublicRoute;