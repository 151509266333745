import React from "react";
import "./Home.css";
import { Button } from "react-bootstrap";

export default function Home(props) {
  function dashboard() {
    props.history.push("/dashboard");
  }

  return (
    <div className="Home">
      <div className="lander">
        <h1>Bedrock Wireless</h1>
        <p>Your gateway to virtual construction management</p>
        <Button
          autoFocus
          bsStyle="success"
          bsSize="large"
          onClick={dashboard}
        >
          My Dashboard
        </Button>
      </div>
    </div>
  );
}