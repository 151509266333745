import React from "react";
import { PageHeader, Panel } from "react-bootstrap";
import { View } from "react-native";
import ShareThisCap from "../../images/share-this-cap.png";
import SharingOptions from "../../images/sharing-permissions.png";
import SharingIcon from "../../images/share.png";
import ManchesterByTheSea from "../../images/timelapse-views.png";
import StartEndRangeFilters from "../../images/time-filters.png";
import RealMotionButton from "../../images/uninteresting-motion.png";
import FourCamPackaged from "../../images/packaged_camera_system.jpg";
import TwoCamPackaged from "../../images/packaged-2cam-system.jpg";
import ShippingLabel from "../../images/shipping-label.jpg";

export default function OtherFAQ() {

    const QuestionAndAnswer = ({i,q,a,expanded}) => (
        <Panel id={i} defaultExpanded={expanded}>
            <Panel.Heading>
                <Panel.Title toggle>
                    {q}
                </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body>
                    {a}
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    );

    return (
        <View>
            <PageHeader>Frequently Asked Questions</PageHeader>
            <View>
                <QuestionAndAnswer
                    i="q1"
                    q="How can we reach Bedrock Wireless?"
                    a={(<div>
                        You can always email <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a> or call <a href="tel:17047022152">(704)702-2152</a> to reach us directly and get help with any problem you might have
                    </div>)}
                    expanded={true}
                />
                <QuestionAndAnswer
                    i="q2"
                    q="How do I view the high resolution live view?"
                    a="When accessing your camera's live view ( the four or two simultaneous camera views ), you can click on any one of the views to get the 4MP high resolution live view."
                />
                <QuestionAndAnswer
                    i="q3"
                    q="How do I share my camera?"
                    a={(<div>
                        Go to your main dashboard (<a href="https://data.bedrockwireless.com/dashboard">data.bedrockwireless.com/dashboard</a>). To the right of each camera is a blue botton labeled 'Share this Cap'<br /><br />
                        <img style={{width:"150px"}} src={ShareThisCap} alt="Share This Cap" /><br /><br />
                        Clicking this button will allow you to enter the email address of the person with whom you would like to share access. If you do not see this blue botton, you may not have sharing permissions. Reach out to your company administrator or help@bedrockwireless.com if you feel there is an error.<br /><br />
                        When sharing a camera, be sure to assign appropriate permissions. Every user will be able to view the camera's live feeds by default.<br /><br />
                        <ul>
                            <li>Motion History allows a user to view and search motion events</li>
                            <li>Camera Sharing allows the user to share the camera with others</li>
                            <li>Power Notifications will subscribe the user to email notifications pertained to the power status of the camera system, prompting them to check the camera and restore power</li>
                        </ul>
                        <div className="center">
                            <img style={{width:"50%"}} src={SharingOptions} alt="Sharing Options" />
                        </div>
                        You can also click the same blue-colored share button on yoru Camera's live view page.<br /><br />
                        <img style={{width:"50px"}} src={SharingIcon} alt="Sharing Icon" />
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q4"
                    q="How do I request another camera system?"
                    a={(<div>
                        Contact us at <a href="mailto: sales@bedrockwireless.com">sales@bedrockwireless.com</a> or <a href="tel:17047022140">(704)702-2140</a>
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q5"
                    q="How do I request a time-lapse of my project?"
                    a={(<div>
                        Send us a note at <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a> with the following:<br /><br />
                        <ul>
                            <li>Start and end dates for the time-lapse</li>
                            <li>Hours of the day you care about (ex: 9AM to 4PM)</li>
                            <li>The camera views you want to time-lapse (use the numbers like the image below)</li>
                        </ul>
                        These can take several days to process. If you know in advance that there's a time period you want to time-lapse, please let us know so we can increase the time-lapse image recording frequency<br /><br />
                        <div className="center">
                            <img style={{width:"80%"}} src={ManchesterByTheSea} alt="Timelapse Camera Views" />
                        </div>
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q6"
                    q="How do I filter motion events by date and time?"
                    a={(<div>
                        When you're in the 'Motion History' section for your camera system, you can click on the 'Start Range' or the 'End Range' (as shown below) to filter and show only events that took place during this time range. These dates can go back for as long as you have had the camera system. We don't delete anything<br /><br />
                        <div className="center">
                            <img style={{width:"80%"}} src={StartEndRangeFilters} alt="Start and End Range Filters" />
                        </div>
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q7"
                    q="I'm not finding the motion event I'm looking for, is there more I can do?"
                    a={(<div>
                        Our AI attempts to catch all people and vehicle motion that occurs on site. In some cases, if the motion is too far away or partially outside of the image, the AI may not be able to detect and record. Since we are limited by our bandwidth over a cellular data connection, it is impossible to upload 24/7 footage.<br /><br />
                        Once motion events are uploaded to the website, our servers re-check all of the motion events and attempt to eliminate the motion events you wouldn't care about. Examples include leaves blowing in the wind or a car that isn't moving. However, on rare occasions this system could accidentally eliminate something you care to see. If you would like to see all unfiltered motion, click the asterisk button as shown in the image below.<br /><br />
                        <div className="center">
                            <img style={{width:"80%"}} src={RealMotionButton} alt="Show All Motion Button" />
                        </div><br /><br />
                        If you have one of our new aluminum cameras, we aim to keep a full 48 hour recording backup locally for each camera. If you can provide us with a specific 10 minute time window, we can upload this full 10 minute video slowly over the cellular data connection and send it to you within a couple of hours. Contact us at <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a> for help.
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q8"
                    q="How do I return my camera system?"
                    a={(<div>
                        Included inside every box is a prepaid return label. The prepaid return label is located inside the plastic pack of instructions that come inside the box. Place the camera system neatly in the foam and stick the prepaid return label over the old shipping label. Simply drop it off at any FedEx location.<br /><br />
                        You are welcome to keep any of the included hardware (bolts/washers/tools/etc) and the warning sign.<br /><br />
                        If you cannot find your plastic instructions packet or the prepaid label inside, just email <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a> and we will email you a new label that you can print out and tape over the old one.<br /><br />
                        <div className="center">
                            <img style={{width:"50%"}} src={ShippingLabel} alt="Shipping Label" />
                        </div><br /><br />
                        If you cannot find your box and protective foam, we can ship you a new set. This will take a few days to ship. Please note that the subscription does not cancel until the return label is scanned (A reminder to save your return box and foam).<br /><br />
                        <div className="center">
                            <img style={{width:"33%"}} src={FourCamPackaged} alt="Four Camera System Packaged" />
                            <img style={{paddingLeft:"10px",width:"40%"}} src={TwoCamPackaged} alt="Two Camera System Packaged" />
                        </div>
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q9"
                    q="My camera says 'Waiting for Cell Connection' - what can I do?"
                    a={(<div>
                        Cell towers occasionally have brief data outages. When this happens our cameras cannot connect to the internet. They still run and record motion like normal, but won't allow you to access them remotely. These data outages normally only last a few minutes, but occasionally longer.<br /><br />
                        If it's been a while and the camera is still showing this error, you'll first want to verify that the camera system has power and is turned on.<br /><br />
                        If you've verified all of the power is correct, please reach out to us so we can run an analysis and get you a replacement if something is broken. Email is best using <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a>, but you may also try reaching out by calling <a href="tel:17047022012">(704)702-2012</a> 
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q10"
                    q="How do I download my data?"
                    a={(<div>
                        As long as the subscription is active, we do not delete any data for a camera system. We'll store it all for you, so you don't have to worry.<br /><br />
                        Once you're done with the camera, if you would like all of the data (this can sometimes be larger than a TeraByte, greater than 1000 GigaBytes ), just reach out to us at <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a> - we'll give you ~30 days to download a zip file with everything before it gets securely deleted off of our servers. Note that you will most likely need to purchase an external backup hard drive to store all of this data.
                    </div>)}
                />
                <QuestionAndAnswer
                    i="q11"
                    q="My camera keeps experiencing power outages, what can I do?"
                    a={(<div>
                        You have a few options.<br /><br />
                        In most parts of the country, an electrician will install a temporary power pole to provide electricity to the construction site. Oftentimes, the GFCIs, breakers, or outlets they use may be old or even slightly damaged. Included with our shipment is a brand new GFCI outlet which we recommend having your electrician install at the T-Pole. This should reduce erroneous tripping of the outlet.<br /><br />
                        Additionally, power cables over 100ft will cause even brand new GFCI outlets to trip, especially in high humidity or heat.  Try to reduce the distance from your T-Pole to your camera system.<br /><br />
                        If your power/extension cable is run along the ground, make sure it is not getting nicked or cut by yard crews, mowers, large trucks driving over it, or even just wild animals nibbling at it.  You may need to use conduit to wrap the cable from your outlet to your camera.<br /><br />
                        If you’re in a hot or humid environment, or you’re placing your camera fairly far from your outlet, you may need to have your electrician formally run an additional power pole near your camera or a dedicated electrical line directly to the camera.
                    </div>)}
                />
            </View>
        </View>
    )
}