import React from "react";
import { PageHeader } from "react-bootstrap";
import { View } from "react-native";
import CameraView from "../../images/camera-view.png";
import MotionZones from "../../images/motion-zones.png";
import Zones from "../../images/zones.png";
import SingleZone from "../../images/single-zone.png";
import ChangedSingleZone from "../../images/changed-single-zone.png";
import ComplexZone from "../../images/complex-zone.png";
import SaveZones from "../../images/save-zones.png";

export default function AdjustMotionZones() {
    return (
        <View>
            <PageHeader>How to adjust your motion zones</PageHeader>
            <View>
                <h3>
                    Zones represent where the AI looks for motion in the image.  Any vehicle or person that moves into, through, or out of the zone will trigger a motion event.<br /><br />
                    <ol>
                        <li className="helpList">
                            Navigate to your camera's live views by clicking on its name in the dashboard<br /><br />
                            <div className="center">
                                <a href="https://data.bedrockwireless.com/dashboard">data.bedrockwireless.com/dashboard</a><br /><br />
                            </div>
                            <div className="center">
                                <img style={{width:"80%"}} src={CameraView} alt="Camera View" />
                            </div>
                        </li>
                        <li className="helpList">
                            Make sure your camera is online.  If the camera is turned off our has lost power, the zones will not be editable<br /><br />
                        </li>
                        <li className="helpList">
                            Click on 'Motion Zones' or 'Zones'
                            <div className="center">
                                <img style={{width:"100px"}} src={MotionZones} alt="Motion Zones" />
                                <img style={{width:"65px"}} src={Zones} alt="Zones" />
                            </div>
                        </li>
                        <li className="helpList">
                            Adjust the circle knobs on the corners of the zone to change the shape of the zone<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={SingleZone} alt="Single Zone" />
                                <img style={{width:"80%"}} src={ChangedSingleZone} alt="Changed Single Zone" />
                            </div>
                        </li>
                        <li className="helpList">
                            To create more complex shapes, you can click on the open-circles that are on the edges of the zones to create a new corner<br /><br />
                            You can also double click on a corner to delete it.<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={ComplexZone} alt="Complex Zone" />
                            </div>
                        </li>
                        <li className="helpList">
                            When you're done editing your zones, you MUST click the 'Save Zones' button at the top of the page to update the zones at the camera<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={SaveZones} alt="Save Zones" />
                            </div>
                        </li>
                    </ol>
                </h3>
            </View>
        </View>
    )
}