import React, {useState, useEffect, useMemo} from "react";
import { addDaysToDate, addHoursToDate, setUserSession, getUser, getToken, mobileCheck, pwaInstalled, notificationsAreSupported } from "../utils/Common";
import { patchUser, getUserMotions, getSharedCaps, getUserMe } from "../utils/bedrockAPI";
import { Button } from "react-bootstrap";
import { View } from "react-native"

export default function Nudge({setErrorStatus,setErrorMessage,setShowError,showError,history}) {

    const [userMotions, setUserMotions] = useState(null);
    const [sharedCaps, setSharedCaps] = useState(null);
    const [userMeetsRequirements, setUserMeetsRequirements] = useState(false);
    const [user, setUser] = useState(null);

    async function navigateAndDismiss(nudgeType) {
        setShowError(false);
        setErrorMessage("");
        if(nudgeType==="notifications") {
            history.push('/account/notifications/nudge');
        } else if (nudgeType==="pwa") {
            history.push('/help/app');
            // window.open("https://data.bedrockwireless.com/help/app","_blank");
        } else if (nudgeType==="blocked" && mobileCheck()) {
            history.push('/help/mobile');
            // window.open("https://data.bedrockwireless.com/help/mobile","_blank");
        } else if (nudgeType==="blocked" && !mobileCheck()) {
            history.push('/help/desktop');
            // window.open("https://data.bedrockwireless.com/help/desktop","_blank");
        } else if (nudgeType.startsWith("motion")) {
            history.push('/account/notifications/motion');
        } else if (nudgeType.startsWith("power")) {
            history.push('/account/notifications/power');
        }
    }

    async function handleDismiss(nudgeType,dismiss=false) {
        if(nudgeType==="blocked") {
            updateNudge(user,"permablocked");
        } else if(dismiss) {
            updateNudge(user, nudgeType, true);
        }
        setShowError(false);
        setErrorMessage("");
    }

    const NudgeBlock = ({text,affirmitive,nudgeType,dismiss}) => {
        return (
            <div key="don't change me bro">
                {text}
                <View style={{flex:1, flexDirection:"row",paddingTop:"10px"}}>
                        {nudgeType!=="blocked" && (
                            <View style={{paddingRight:"10px"}}>
                                <Button
                                    bsStyle="info"
                                    bsSize="small"
                                    onClick={(e) => handleDismiss(nudgeType)}
                                >
                                    Remind Me Later
                                </Button>
                            </View>
                        )}
                        <View>
                            <Button
                                bsStyle="info"
                                bsSize="small"
                                onClick={(e) => navigateAndDismiss(nudgeType)}
                            >
                                {affirmitive}
                            </Button>
                        </View>
                        {dismiss && (
                            <View style={{paddingLeft:"10px"}}>
                                <Button
                                    bsStyle="info"
                                    bsSize="small"
                                    onClick={(e) => handleDismiss(nudgeType,true)}
                                >
                                    Dismiss
                                </Button>
                            </View>
                        )}
                </View>
            </div>
        );
    };

    const notificationsContent = useMemo(() => 
        <NudgeBlock 
            text={"We've added"+(mobileCheck() ? " mobile app": " desktop") +" notifications. Click below to check it out!"}
            affirmitive="Go to Notification Settings"
            nudgeType="notifications"
        />
    ,[]);

    const notificationsDismissContent = useMemo(() => 
        <NudgeBlock
            text="Friendly reminder: Notifications can greatly improve your user experience. Click below to turn these on."
            affirmitive={mobileCheck() ? "Notification Settings" : "Go to Notification Settings"}
            nudgeType="notifications"
            dismiss={true}
        />
    ,[]);

    const pwaContent = useMemo(() => 
        <NudgeBlock
            text="You can now install our web app on your mobile device! Click below to see instructions."
            affirmitive="View Instructions"
            nudgeType="pwa"
        />
    ,[]);

    const pwaDismissContent = useMemo(() => 
        <NudgeBlock
            text="Friendly reminder: downloading our web app will result in a better user experience. Click below for instructions, or access these instructions under 'Help'"
            affirmitive="View Instructions"
            nudgeType="pwa"
            dismiss={true}
        />
    ,[]);

    const blockedContent = useMemo(() =>
        <NudgeBlock
            text={"It looks like you’ve requested notifications for this type of device but you’re currently blocking notifications in your "+(mobileCheck()?"app":"browser")+".  Would you like instructions for allowing notifications?"}
            affirmitive="View Instructions"
            nudgeType="blocked"
            dismiss={true}
        />
    ,[]);

    const motionContent = useMemo(() => 
        <NudgeBlock
            text={"Would you like to configure your motion notifications? Click below to access those settings for "+(mobileCheck()?"mobile":"desktop")}
            affirmitive={mobileCheck() ? "Motion Notifications" : "Go to Motion Notification Settings"}
            nudgeType={"motion"+(mobileCheck()?"AppNotifications":"DesktopNotifications")}
            dismiss={true}
        />
    ,[]);

    const powerContent = useMemo(() => 
        <NudgeBlock
            text={"Would you like to get power outage notifications? Click below to access those settings for "+(mobileCheck()?"mobile":"desktop")}
            affirmitive={mobileCheck() ? "Power Notifications" : "Go to Power Notification Settings"}
            nudgeType={"power"+(mobileCheck()?"AppNotifications":"DesktopNotifications")}
            dismiss={true}
        />
    ,[]);

    const updateNudge = async function (u,nudgeType,dismiss=false) { // Should this be an api call?
        const newNudgeDate = (new Date()).toISOString();
        let newSettings;
        if(!u) {
            u = getUser();
            setUser(u);
        }
        if(!u.settings) {
            newSettings = {};
        } else {
            newSettings = JSON.parse(JSON.stringify(u.settings));
        }
        // default fib is 1
        let fibonacci = 1;
        if(!newSettings.nudge) {
            newSettings.nudge = {};
        } else {
            // Get fib for nudge type
            if(newSettings.nudge[nudgeType]) {
                if(newSettings.nudge[nudgeType].fibonacci) {
                    fibonacci = newSettings.nudge[nudgeType].fibonacci+1;
                }
            }
        }
        // Master nudge so we don't nudge multiple things in a row
        newSettings.nudge.lastNudge = {date:newNudgeDate};
        // When the user dismisses a blocked notification warning (they really want it blocked)
        if(nudgeType==='permablocked') {
            if(mobileCheck()) {
                newSettings.nudge['blockedAppNotifications'] = {date:newNudgeDate,fibonacci:100,dismiss:true}
            } else {
                newSettings.nudge['blockedDesktopNotifications'] = {date:newNudgeDate,fibonacci:100,dismiss:true}
            }
        } else {
            newSettings.nudge[nudgeType] = {date:newNudgeDate,fibonacci:fibonacci}
            if(dismiss) {
                newSettings.nudge[nudgeType].dismiss = true;
                if(u.settings && u.settings.nudge && u.settings.nudge[nudgeType] && u.settings.nudge[nudgeType].date) {
                    if(['blockedAppNotifications','blockedDesktopNotifications','appNotifications','desktopNotifications'].includes(nudgeType)) {
                        if(addDaysToDate(u.settings.nudge[nudgeType].date,90)>newNudgeDate) {
                            // Reset the dismiss value every 90 days on blocked nudges
                            newSettings.nudge[nudgeType].dismiss = false; 
                        }
                    }
                }
            }
        }
        await patchUser(u.userId,{settings:newSettings});
        let tempUser = JSON.parse(JSON.stringify(u));
        tempUser.settings = newSettings;
        setUser(tempUser);
    }

    const shouldINudge = function (u,nudgeType) { // Should this be an API call?
        const now = new Date();
        if(!u) u = getUser();
        // User must be at least a week old
        if(u && u.addDate) {
            if(addDaysToDate(u.addDate,7)>now) {
                return false;
            }
        }
        if(u && u.settings && u.settings.nudge) {
            // console.log(u.settings.nudge);
            let nudgeOkay = false;
            if(u.settings.nudge.lastNudge) {
                console.log(u.settings.nudge.lastNudge);
                const masterNudge = u.settings.nudge.lastNudge.date;
                if(addDaysToDate(masterNudge,1)<now) {
                // if(addHoursToDate(masterNudge,0.001)>now) { // TESTING TODO SWAP
                    // user was nudged (any category) over a day ago
                    nudgeOkay = true;
                }
            } else {
                // user has never been nudged
                nudgeOkay = true;
            }
            if(nudgeOkay) { // master nudge is older than 1 day (nudge once per day max)
                if(u.settings.nudge[nudgeType]) {
                    const lastNudge = u.settings.nudge[nudgeType].date;
                    const dismissed = u.settings.nudge[nudgeType].dismiss;
                    if(dismissed) { 
                        // If the user keeps blocking notifications, reattmpt every 90 days
                        if(['blockedAppNotifications','blockedDesktopNotifications','appNotifications','desktopNotifications'].includes(nudgeType)) {
                            if(addDaysToDate(lastNudge,90)>now) {
                                return true;
                            }
                        }
                        // We've already shown the nudge 5 times over 15 days or they dismissed the nudge
                        return false;
                    }
                    if(addDaysToDate(lastNudge,3)<now) { // Show nudge every 3 days
                    // if(addHoursToDate(lastNudge,0.01)<now) { // TESTING TODO SWAP
                        return true;
                    }
                    // user was just nudged on this topic
                    return false;
                } else {
                    // user hasn't been nudged on this topic yet
                    return true;
                }
            } else {
                // user was nudged by any nudegType less than 1 day ago
                return false;
            }
        } else {
            // user hasn't had any nudge interaction yet
            return true;
        }
    }

    async function showNudge(user,content,update) {
        // console.log("who is calling me",update);
        setErrorMessage(content);
        setErrorStatus("nudge-message");
        setShowError(true);
        updateNudge(user,update);
    }

    function checkNudgedTooManyTimes(user,nudgeType,tooManyTimes=5) {
        if(user.settings && user.settings.nudge) {
            if(user.settings.nudge[nudgeType]) {
                if(user.settings.nudge[nudgeType].fibonacci > tooManyTimes) {
                    return true;
                }
            }
        }
        return false;
    }

    // Get User first (from db updated)
    useEffect(() => {
        let isMounted = true;
        
        async function fetchUser() {
            const tempUser = await getUserMe();
            if(isMounted) {
                setUserSession(getToken(),tempUser);
                setUser(tempUser);
            }
        }

        fetchUser();
        
        return () => { isMounted = false; };
    },[]);

    // 2 --> Get usercaps and usermotions
    useEffect(() => {
        let isMounted = true;

        async function fetchUserMotions(userId) {
            const tempUserMotions = await getUserMotions(userId);
            if(isMounted)
                setUserMotions(tempUserMotions);
        }

        async function fetchSharedCaps(userId) {
            const tempSharedCaps = await getSharedCaps(userId);
            if(isMounted) 
                setSharedCaps(tempSharedCaps);
        }

        if(user) {
            fetchUserMotions(user.userId);
            fetchSharedCaps(user.userId);
        }
        return () => { isMounted = false; };
    },[user]);

    useEffect(() => {
        let isMounted = true;
        // NUDGE when user has no userMotions [including deleted ones - at least they tried] 
        if(user && showError!==null && !showError && user.settings) {  // make sure no nudge is already active
            if(userMotions!==null) { // done fetching userMotions
                if(userMotions.length===0) {
                    const mobile = mobileCheck();
                    const permissions = notificationsAreSupported() && Notification.permission;
                    let nudgeType;
                    // Enabled everything but no specific notification configs created
                    if(!mobile && user.settings.desktopEnabled && permissions==="granted") {
                        nudgeType='motionDesktopNotifications';
                        if(shouldINudge(user,nudgeType)) {
                            if(isMounted) {
                                showNudge(user,motionContent,nudgeType);
                            }
                        }
                    } else if(mobile && user.settings.appEnabled && permissions==="granted") {
                        nudgeType='motionAppNotifications';
                        if(shouldINudge(user,nudgeType)) {
                            if(isMounted) {
                                showNudge(user,motionContent,nudgeType);
                            }
                        }
                    }
                }
            }
        }
        return () => { isMounted = false; };
    },[user,userMotions]);

    // MAIN FUNCTION FOR CALLING NUDGES
    useEffect(() => {
        let isMounted = true;
        if(userMeetsRequirements) {
            const mobile = mobileCheck();
            const pwa = pwaInstalled();
            const userPWA = user && user.settings && user.settings.pwaInstalled
            const permissions = notificationsAreSupported() && Notification.permission;  

            // Don't run if already has error message
            if(showError!==null && !showError && user) { 
                let nudged = false;
                let nudgeType;
                // Haven't install pwa yet
                if(mobile && !pwa && !userPWA) {
                    nudgeType = 'pwa';
                    if(shouldINudge(user,nudgeType)) {
                        if(checkNudgedTooManyTimes(user,nudgeType,10)) {
                            if(isMounted) {
                                showNudge(user,pwaDismissContent,nudgeType);
                                nudged = true;
                            }
                        } else {
                            if(isMounted) {
                                showNudge(user,pwaContent,nudgeType);
                                nudged = true;
                            }
                        }
                    }
                }
                // Haven't looked at notifications yet (mobile)
                if(!nudged && mobile && (user.settings == null || user.settings.appEnabled == null)) {
                    nudgeType = 'appNotifications';
                    if(shouldINudge(user,nudgeType)) {
                        if(checkNudgedTooManyTimes(user,nudgeType)) {
                            if(isMounted) {
                                showNudge(user,notificationsDismissContent,nudgeType);
                                nudged = true;
                            }
                        } else {
                            if(isMounted) {
                                showNudge(user,notificationsContent,nudgeType);
                                nudged = true;
                            }
                        }
                        
                    }
                }
                // Haven't looked at notificatinos yet (desktop)
                if(!nudged && !mobile && (user.settings == null || user.settings.desktopEnabled == null)) {
                    nudgeType = 'desktopNotifications';
                    if(shouldINudge(user,nudgeType)) {
                        if(checkNudgedTooManyTimes(user,nudgeType)) {
                            if(isMounted) {
                                showNudge(user,notificationsDismissContent,nudgeType);
                                nudged = true;
                            }
                        } else {
                            if(isMounted) {
                                showNudge(user,notificationsContent,nudgeType);
                                nudged = true;
                            }
                        }
                    }
                }
                // Want Desktop notifications, but blocked
                if(!nudged && !mobile && user.settings && user.settings.desktopEnabled && permissions!=="granted") {
                    if(shouldINudge(user,'blockedDesktopNotifications')) {
                        if(isMounted) {
                            showNudge(user,blockedContent,'blockedDesktopNotifications');
                            nudged = true;
                        }
                    }
                }
                // Want app notifications, but blocked
                if(!nudged && mobile && user.settings && user.settings.appEnabled && permissions!=="granted") {
                    if(shouldINudge(user,'blockedAppNotifications')) {
                        if(isMounted) {
                            showNudge(user,blockedContent,'blockedAppNotifications');
                            nudged = true;
                        }
                    }
                }
                // User has enabled stuff but not setup any app notifications (power)
                if(!nudged && sharedCaps!==null) {
                    let powerAppNotficationsPerCap = false;
                    for(var i=0; i<sharedCaps.length; i++) {
                        if(sharedCaps[i].appNotifications) {
                            powerAppNotficationsPerCap = true;
                            break;
                        }
                    }
                    if(!powerAppNotficationsPerCap) {
                        if(!mobile && user.settings && user.settings.desktopEnabled && permissions==="granted") {
                            nudgeType='powerDesktopNotifications';
                            if(shouldINudge(user,nudgeType)) {
                                // Defaulty has dimsiss button, no need for different message after N times
                                if(isMounted) {
                                    showNudge(user,powerContent,nudgeType);
                                    nudged = true;
                                }
                            }
                        } else if(mobile && user.settings && user.settings.appEnabled && permissions==="granted") {
                            nudgeType='powerAppNotifications';
                            if(shouldINudge(user,nudgeType)) {
                                // Defaulty has dimsiss button, no need for different message after N times
                                if(isMounted) {
                                    showNudge(user,powerContent,nudgeType);
                                    nudged = true;
                                }
                            }
                        }
                    }
                }
                // TESTING TODO TODO TODO REMOVE
                // nudgeType = 'desktopNotifications'
                // showNudge(user,notificationsContent,nudgeType);
                // if(shouldINudge(user,nudgeType))
                //     showNudge(user,motionContent,nudgeType);
            }
        }
        return () => { isMounted = false; };
    },[userMeetsRequirements]);

    useEffect(() => {
        let isMounted = true;
        // FIRST CHECK TO SEE IF USER HAS ACCESS FOR CAPS THAT MAKE SENSE TO NUDGE THEM
        if(!userMeetsRequirements && sharedCaps) {
            let access = false;
            if(user && user.role==='ADMIN') {
                access = true;
            } else {
                for(var i=0; i<sharedCaps.length; i++) {
                    if(sharedCaps[i].accessType==='VIEWER') {
                        access = true;
                        break;
                    }
                    if(sharedCaps[i].accessType==='OWNER') {
                        access = true;
                        break;
                    }
                }
            }
            if(isMounted && access) {
                setUserMeetsRequirements(true);
            }
        }
        return () => { isMounted = false; };
    },[sharedCaps]);

    return (<div></div>);
}