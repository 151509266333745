import React, { useState } from "react";
import { View } from "react-native";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Activate.css";
import { postActivateAccount, patchUser } from "../utils/bedrockAPI";

export default function Activate(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    function renderActivation() {
        function validateForm() {
            if(password.length < 8 || confirmPassword !== password) {
                return false;
            }
            if(firstName.length===0 || lastName.length===0) {
                return false
            }
            return true;
        }

        async function handleAccountActivation(event) {
            event.preventDefault();
            setIsLoading(true);
            // Activate account and retrieve temporary pw
            // console.log(props.match.params.userid,props.match.params.hash)
            try {
                const decodedHash = decodeURIComponent(props.match.params.hash)
                const token = await postActivateAccount(props.match.params.userid,decodedHash,password);
                // Add user information
                const addUsersName = {'firstName':firstName,'lastName':lastName};
                await patchUser(props.match.params.userid,addUsersName,token)
            } catch(e) {
                props.setShowError(true);
                props.setErrorStatus("error-message");
                props.setErrorMessage("Authorization Link invalid, please make sure you find the latest activation email from Bedrock Wireless");
            }
            setIsLoading(false);
            props.history.push(`/caps/${props.match.params.capid}`);
            // Probably route to login page?
        }

        return (
            <View>
                <div className="instructions">
                    <b>Please fill out your name and set your password to activate your account</b>
                    <br />
                    You will need to login once activated.
                </div>
                <form className="activate" onSubmit={e => handleAccountActivation(e)}>
                    {/* <FormGroup controlId="instructions" bsSize="large">
                        <ControlLabel>Please fill out your name and set your password to activate your account</ControlLabel>
                    </FormGroup> */}
                    <FormGroup controlId="firstName" bsSize="large">
                        <ControlLabel>First Name</ControlLabel>
                        <FormControl
                            autoFocus
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlId="lastName" bsSize="large">
                        <ControlLabel>Last Name</ControlLabel>
                        <FormControl
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Set Password</ControlLabel>
                        <FormControl
                            value={password}
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlid="confirmPassword" bsSize="large">
                        <ControlLabel>Confirm Password</ControlLabel>
                        <FormControl
                            value={confirmPassword}
                            type="password"
                            onChange={e => setConfirmPassword(e.target.value)}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Activate Your Account
                    </LoaderButton>
                </form>
            </View>
        )
    }

    return (
        <div className="Activate">
            {renderActivation()}
        </div>
    )
}