import React, {useState, useEffect, useRef} from "react";
import { getCap, storeCap, getUser, getUserInformation } from "../utils/Common";
import { Button, Glyphicon, PageHeader } from "react-bootstrap";
import { View } from "react-native";
import "./Camera.css";
import { loadCap, getCapture, getCaptureImages, putEventName, recordUserAction } from "../utils/bedrockAPI";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ContentEditable from "react-contenteditable";

export default function Snapshot(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [cap,setCap] = useState(null);
    const [buttonSize, setButtonSize] = useState("large");
    const [capture, setCapture] = useState(null);
    const [captureImages, setCaptureImages] = useState(null);
    const [motionEvent, setMotionEvent] = useState(null);
    const nameElement = useRef(null);
    var captureName = useRef("");

    // 1 - Screen size
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    // 2a - Load the cap from memory 1st then api
    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);                    
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"snapshot360",props.match.params.capid,{'captureId':props.match.params.captureid,'userData':getUserInformation()});
    },[props.match.params.capid])

    // 2b - Capture Info
    useEffect(() => {
        async function accessEvent() {
            try {
                setIsLoading(true);
                var tempCapture = await getCapture(props.match.params.captureid);
                var tempMotionEvent = null;
                for(var i=0; i<tempCapture.events.length; i++) {
                    if(tempCapture.events[i].motion) {
                        tempMotionEvent = tempCapture.events[i];
                    }
                }
                if(tempMotionEvent) {
                    captureName.current = tempMotionEvent.name;
                    setMotionEvent(tempMotionEvent);
                }
                else {
                    captureName.current = tempCapture.events[0].name;
                }
                setCapture(tempCapture);
                setIsLoading(false);
            } catch(e) {    
                console.log(e);
            }
        }
        accessEvent();
    },[props.match.params.captureid]);

    // 3 - Load Images from capture
    useEffect(() => {
        async function accessEventImages() {
            try {
                var tempImages = await getCaptureImages(props.match.params.captureid);
                setCaptureImages(tempImages);
            } catch (e) {
                console.log(e);
            }
        }
        if(capture && capture.events) {
            accessEventImages();
        }
    },[capture]);

    var highlightAll = function(e) {
        var range = document.createRange();
        range.selectNodeContents(nameElement.current.getEl());
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    function renderEventImage() {
        var handleNameChange = async function(e) {
            const name = e.target.value.replace(/(<([^>]+)>)/ig,"");
            captureName.current = name;
        };

        var saveToServer = function(e) {
            console.log(captureName);
            if(motionEvent) {
                putEventName(motionEvent.eventId, captureName.current);
            } else {
                putEventName(capture.events[0].eventId, captureName.current);
            }
        }
      
        var pasteAsPlainText = function(e) {
            e.preventDefault();
        
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertHTML',false,text);
        };
      
        var disableNewLines = function(e) { 
            const keyCode = e.keyCode || e.which;
    
            if(keyCode === 13) {
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
                const element = (e.currentTarget || e.srcElement);
                element.blur();
            }
        };

        return (
            <View style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <PageHeader style={{marginTop:"-10px"}}>
                    <View style={{flex:1, flexDirection:"row",justifyContent:'space-between'}}>
                        {/* {console.log(getUser().caps[props.match.params.capid])} */}
                        <ContentEditable
                            html={captureName.current}
                            // className="ContentEditable-header"
                            disabled={isLoading || getUser().caps[props.match.params.capid].accessType!=="OWNER"}
                            onPaste={pasteAsPlainText}
                            onKeyPress={disableNewLines}
                            onFocus={highlightAll}
                            onChange={handleNameChange}
                            onBlur={saveToServer}
                            ref={nameElement}
                        />
                    </View>
                </PageHeader>
                <View style={{flex: 1, flexDirection: "column", justifyContent: "center"}}>
                    <View style={{flex:1, flexDirection: "row", justifyContent: "center"}}>
                        <View style={{width:"50%", maxWidth: "2592px", height: undefined, aspectRatio:1.4667, padding: "5px"}}>
                            { captureImages && captureImages.house && (
                                <TransformWrapper>
                                    <TransformComponent>
                                        <View style={{flex:1, alignItems:"flex-start"}}>
                                            <img src={captureImages.house.url} alt="" />
                                        </View>
                                    </TransformComponent>
                                </TransformWrapper>
                            )}
                        </View>
                        <View style={{width:"50%", maxWidth: "2592px", height: undefined, aspectRatio:1.4667, padding: "5px"}}>
                            { captureImages && captureImages.neighbor && (
                                <TransformWrapper>
                                    <TransformComponent>
                                        <View style={{flex:1, alignItems:"flex-start"}}>
                                            <img src={captureImages.neighbor.url} alt="" />
                                        </View>
                                    </TransformComponent>
                                </TransformWrapper>
                            )}
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection: "row", justifyContent: "center"}}>
                        <View style={{width:"50%", maxWidth: "2592px", height: undefined, aspectRatio:1.4667, padding: "5px"}}>
                            { captureImages && captureImages.street && (
                                <TransformWrapper>
                                    <TransformComponent>
                                        <View style={{flex:1, alignItems:"flex-start"}}>
                                            <img src={captureImages.street.url} alt="" />
                                        </View>
                                    </TransformComponent>
                                </TransformWrapper>
                            )}
                        </View>
                        <View style={{width:"50%", maxWidth: "2592px", height: undefined, aspectRatio:1.4667, padding: "5px"}}>
                            { captureImages && captureImages.driveway && (
                                <TransformWrapper>
                                    <TransformComponent>
                                        <View style={{flex:1, alignItems:"flex-start"}}>
                                            <img src={captureImages.driveway.url} alt="" />
                                        </View>
                                    </TransformComponent>
                                </TransformWrapper>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    function renderHeader() {

        // async function removeEvent() {
        //     setIsLoading(true);
        //     if(window.confirm(`Are you sure you wish to delete ${event.name}?`)) {
        //         await deleteEventVideo(event.eventId);
        //         props.history.push(`/caps/${cap.capId}/events`)
        //     }
        //     setIsLoading(false);
        // }

        return (
            <div>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between',padding:10}}>
                    <Button
                        bsStyle="primary"
                        bsSize={buttonSize}
                        // disabled={isLoading}
                        onClick={() => props.history.push(`/caps/${cap.capId}/events`)}
                    >
                        Event Dashboard
                    </Button>
                    <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                        <Button
                            bsStyle="info"
                            bsSize={buttonSize}
                            // disabled={isLoading}
                            onClick={() => window.location.reload(false)}
                        >
                            <Glyphicon glyph="refresh"></Glyphicon>
                        </Button>
                        {getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                            <React.Fragment>
                                {/* <View style={{paddingRight: "5px"}}>
                                    <Button
                                        bsStyle="danger"
                                        bsSize={buttonSize}
                                        onClick={() => removeEvent()}
                                    >
                                        <Glyphicon glyph="remove" />
                                    </Button>
                                </View> */}
                                <View style={{paddingRight: "5px"}}>
                                    <Button
                                        bsStyle="success"
                                        bsSize={buttonSize}
                                        onClick={() => highlightAll()}
                                    >
                                        <Glyphicon glyph="edit" />
                                    </Button>
                                </View>
                                <View style={{paddingRight: "5px"}}>
                                    <Button
                                        bsStyle="warning"
                                        bsSize={buttonSize}
                                        onClick={() => props.history.push(`/caps/${cap.capId}/360events/${props.match.params.captureid}`)}
                                    >
                                        <Glyphicon glyph="film" />
                                    </Button>
                                </View>
                            </React.Fragment>
                        )}
                    </View>
                </View>
                {capture && captureImages && renderEventImage()}
            </div>
        )
    }
    
    return (
        <div className="Cameras">
            {!isLoading && renderHeader()}
        </div>
    );
}