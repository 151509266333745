
import React, { useEffect, useState, useRef } from "react";
import "./Frames.css";
import { View } from "react-native";
import { getCap, 
    storeCap, 
    reformatDate, 
    getUserInformation, 
    getUser, 
    addDaysToDate,
    filterOptions,
    getDefaultFrameParameters,
    makeMonitorFilter,
    setDefaultFrameParameters
} from "../utils/Common";
import { loadCap, getFrames, recordUserAction, getMonitors } from "../utils/bedrockAPI";
import { ButtonGroup, 
    PageHeader, 
    Button, 
    ListGroup, 
    ListGroupItem, 
    Glyphicon, 
    OverlayTrigger,
    Tooltip,
    // Dropdown,
    // MenuItem,
    // Checkbox
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";

export default function Frames(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [buttonSize, setButtonSize] = useState("large");
    const [cap,setCap] = useState(null);
    const [monitors, setMonitors] = useState(null);
    const [frames, setFrames] = useState(null);
    const [nextPageFrames,setNextPageFrames] = useState(null);
    // const [chevron, setChevron] = useState("chevron-down")
    var tempSelectedFilters = {}
    for(var i=0; i<filterOptions.length; i++) {
        tempSelectedFilters[filterOptions[i]] = true;
    }
    const tempFrameParameters = {
        selectedFilters:tempSelectedFilters,
        pageNumber:0,
        chevron:"chevron-down",
        startDate:addDaysToDate(new Date(),-90),
        endDate:new Date()
    };
    const [frameParameters,setFrameParameters] = useState(tempFrameParameters);
    const componentMounted = useRef(true);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        return () => { componentMounted.current = false; };
    },[]);

    useEffect(() => {
        let isMounted = true;
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid ) {
                    tempCap = await loadCap(props.match.params.capid);
                    if(isMounted)
                        storeCap(tempCap);
                }
                if(isMounted) {
                    setCap(tempCap);
                    const tempMonitors = await getMonitors(props.match.params.capid);
                    if(isMounted) {
                        setMonitors(tempMonitors);
                        setIsLoading(false);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }
        var storedParameters = getDefaultFrameParameters();
        if(storedParameters && storedParameters[props.match.params.capid]) {
            setFrameParameters(storedParameters[props.match.params.capid]);
        }
        accessCap();
        recordUserAction(getUser().userId,"frames",props.match.params.capid,{'userData':getUserInformation()});
        return () => { isMounted = false; };
    },[props.match.params.capid]);

    useEffect(() => {
        let rerender = true;
        if(cap && monitors) {
            setIsLoading(true);
            if(rerender)
                changePage(0,frameParameters.pageNumber,frameParameters.chevron,frameParameters.selectedFilters,frameParameters.startDate,frameParameters.endDate);
            setIsLoading(false);
        }
        return () => rerender = false;
    },[cap,monitors]);

    const changeStartDate = function(startTime) {
        if(cap!==null && frames!==null) {
            changePage(0,frameParameters.pageNumber,frameParameters.chevron,frameParameters.selectedFilters,startTime,frameParameters.endDate);
        }
    };

    const changeEndDate = function(endTime) {
        if(cap!==null && frames!==null) {
            changePage(0,frameParameters.pageNumber,frameParameters.chevron,frameParameters.selectedFilters,frameParameters.startDate,endTime);
        }
    }

    async function changePage(n,pn,sortDirection=null,filters=null,sd=null,ed=null) {
        setIsLoading(true);
        setFrames([]);
        var storedFrameParameters = getDefaultFrameParameters();
        if(!storedFrameParameters) storedFrameParameters = {};
        if(!storedFrameParameters[cap.capId]) storedFrameParameters[cap.capId] = {};
        var monitorIds = makeMonitorFilter(monitors,filters);
        var tempFrameParams = JSON.parse(JSON.stringify(frameParameters));
        tempFrameParams.pageNumber = pn+n;
        tempFrameParams.chevron = sortDirection;
        tempFrameParams.selectedFilters = filters;
        tempFrameParams.startDate = sd;
        tempFrameParams.endDate = ed;
        setFrameParameters(tempFrameParams);
        storedFrameParameters[cap.capId] = tempFrameParams;
        if(sd) sd = sd.toLocaleString("en-US",{timezone:"UTC"});
        if(ed) ed = ed.toLocaleString("en-US",{timezone:"UTC"});
        var dir = "captureDate DESC";
        if(sortDirection==="chevron-up") {
            dir = "captureDate ASC";
        }
        const tempFrames = await getFrames(cap.capId,pn+n,dir,monitorIds,sd,ed);
        if(componentMounted.current) {
            setFrames(tempFrames);
            // console.log(tempFrames);
            const tempNextPageFrames = await getFrames(cap.capId,pn+n+1,dir,monitorIds,sd,ed);
            if(componentMounted.current) {
                setNextPageFrames(tempNextPageFrames);
                
                setDefaultFrameParameters(storedFrameParameters);
                setIsLoading(false);
            }
        }
    }

    async function sort() {
        if(frameParameters.chevron==="chevron-down") {
            changePage(0,0,"chevron-up",frameParameters.selectedFilters,frameParameters.startDate,frameParameters.endDate);
        } else {
            changePage(0,0,"chevron-down",frameParameters.selectedFilters,frameParameters.startDate,frameParameters.endDate);
        }
    }

    // async function filter(eventKey) {
    //     var tempFP = JSON.parse(JSON.stringify(frameParameters));
    //     tempFP.selectedFilters[filterOptions[Number(eventKey)]] = !tempFP.selectedFilters[filterOptions[Number(eventKey)]];
    //     setFrameParameters(tempFP);
    //     changePage(0,0,tempFP.chevron,tempFP.selectedFilters,startDate,endDate);
    // }

    var prevPage = function() {
        changePage(-1,frameParameters.pageNumber,frameParameters.chevron,frameParameters.selectedFilters,frameParameters.startDate,frameParameters.endDate);
    }

    var nextPage = function() {
        changePage(1,frameParameters.pageNumber,frameParameters.chevron,frameParameters.selectedFilters,frameParameters.startDate,frameParameters.endDate);
    }

    function renderPageButtons(buttonType="default") {
        return (
            <ButtonGroup>
                <Button
                    bsStyle={buttonType}
                    bsSize={buttonSize}
                    onClick={prevPage}
                    disabled={frameParameters.pageNumber===0}
                >
                    <Glyphicon glyph="arrow-left" />
                </Button>
                <Button
                    disabled={true}
                    bsStyle={buttonType}
                    bsSize={buttonSize}
                >
                    {frameParameters.pageNumber+1}
                </Button>
                <Button
                    bsStyle={buttonType}
                    bsSize={buttonSize}
                    onClick={nextPage}
                    disabled={!nextPageFrames || nextPageFrames.length===0}
                >
                    <Glyphicon glyph="arrow-right" />
                </Button>
            </ButtonGroup>
        );
    }

    function renderFramesList() { // TODO: Why isn't this rendering?
        return (frames && frames.length > 0 && (frames.map((frame,i) => (
            <View key={frame.captureId}>
                <LinkContainer to={`/caps/${cap.capId}/frames/${frame.captureId}`}>
                    <ListGroupItem header={reformatDate(frame.captureDate,null)} />
                </LinkContainer>
            </View>
        ))));
    }

    function renderFrames() {
        const biggerFontMUI = createTheme({
            overrides: {
                MuiInputLabel: {
                    root: {
                        fontSize: "50px",
                    }
                }
            }
        });
        const tooltip = (
            <Tooltip id="refresh" style={{fontSize:"18px"}}>
                Refresh to load new events
            </Tooltip>
        )

        return (
            <View style={{flex:1,flexDirection:"column"}}>
                <View style={{flex:1,flexDirection:"row", justifyContent:"space-between"}}>
                    <ButtonGroup>
                        <Button
                            bsStyle="primary"
                            bsSize={buttonSize}
                            disabled={isLoading}
                            onClick={() => props.history.push(`/caps/${cap.capId}`)}
                        >
                            Back to CAP
                        </Button>
                        <Button
                            bsStyle="primary"
                            bsSize={buttonSize}
                            disabled={isLoading}
                            onClick={()=> props.history.push(`/caps/${cap.capId}/events`)}
                        >
                            Motion History
                        </Button>
                    </ButtonGroup>
                </View>
                <PageHeader style={{marginTop:"-10px"}}>
                    {cap && (
                        <div>
                            {cap.name}<small> {cap.neighborhood && "("+cap.neighborhood+")"}</small>
                        </div>
                    )}
                </PageHeader>
                <View style={{paddingBottom:"10px", zIndex:1000}}>
                    <View style={{flex:1, flexDirection:"row"}}>
                        <h2 style={{marginTop:"0px"}}>
                            Look up frames
                        </h2>
                    </View>
                    <View style={{flex:1, flexDirection:"row",zIndex:1000,justifyContent:"flex-start",flexWrap:"wrap"}}>
                        <View style={{flex:1, flexDirection:"row",zIndex:1000}}>
                            <ThemeProvider theme={biggerFontMUI} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <View style={{paddingLeft:"5px"}}>
                                        <div>
                                            Start Range
                                        </div>
                                        <DateTimePicker value={frameParameters.startDate} onChange={changeStartDate} maxDate={new Date()} />
                                    </View>
                                    <View style={{paddingLeft:buttonSize==="small"?"5px":"15px"}}>
                                        <div>
                                            End Range
                                        </div>
                                        <DateTimePicker value={frameParameters.endDate} onChange={changeEndDate} minDate={frameParameters.startDate}/>
                                    </View>
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </View>
                        <View style={{paddingTop:"10px", paddingBottom:"10px", minWidth:"102px", flex:1, flexDirection:"row", justifyContent:"flex-end", alignSelf:"flex-end", alignContent:"flex-end", zIndex:999}}>
                            <View style={{paddingRight:"5px"}}>
                                <OverlayTrigger placment="left" overlay={tooltip}>
                                    <Button
                                        bsStyle="default"
                                        bsSize={buttonSize}
                                        disabled={isLoading}
                                        onClick={() => {changePage(0,0,frameParameters.chevron,frameParameters.selectedFilters,frameParameters.startDate,frameParameters.endDate)}}
                                    >
                                        <Glyphicon glyph="refresh" />
                                    </Button>
                                </OverlayTrigger>
                            </View>
                            
                            {/* TODO: include this once you figure out loopback
                             { frames && (
                                <View style={{paddingRight: "5px"}}>
                                    <Dropdown
                                        bsStyle="default"
                                        bsSize={buttonSize}
                                        key="1"
                                        id="filterDropdownSelector"
                                        style={{zIndex:1000}}
                                    >
                                        <Dropdown.Toggle noCaret>
                                            <Glyphicon glyph="filter" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filterOptions.map((f,i)=>(
                                                <MenuItem key={f} eventKey={i.toString()} onSelect={filter}>
                                                    <Checkbox onChange={() => void(0)} checked={frameParameters.selectedFilters[f]||false} value={f || ''}>{f}</Checkbox>
                                                </MenuItem>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </View>
                            )} */}
                            <View style={{paddingRight:"5px"}}>
                                <Button
                                    bsStyle="default"
                                    bsSize={buttonSize}
                                    disabled={isLoading}
                                    onClick={sort}
                                >
                                    Date&nbsp;<Glyphicon glyph={frameParameters.chevron} />
                                </Button>
                            </View>
                            {renderPageButtons()}
                        </View>
                    </View>
                </View>
                {cap && frames && (
                    <ListGroup style={{zIndex:500}}>
                        {!isLoading && renderFramesList()}
                    </ListGroup>
                )}
                {!isLoading && (
                    <View style={{flex:1, flexDirection:"row",justifyContent:"flex-end"}}>
                        {renderPageButtons()}
                    </View>
                )}
            </View>
        )
    }
    return (
        <div className="Frames">
            {renderFrames()}
        </div>
    )
}