import React, { useEffect, useState } from "react";
import { 
    TabContainer, 
    Tab, 
    Row, 
    Col, 
} from "react-bootstrap";
import { View } from "react-native";
import "./Account.css";
// import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses } from "react-pro-sidebar";
import ChangePassword from "./ChangePassword";
import AccountInfo from "./AccountInfo";
import BillingInfo from "./BillingInfo";
import Integrations from "./Integrations";
import MasterNotifications from "./MasterNotifications";
import Help from "./Help";

export default function Account(props) {
    const [subMenuKey, setSubMenuKey] = useState("info");
    
    const titles = {"master":"Notifications","power":"Power Notifications","motion":"Motion Notifications"};

    useEffect(() => {
        if(props.match.params.submenu) {
            setSubMenuKey(props.match.params.submenu);
        }
    },[]);

    useEffect(() => {
        if(subMenuKey) {
            window.history.replaceState(null,"New Page Title","/account/"+subMenuKey);
        }
    },[subMenuKey]);

    const MenuLink = ({title,eventKey}) => (
        <MenuItem onClick={() => setSubMenuKey(eventKey)}>
            {title}
        </MenuItem>
    );

    function renderAccountMenus() {
        return (
            <View style={{flex:1, flexDirection:"column"}}>
                <TabContainer id="default" defaultActiveKey="info" activeKey={subMenuKey} onSelect={k => setSubMenuKey(k)} >
                    <Row>
                        <Col sm={3}>
                            <Sidebar 
                                onBackdropClick={k => setSubMenuKey(k)} 
                                rootStyles={{
                                    [`.${sidebarClasses.container}`]: {
                                    backgroundColor: '#f8f8f8',
                                    borderColor:"#e7e7e7",
                                    borderRadius: "4px",
                                    border: "1px solid transparent"
                                    },
                                }}
                            >
                                <Menu>
                                    <MenuLink title="Account Information" eventKey="info" />
                                    <SubMenu label="Notifications">
                                        <MenuLink title="Master Controls" eventKey="master" />
                                        <MenuLink title="Power Notifications" eventKey="power" />
                                        <MenuLink title="Motion Notifications" eventKey="motion" />
                                    </SubMenu>
                                    <MenuLink title="Change Your Password" eventKey="password" />
                                    <MenuLink title="Integrations" eventKey="integrations" />
                                    <MenuLink title="Help Docs" eventKey="help" />
                                </Menu>
                            </Sidebar>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="info">{AccountInfo(props)}</Tab.Pane>
                                {/* <Tab.Pane eventKey="notifications">{Notifications(props)}</Tab.Pane> */}
                                <Tab.Pane eventKey="billing">{BillingInfo(props)}</Tab.Pane>
                                <Tab.Pane eventKey="password">{ChangePassword(props)}</Tab.Pane>
                                <Tab.Pane eventKey="integrations">{Integrations(props)}</Tab.Pane>
                                <Tab.Pane eventKey="master">{MasterNotifications(props)}</Tab.Pane>
                                <Tab.Pane eventKey="help">{Help(props)}</Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </TabContainer>
            </View>
        )
    }

    return (
        <div className="Account" style={{paddingTop:"10px"}}>
            {renderAccountMenus()}
        </div>
    )
}