import React, { useEffect, useState } from "react";
import { PageHeader } from "react-bootstrap";
import { View } from "react-native";
import { iosDevice, jumpToInstructions } from "../../utils/Common";
import Safari from "../../images/safari.png";
import AppleShare from "../../images/apple-share.png";
import AddToHomeScreen from "../../images/add-to-home-screen.png";
import Chrome from "../../images/chrome.png";
import InstallApp from "../../images/install-app.png";
import Options from "../../images/options.png";

export default function InstallMobileApp() {
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        if(iosDevice()) {
            setIsIOS(true);
        }
    },[]);

    return (
        <View>
            <PageHeader>How to Install the Mobile App</PageHeader>
            <View>
                {jumpToInstructions(!isIOS,"android","app")}
                <h2 id="ios">
                    iPhone (or iPad)
                </h2>
                <h3>
                    <ol>
                        <li className="helpList">
                            Open our web app (link below) in your Safari Internet Browser on your phone<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={Safari} alt="Safari Logo" /><br /><br />
                            </div>
                            <div className="center">
                                <a href="https://data.bedrockwireless.com/">https://data.bedrockwireless.com</a>
                            </div>
                        </li>
                        <li className="helpList">
                            Click the "share" button<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={AppleShare} alt="Share Button" />
                            </div>
                        </li>
                        <li className="helpList">
                            Scroll down and select "Add to Home Screen"<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={AddToHomeScreen} alt="Add To Home Screen" />
                            </div>
                        </li>
                        <li className="helpList">
                            Our app is now installed!  Look for the Bedrock Logo in your Apps.
                        </li>
                    </ol>
                </h3>
                <h2 id="android">
                    Android 
                </h2>
                <h3>
                    <ol>
                        <li className="helpList">
                            Open our web app (link below) in your Google Chrome Browser on your phone<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={Chrome} alt="Chrome Logo" /><br /><br />
                            </div>
                            <div className="center">
                                <a href="https://data.bedrockwireless.com/">https://data.bedrockwireless.com</a>
                            </div>
                        </li>
                        <li className="helpList">
                            Click the "options" button<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={Options} alt="Options Button" />
                            </div>
                        </li>
                        <li className="helpList">
                            Scroll down and select "Install App" or click the install button when propmetd at the top of the page<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={InstallApp} alt="Install App" />
                            </div>
                        </li>
                    </ol>
                </h3>
            </View>
        </View>
    )
}