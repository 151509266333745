import React, { useState, useEffect } from "react";
import "./singupNewCap.css";
import { 
    FormGroup, 
    FormControl, 
    ControlLabel, 
    Glyphicon,
    Button, 
    Jumbotron, 
    Well,
    Collapse,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { View } from "react-native";
import { getCaps, createNeighborhood, activateCapByKey } from "../utils/bedrockAPI";
import { setNewCapSignup } from "../utils/Common";

export default function SignupNewCap(props) {
    const [capCode, setCapCode] = useState("");
    const [capName, setName] = useState("");
    const [neighborhoodSelection, setNeighborhoodSelection] = useState("");
    const [newNeighborhood, setNewNeighborhood] = useState("");
    const [neighborhoods, setNeighborhoods] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [caps, setCaps] = useState(null);

    const [errorCollapse, setErrorCollapse] = useState("");
    const [capCodeInfo, setCapCodeInfo] = useState(false);
    const [capNameInfo, setCapNameInfo] = useState(false);
    const [capNeighborhoodInfo, setCapNeighborhoodInfo] = useState(false);

    useEffect(() => {
        async function loadCaps() {
            const tempCaps = await getCaps();
            setCaps(tempCaps);
        }
        loadCaps();
    }, []);

    useEffect(() => {
        const compileNeighborhoods = (capList) => {
            var neighborhoods = []
            neighborhoods.push({null:"Do not assign a Neighborhood"});
            neighborhoods.push({"new":"Create New Neighborhood"});
            for(var i=0; i<capList.length; i++) {
                if(capList[i].neighborhoodid) {
                    neighborhoods.push({[capList[i].neighborhoodid]:capList[i].neighborhood});
                }
            }
            return neighborhoods;
        }
        if(caps) {
            setNeighborhoods(compileNeighborhoods(caps));
        }
    },[caps]);
    

    function validateForm() {
        return (
            capCode.length == 8 && 
            capName.length > 0 && 
            (neighborhoodSelection==="Create New Neighborhood" ? newNeighborhood && newNeighborhood.length > 0 : true)
        );
    }

    function lookUpNeighborhoodId(neighborhoodName) {
        for(var i=0; i<neighborhoods.length; i++) {
            if(Object.values(neighborhoods[i])[0]===neighborhoodName) {
                return Object.keys(neighborhoods[i])[0];
            }
        }
    }

    async function handleSignup(event) {
        event.preventDefault();

        setIsLoading(true);
        let neighborhoodId;
        if(neighborhoodSelection==="Create New Neighborhood") {
            neighborhoodId = (await createNeighborhood(newNeighborhood)).neighborhoodId;
            console.log(neighborhoodId);
        } else if (neighborhoodSelection==="Do not assign a Neighborhood") {
            neighborhoodId = null;
        } else {
            neighborhoodId = lookUpNeighborhoodId(neighborhoodSelection);
        }

        const capActivation = {
            name: capName,
            neighborhood: neighborhoodId,
            key: capCode,
        }

        try {
            await activateCapByKey(capActivation);
            setNewCapSignup(false);
            props.history.push('/dashboard');
        } catch(error) {
            if(error.response.status === 422) {
                setErrorCollapse("Please recheck your activation code")
            } else if(error.response.status===401) {
                setErrorCollapse("Please contact the Owner of this unit for access")
            }
        }

        setIsLoading(false);
    }

    // const capNameTooltip = (
    //     <Tooltip id="capNameTooltip" >
    //         Typically you would use the street address of your site (Ex: 123 Main St)
    //     </Tooltip>
    // )
    
    function renderForm() {
        return (
            <View>
                <Jumbotron style={{textAlign:"center",paddingBottom:"25px"}}>
                    <h1>Welcome to Bedrock Wireless</h1>
                    
                    <p>
                        <br />You have received a Construction Access Point (CAP).<br /><br />

                        Make sure your CAP is plugged in and turned on.<br /><br />

                        To connect your CAP, first locate the 8 character code on the inside lid of your CAP (4cam) or the back of the unit (2cam) and enter it below.<br /><br />

                        Next, provide the name you want to use for your CAP, typically a street address is used (Ex: 123 Main St).<br /><br />

                        Finally, you may elect to provide a neighborhood for your CAP, either existing or new.
                    </p>
                </Jumbotron>
                <form onSubmit={handleSignup}>
                    <FormGroup controlId="capCode" bsSize="large">
                        <View style={{flex:1, flexDirection:"row", justifyContent:"space-between",paddingBottom:"5px"}}>
                            <ControlLabel style={{textAlign:"center"}}>
                                Activation Code
                            </ControlLabel>
                            <Button
                                bsStyle="default"
                                bsSize="small"
                                onClick={() => setCapCodeInfo(!capCodeInfo)}
                            >
                                <Glyphicon glyph="info-sign" />
                            </Button>
                        </View>
                        <Collapse in={capCodeInfo}>
                            <Well>
                                Lower or upper case does not matter. <br /><br />Please make sure you have 8 characters.
                            </Well>
                        </Collapse>
                        <FormControl
                            autoFocus   
                            type="text"
                            value={capCode}
                            onChange={e => setCapCode(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlId="capName" bsSize="large">
                        <View style={{flex:1, flexDirection:"row", justifyContent:"space-between",paddingBottom:"5px"}}>
                            <ControlLabel>Cap Name</ControlLabel>
                            <Button
                                bsStyle="default"
                                bsSize="small"
                                onClick={() => setCapNameInfo(!capNameInfo)}
                            >
                                <Glyphicon glyph="info-sign" />
                            </Button>
                        </View>
                        <Collapse in={capNameInfo}>
                            <Well>
                                A name can be anything you want.<br/><br />
                                Typically the address of the jobsite is used.<br /><br />
                                Something like: 123 main St<br/><br />
                                You can edit this later if you want.
                            </Well>
                        </Collapse>
                        <FormControl
                            type="text"
                            value={capName}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlId="neighborhood" bsSize="large">
                        <View style={{flex:1, flexDirection:"row", justifyContent:"space-between",paddingBottom:"5px"}}>
                            <ControlLabel>Neighborhood</ControlLabel>
                            <Button
                                bsStyle="default"
                                bsSize="small"
                                onClick={() => setCapNeighborhoodInfo(!capNeighborhoodInfo)}
                            >
                                <Glyphicon glyph="info-sign" />
                            </Button>
                        </View>
                        <Collapse in={capNeighborhoodInfo}>
                            <Well>
                                Neighborhoods are used to organize your caps.<br /><br />
                                They're not necessary, but may help you quickly refer to a specific site.<br /><br />
                                You can use the name of the neighborhood, the name of the job, the name of the customer, or anything else you want.
                            </Well>
                        </Collapse>
                        <FormControl
                            componentClass="select"
                            value={neighborhoodSelection}
                            onChange={e => setNeighborhoodSelection(e.target.value)}
                        >
                            {neighborhoods && neighborhoods.map((nh,i) => (
                                <option key={i}>
                                    {nh[Object.keys(nh)[0]]}
                                </option>
                            ))}
                        </FormControl>
                    </FormGroup>
                    {neighborhoodSelection==="Create New Neighborhood" && (
                        <FormGroup controlId="newNeighborhood" bsSize="large">
                            <View style={{flex:1, flexDirection:"row", justifyContent:"space-between",paddingBottom:"5px"}}>
                                <ControlLabel>Provide a name for your neighborhood</ControlLabel>
                                <Button
                                    bsStyle="default"
                                    bsSize="small"
                                    onClick={() => setNewNeighborhoodInfo(!newNeighborhoodInfo)}
                                >
                                    <Glyphicon glyph="info-sign" />
                                </Button>
                            </View>
                            <FormControl
                                type="text"
                                value={newNeighborhood}
                                onChange={e => setNewNeighborhood(e.target.value)}
                            />
                        </FormGroup>
                    )}
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Launch CAP
                    </LoaderButton>
                    <Collapse in={errorCollapse.length>0}>
                        <Well style={{backgroundColor:"#FF7272", fontWeight:"bold",color:"white"}}>
                            {errorCollapse}
                        </Well>
                    </Collapse>
                    {//TODO: Put Collapsable that appears on error???
                    }
                </form>
            </View>
        )
    }

    return (
        <div className="SignupNewCap">
            {renderForm()}
        </div>
    )
}