import React, {useState, useEffect} from "react";
import "./Events.css";
// import { ListGroupItem, ListGroup, Glyphicon, Button } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
// import { View } from "react-native";
// import { getToken } from "../utils/Common";
// import axios from "axios";

export default function NewEvent(props) {
    const [coords, setCoords] = useState([]);

    useEffect(() => {
        setCoords([0,0,0])
    },[])

    return (
        <div className="NewEvent">
            {coords}
        </div>
    )
}