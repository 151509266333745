import React, { useState, useEffect, useRef } from "react";
import { 
    PageHeader,
    Table,
    FormGroup,
    Checkbox,
    Glyphicon,
    Button
} from "react-bootstrap";
import {
    patchUser,
    getUserMotions,
    getCaps,
    // getSharedCaps
} from "../utils/bedrockAPI";
import {getUser} from "../utils/Common";
import { View } from "react-native";
import TopLeft from "../images/top-left.png";
import TopRight from "../images/top-right.png";
import BottomLeft from "../images/bottom-left.png";
import BottomRight from "../images/bottom-right.png";
import TopLeft2 from '../images/top-left-2.png';
import TopRight2 from '../images/top-right-2.png';
// import MasterNotifications from "./MasterNotifications";
import NewUserMotionModal from "../components/NewUserMotionModal";

export default function MotionNotifications(props) {
    const [caps, setCaps] = useState(null);
    const [showNewUserMotionModal, setShowNewUserMotionModal] = useState(false);
    const [userMotions, setUserMotions] = useState(null);
    const [buttonSize, setButtonSize] = useState("large");
    const [userMotionToUpdate, setUserMotionToUpdate] = useState(null);
    const componentMounted = useRef(true);
    // const [capShare,setCapShare] = useState(null);

    const cameraViews = [TopLeft,TopRight,BottomLeft,BottomRight];
    const cameraViews2 = [TopLeft2,TopRight2];

    async function acquireUserMotions(userId) {
        function convertTime(time) {
            let newTime = "";
            let timeHours = parseInt(time.substring(0,2));
            let timeMinutes = time.substring(3,5);
            if(timeHours > 12) {
                newTime = (timeHours-12).toString()+":"+timeMinutes+"PM";
            } else if (timeHours === 12) {
                newTime = timeHours.toString()+":"+timeMinutes+"PM";
            } else {
                newTime = timeHours.toString()+":"+timeMinutes+"AM";
            }
            return newTime;
        }
        const tempUserMotions = await getUserMotions(userId);
        let newUserMotions = [];
        const orderedDays = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
        for(var i=0; i<tempUserMotions.length; i++) {
            if(tempUserMotions[i].userMotion.status!=='DELETE') {
                let newUserMotion = {capId:tempUserMotions[i].capId,capName:tempUserMotions[i].capName,monitorIndex:tempUserMotions[i].monitorIndex};
                newUserMotion.capType = tempUserMotions[i].capType;
                newUserMotion.userMotion = {};
                newUserMotion.userMotion.userMotionId = tempUserMotions[i].userMotion.userMotionId;
                newUserMotion.userMotion.name = tempUserMotions[i].userMotion.name;
                newUserMotion.userMotion.monitorId = tempUserMotions[i].userMotion.monitorId;
                newUserMotion.userMotion.userId = tempUserMotions[i].userMotion.userId;
                newUserMotion.userMotion.status = tempUserMotions[i].userMotion.status;
                newUserMotion.userMotion.startTime = convertTime(tempUserMotions[i].userMotion.startTime);
                newUserMotion.userMotion.endTime = convertTime(tempUserMotions[i].userMotion.endTime);
                newUserMotion.userMotion.classes = tempUserMotions[i].userMotion.classes.join(" & ").replace("Vehicle","🚗").replace("Person","🙋");
                newUserMotion.userMotion.days = tempUserMotions[i].userMotion.days.sort((a,b) => orderedDays.indexOf(a)-orderedDays.indexOf(b)).join(",").replace("Mon","M").replace("Tue","Tu").replace("Wed","W").replace("Thu","Th").replace("Fri","F").replace("Sat","Sa").replace("Sun","Su");
                // tempUserMotions[i].userMotion.methods = tempUserMotions[i].userMotion.methods.join(" & ").replace("email","📧").replace("app","📱");
                if(tempUserMotions[i].userMotion.frequency==="ALWAYS") {
                    newUserMotion.userMotion.frequency = "All Events"
                } else {
                    newUserMotion.userMotion.frequency = "Once per Day"
                }
                newUserMotion.userMotion.methods = tempUserMotions[i].userMotion.methods;
                newUserMotions.push(newUserMotion);
            }
        }
        if(componentMounted.current)
            setUserMotions(newUserMotions);
    }

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }

        async function getCapShare(userId) {
            // const tempShare = await getSharedCaps(userId);
            // setCapShare(tempShare);
            const tempCaps = await getCaps();
            if(componentMounted.current)
                setCaps(tempCaps);
        }
        
        const user = getUser();
        if(user) {
            acquireUserMotions(user.userId);
            getCapShare(user.userId);
        }
        return () => { componentMounted.current = false; };
    },[]);

    useEffect(() => {
        const user = getUser();
        if(user && showNewUserMotionModal===false) {
            acquireUserMotions(user.userId);
        };
    },[showNewUserMotionModal]);

    async function updateUserMotion(um) {
        setUserMotionToUpdate(um);
        setShowNewUserMotionModal(true);
    }

    async function createNew(e) {
        e.preventDefault();
        setUserMotionToUpdate(null);
        setShowNewUserMotionModal(true);
    }

    return (
        <View>
            <PageHeader>
                <View style={{flex:1, flexDirection:"row",justifyContent:"space-between"}}>
                    <div style={{flex:1, flexDirection:"row",justifyContent:"center",marginRight:buttonSize==="small"?"0px":"-52px",fontSize:buttonSize==="small"?"24px":"36px"}}>
                        Motion Notifications
                    </div>
                    {buttonSize==="large" && (
                        <Button
                            bsStyle="success"
                            bsSize={buttonSize}
                            onClick={(e) => createNew(e)}
                        >
                            <Glyphicon glyph="plus" />
                        </Button>
                    )}
                </View>
            </PageHeader>
            <View>
                <Table striped bordered hover responsive>
                    <thead>
                        {userMotions && userMotions.length > 0 && (<tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Camera
                            </th>
                            <th>
                                View
                            </th>
                            <th>
                                Start 
                            </th>
                            <th>
                                End 
                            </th>
                            <th>
                                Detections
                            </th>
                            <th>
                                Days
                            </th>
                            <th>
                                Frequency
                            </th>
                            {/* <th>
                                Method
                            </th> */}
                        </tr>
                        )}
                    </thead>
                    <tbody style={{height:"auto"}}>
                        {userMotions && userMotions.map((um,i) => (
                            <tr key={i} onClick={() => updateUserMotion(um)}>
                                <td>{um.userMotion.name}</td>
                                <td>{um.capName}</td>
                                <td><img style={{maxWidth:"48px"}} src={um.capType==="2CAM" ? cameraViews2[um.monitorIndex]: cameraViews[um.monitorIndex]} alt={um.monitorIndex} /></td>
                                <td>{um.userMotion.startTime}</td>
                                <td>{um.userMotion.endTime}</td>
                                <td>{um.userMotion.classes}</td>
                                <td>{um.userMotion.days}</td>
                                <td>{um.userMotion.frequency}</td>
                                {/* <td>
                                    {um.userMotion.methods.includes("app") && um.userMotion.methods.includes("email") && (
                                        <div>
                                            <Glyphicon glyph="phone" />
                                            &nbsp;&&nbsp;
                                            <Glyphicon glyph="envelope" />
                                        </div>
                                    ) || um.userMotion.methods.includes("app") && (
                                        <Glyphicon glyph="phone" />
                                    ) || um.userMotion.methods.includes("email") && (
                                        <Glyphicon glyph="envelope" />
                                    )}
                                </td> */}
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={9}>
                                <View style={{flex:1,flexDirection:"row",justifyContent:buttonSize==="small"?"flex-start":"center"}}>
                                    <Button
                                        bsStyle="success"
                                        bsSize={buttonSize}
                                        onClick={(e) => createNew(e)}
                                        block={buttonSize==="large"}
                                    >
                                        {
                                            userMotions && userMotions.length > 0 ? 
                                                buttonSize==="small" ? "Create New Notification Config" :
                                                    "Create New Custom Motion Event Notification" :
                                                buttonSize==="small" ? "Create Your First Notification Config" :
                                                    "Create Your First Notification Configuration"
                                                
                                        }
                                    </Button>
                                </View>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </View>
            <NewUserMotionModal 
                show={showNewUserMotionModal}
                setShow={setShowNewUserMotionModal}
                caps={caps}
                userMotion={userMotionToUpdate}
                props={props}
            />
        </View>
    )
}