import React, { useState, useCallback, useEffect } from "react";
import { getUser, renderAccessDenied } from "../utils/Common";
import { getLabelClasses, getRandomImage, getImageLabels, getSpecificImage, createImageLabel, patchImage, patchImageLabel, getUserImage } from "../utils/bedrockAPI";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Crosshair from "../components/Crosshair";
// import ImageContainer from "./ImageContainer";
import LabelImage from "../components/LabelImage";
// import InfoPanel from "../components/InfoPanel";
// import SubmitButton from "../components/SubmitButton";
import BoundingBoxes from "../components/BoundingBoxes";
import { calculateRectPosition, isRectangleTooSmall, convertImageLabelsToBoxes, convertBoxesToImageLabels } from "../utils/drawing";
import "./labeler.css"

//TODO:
// 4) submit button behavior
// 5) fix clear labels (make them acutally delete in the db)

export default function Labeler(props) {

    const [clickTiggered, setClickTriggered] = useState(false);
    const [currX, setCurrX] = useState(null);
    const [currY, setCurrY] = useState(null);
    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);
    const [showCrosshair, setShowCrosshair] = useState(true);
    const [imageProps, setImageProps] = useState({
            height:null,
            width:null,
            offsetX:null,
            offsetY:null
        });
    const [boxesToRender, setBoxesToRender] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [wasDrawing, setWasDrawing] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    // const [showSidePanel, setShowSidePanel] = useState(true);
    const [cursorValid, setCursorValid] = useState(false);
    const [currentBoxId, setCurrentBoxId] = useState(0);
    const [currentLabel, setCurrentLabel] = useState(null);
    const [infrared, setInfrared] = useState(null);
    const [labelClasses, setLabelClasses] = useState(null);
    const [returnLabeled, setReturnLabeled] = useState(null);
    const [labelsToDelete, setLabelsToDelete] = useState(null);
    const [showTextLabels, setShowTextLabels] = useState(false);
    const [idTracks, setIdTracks] = useState(null);
    const [imageId, setImageId] = useState("");

    // Should probably be a db list?  -- DONE
    // const labels = ['Person','Car','Truck','Bus','Van','Motorbike','Bicycle']
    // const labelColors = ['Red','Blue','Yellow','LimeGreen','Aquamarine','Purple','Teal','IndianRed','DarkOrange']
    // const textColors = ['White','White','Black','White','Black','White','White','White','White']

    useEffect(() => {
        fetchLabelClasses();
    },[]);

    useEffect(() => {
        if(labelClasses) {
            if(imageUrl==null) {
                fetchNewImage();
                // console.log("fetching first image")
            }
        }
    },[labelClasses])

    // useEffect(() => {
    //     if(boxesToRender)
    //         console.log(boxesToRender);
    // },[boxesToRender])

    useEffect(() => {
        if(imageUrl && labelClasses && imageProps && imageProps.height) {
            if(boxesToRender==null) {
                fetchImageLabels();
                // console.log("fetched new labels");
            }
        }
    },[imageUrl,imageProps])

    useEffect(() => {
        if(imageUrl && boxesToRender) {
            fetchImageLabels();
            // console.log("fetched updated labels");
            if(imageUrl.infrared!=null) {
                setInfrared(imageUrl.infrared);
            } else {
                setInfrared(null);
            }
        }
        if(imageUrl) {
            console.log(imageUrl.imageId);
            var idTracker = idTracks ? idTracks : [];
            if(idTracks && idTracks.length>9) {
                idTracker.shift();
            }
            idTracker.push(imageUrl.imageId);
            setIdTracks(idTracker);
        }
    },[imageUrl])

    useEffect(() => {
        if(imageProps.height!=null && !imagePropsAreCorrect()) {
            console.log(imageUrl);
            fetchNewImage();
        }
    },[imageProps])

    async function fetchImageLabels() {
        try {
            const tempImageLabels = await getImageLabels(imageUrl.imageId);
            setBoxesToRender(convertImageLabelsToBoxes(tempImageLabels,labelClasses,imageProps));
            setCurrentBoxId(tempImageLabels.length);
        } catch (f) {
            console.log(f);
        }
    }

    async function fetchLabelClasses() {
        try {
            const dbClasses = await getLabelClasses();
            setLabelClasses(dbClasses);
            setCurrentLabel(dbClasses[0]);
        } catch(f) {
            console.log(f);
        }
    }

    async function fetchNewImage() {
        if(props.match.params.userid) {
            await fetchUserImage(props.match.params.userid);
        }
        else if(props.match.params.imageid) {
            if(props.match.params.imageid==="true") {
                await fetchRandomImage(true);
            } else {
                await fetchSpecificImage(props.match.params.imageid);
            }
        } else {
            await fetchRandomImage();
        }
        setInfrared(null);
        // fetchImageLabels();
    }

    async function fetchRandomImage(labelTypes=null) {
        try {
            if(labelTypes!=null) {
                setReturnLabeled(labelTypes);
            }
            const randomImage = await getRandomImage(labelTypes);
            // console.log(randomImage);
            setImageUrl(randomImage);
        } catch(f) {
            console.log(f);
        }
    }

    async function fetchUserImage(userId) {
        try {
            const userImage = await getUserImage(userId);
            setImageUrl(userImage);
        } catch (f) {
            console.log(f);
        }
    }

    async function fetchSpecificImage(imageId) {
        try {
            const specificImage = await getSpecificImage(imageId);
            setImageUrl(specificImage);
        } catch(f) {
            console.log(f);
        }
    }

    function imagePropsAreCorrect() {
        // console.log(imageProps);
        // if((imageProps.height==480 && (imageProps.width===704 || imageProps.width===720)) || 
        //     ((imageProps.width===704 || imageProps.width===720) && imageProps.height > 490 
        //     && imageProps.height < 492) || (
        //         (imageProps.width===640 && imageProps.height===360) || (imageProps.width===720 && imageProps.height===405)
        //     ) || ( imageProps.width===720 && (imageProps.height>420 && imageProps.height < 424))) {
        //     return true;
        // } else {
        //     console.log(imageProps);
        //     return false;
        // }
        return true;
    }

    function addBox(id,box) {
        var newBoxes = boxesToRender;
        var newBox = {position:box};
        newBox.id=id;
        newBox.imageLabelId=null;
        newBox.label=currentLabel;
        newBoxes.push(newBox)
        setBoxesToRender(newBoxes);
    }
    
    function modifyCurrentBox(box) {
        boxesToRender[boxesToRender.length-1].position = box;
        setBoxesToRender(boxesToRender);
    }

    function handleDelete(b) {
        for(var i=0; i<boxesToRender.length; i++) {
            if(boxesToRender[i].id==b) {
                if(boxesToRender[i].imageLabelId) {
                    var newDeletes = labelsToDelete ? labelsToDelete : [];
                    newDeletes.push(boxesToRender[i].imageLabelId);
                    setLabelsToDelete(newDeletes);
                }
                boxesToRender.splice(i,1);
                break;
            }
        }
    }

    function changeLabel(b) {
        for(var i=0; i<boxesToRender.length; i++) {
            if(boxesToRender[i].id==b) {
                const indexOfNewLabel = (labelClasses.indexOf(boxesToRender[i].label)+1)%labelClasses.length
                // console.log(indexOfNewLabel,labels[indexOfNewLabel],labelColors[indexOfNewLabel])
                boxesToRender[i].label = labelClasses[indexOfNewLabel];
                break;
            }
        }
    }

    async function changeImageProps(ip) {
        setImageProps(ip);
    }

    const onClick = () => {
        // console.log(cursorValid);
        // console.log(currX,currY);
        // console.log(imageProps);
        // console.log(isDrawing);
        if(cursorValid) {
            // createRectangle();
            setIsDrawing(!isDrawing);
        }
    }

    function getCurrentBox() {
        return {
            startX: startX,
            startY: startY,
            currX: currX,
            currY: currY
        };
    }

    function getDefaultSmallBox() {
        return {
            startX: startX,
            startY: startY,
            currX: startX+10,
            currY: startY+10
        }
    }

    function createRectangle() {
        if(isDrawing && !wasDrawing) {
            setStartX(currX);
            setStartY(currY);
            const boxPosition = calculateRectPosition(
                imageProps,
                getCurrentBox()
            )
            // if(!isRectangleTooSmall(boxPosition)) {
            addBox(currentBoxId,boxPosition);
            setCurrentBoxId(currentBoxId+1);
            setWasDrawing(true);
            // }
        } else if(isDrawing && wasDrawing) {
            const boxPosition = calculateRectPosition(
                imageProps,
                getCurrentBox()
            )
            if(!isRectangleTooSmall(boxPosition)) {
                modifyCurrentBox(boxPosition);
            } else { 
                // If the box is too small, you still want to make it a something 
                // that makes sens based on the cursor positoin
                modifyCurrentBox(calculateRectPosition(imageProps,getDefaultSmallBox()))
            }
        } else {
            setWasDrawing(false);
        }
    }

    const handleMouseUp = event => {
    if (event.button !== 2) {
        setClickTriggered(true);
        setTimeout(() => setClickTriggered(false), 10);
        onClick();
    }
    };

    const handleClick = useCallback(() => {
    if (!clickTiggered) {
        onClick();
    }
    }, [clickTiggered]);

    function updateCursorPosition(event) {
        setCurrX(event.pageX);
        setCurrY(event.pageY);
        if( event.target.className !== "line" &&
            event.target.id !== "LabelViewImg" &&
            event.target.className !== "BoundingBox" &&
            event.target.id !== "Crosshair" ) {
            setCursorValid(false);
        } else {
            setCursorValid(true);
            createRectangle();
        }
    }

    function isCrossHairReady() {
        return currX && currY && imageProps.height && imageProps.width;
    }

    const mouseMoveHandler = useCallback((event) => {
        event.persist();
        updateCursorPosition(event);
    });

    async function handleImageDelete() {
        if(window.confirm("Are you sure you want to mark this image for deletion?")) {
            console.log('DELETED:',imageUrl.imageId);
            patchImage(imageUrl.imageId,{'status':'DELETE'});
            fetchNewImage();
        }
    }

    async function handleSubmit() {
        const userId = getUser().userId;
        var submitLabels = convertBoxesToImageLabels(boxesToRender,imageProps,userId,imageUrl.imageId);
        // console.log(submitLabels);
        for(var i=0; i<submitLabels.length;i++) {
            if(!submitLabels[i].imageLabelId) {
                createImageLabel(submitLabels[i]);
            }
        }
        var newImage = {'labeled':true}
        if(infrared!=null) {
            newImage.infrared = infrared;
        }
        patchImage(imageUrl.imageId,newImage);
        if(labelsToDelete) {
            for(var i=0; i<labelsToDelete.length; i++) {
                patchImageLabel(labelsToDelete[i],{'status':'DELETE'});
            }
            setLabelsToDelete(null);
        }
        fetchNewImage();
    }

    const handleImageIdChange = (e) => {
        e.preventDefault();
        setImageId(e.target.value);
    }

    function renderLabeler() {
        return (
            <div
                id="LabelViewContainer"
                onMouseUp={handleMouseUp}
                onClick={handleClick}
                onMouseMove={mouseMoveHandler}
            >
                <div>
                    <form onSubmit={e => props.history.push(`/labels/${imageId}`)}>
                        <FormGroup style={{flex:1, flexDirection:"row"}}>
                            <ControlLabel>image_id</ControlLabel>
                            <FormControl 
                                type="text"
                                autoFocus
                                style={{paddingRight:"5px", width:"350px"}}
                                value={imageId}
                                onChange={handleImageIdChange}
                            />
                            <div style={{paddingTop:"5px"}}>
                                <Button 
                                    type="submit"
                                >
                                    Fetch Image
                                </Button>
                            </div>
                            {/* <div style={{paddingLeft:"5px"}}>
                                <Button
                                    disabled
                                >
                                    {imageUrl ? imageUrl.labeled : "loading"}
                                </Button>
                            </div> */}
                        </FormGroup>
                    </form>
                </div>
                <div id="Middle">
                    <div id="LabelView">
                        {showCrosshair && isCrossHairReady() && 
                        <Crosshair
                            x={currX}
                            y={currY}
                            imageProps={imageProps}
                        />
                        }
                        {boxesToRender && boxesToRender.length > 0 && (
                            <BoundingBoxes
                                className="BoundingBoxes unselectable"
                                boxes={boxesToRender}
                                isDrawing={isDrawing}
                                showTextLabels={showTextLabels}
                                handleDelete={handleDelete}
                                changeLabel={changeLabel}
                            />
                        )}
                        {imageUrl && imageProps && (
                            <LabelImage imageURL={imageUrl.url} setImageProps={changeImageProps}/>
                        )}
                    </div> 
                    <div id="SidePanel">
                        {/* <InfoPanel /> */}
                        <div id="labelButtonPanel">
                            {labelClasses && currentLabel && labelClasses.map(function(label,i) {
                                return (
                                    <Button
                                        id="labelButton"
                                        key={i}
                                        bsStyle={currentLabel.labelClassId===label.labelClassId ? "info" : "default"}
                                        style={currentLabel.labelClassId===label.labelClassId ? {backgroundColor:label.labelColor,color:label.textColor} : {}}
                                        bsSize="small"
                                        onClick={() => {setCurrentLabel(label)}}
                                    >{label.name}</Button>
                                )
                            })}
                        </div>
                        <div id="InfraredButtonPanel">
                            <table>
                                <tbody>
                                    {/* <tr>
                                        <td>Types of Images to Fetch</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Button
                                                bsStyle={returnLabeled?"success":"default"}
                                                bsSize="small"
                                                onClick={() => {returnLabeled===true ? setReturnLabeled(null) : setReturnLabeled(true)}}
                                            >Labeled</Button>
                                            <Button
                                                bsStyle={returnLabeled==null?"default":returnLabeled?"default":"success"}
                                                bsSize="small"
                                                onClick={() => {returnLabeled===false ? setReturnLabeled(null) : setReturnLabeled(false)}}
                                            >Unlabeled</Button>
                                            <Button
                                                bsStyle={returnLabeled==null?"success":"default"}
                                                bsSize="small"
                                                onClick={() => {if(returnLabeled!==null) setReturnLabeled(null)}}
                                            >All</Button>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>Infrared</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Button
                                                bsStyle={infrared?"success":"default"}
                                                bsSize="small"
                                                onClick={() => {infrared===true ? setInfrared(null) : setInfrared(true)}}
                                            >Yes</Button>
                                            <Button
                                                bsStyle={infrared==null?"default":infrared?"default":"success"}
                                                bsSize="small"
                                                onClick={() => {infrared===false ? setInfrared(null) : setInfrared(false)}}
                                            >No</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Button 
                            id="clearButton"
                            bsStyle="warning"
                            bsSize="small"
                            onClick={() => {setBoxesToRender([])}} // TODO: fix this so that it actually deletes them
                        >Clear Labels</Button>
                        <Button
                            id="clearButton"
                            bsStyle={showTextLabels ? "success" : "default"}
                            bsSize="small"
                            onClick={() => {setShowTextLabels(!showTextLabels)}}
                        >Show Label Text</Button>
                        <Button
                            id="clearButton"
                            bsStyle="info"
                            bsSize="small"
                            onClick={() => {
                                fetchRandomImage();
                            }}
                        >New Image</Button>
                        <Button
                            id="clearButton"
                            bsStyle="info"
                            bsSize="small"
                            disabled={!props.match.params.imageid && (idTracks==null || idTracks.length<2)}
                            onClick={() => {idTracks && idTracks.length<2 ? props.history.push(`/labels/`) : props.history.push(`/labels/${idTracks[idTracks.length-2]}`)}}
                        >{idTracks && idTracks.length<2 ? "Get random image" : "Previous Image"}</Button>
                        {/* <SubmitButton /> */}
                        <Button
                            id="deleteButton"
                            bsStyle="danger"
                            bsSize="large"
                            onClick={handleImageDelete}
                        >MARK IMAGE DELETE</Button>
                        <Button
                            id="submitButton"
                            bsStyle="success"
                            bsSize="large"
                            onClick={handleSubmit}
                        >Submit</Button>
                    </div>
                    <div style={{clear:"both"}} />
                </div>
                <div id="Instructions">
                    <ul>
                        <li>If the image is all black or the image is not from a fully installed system (i.e. in a workshop) - then mark the image DELETE </li>
                        <li>If the image is valid but has nothing to label, click submit to mark the image as a negative example</li>
                        <li>Label all items in the image that are over 12x12px (bigger than the cross hair) before clicking submit</li>
                        <li>Make sure labels are tight to the objects</li>
                        <li>Mark whether the image is in infrared - If you don't know, make sure both yes and no are white before submitting</li>
                        <li>You can change a label class by clicking on the label text to cycle through the label options</li>
                        <li>You can delete a single label box by hovering over it and clicking the x in the top right</li>
                        <li>You can clear all labels by clicking the clear labels button to the right</li>
                        <li>If you get an image that has been previously labeled, just check the labels for accuracy and click submit</li>
                        <li>If you don't know what to do with the image, just refresh the page to get a new image (nothing will be submitted)</li>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="Labeler">
            {(getUser().role==="ADMIN" || getUser().role==="LABELER") ? renderLabeler() : renderAccessDenied()}
        </div>
    )
}