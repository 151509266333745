import React, { useEffect, useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { setUserSession, removeUserSession, getUser } from "./utils/Common";
import { getToken } from "./utils/Common"
import axios from "axios";
import Image from "./images/Logo6.png";
// import Notification from "./components/Notification"
import { updateUserPWA } from "./utils/bedrockAPI";

function App(props) {
  // const [isAuthenticated, userHasAuthenticated] = useState(false);
  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("error-message");
  const [errorMessage, setErrorMessage] = useState("");
  const [pwaInstalled, setPWAInstalled] = useState(false);
  const [prompt, setPrompt] = useState();
  const [promptAvailable, setPromptAvailable] = useState(false);
  const [buttonSize, setButtonSize] = useState("large");
  const [pageViewsSinceNudge, setPageViewsSinceNudge] = useState(0);
  const history = useLocation();

  useEffect(() => {
    let isMounted = true;
    if (window.matchMedia('(display-mode: standalone)').matches) {
      if(isMounted) {
        setPWAInstalled(true);
      }
    }

    window.addEventListener('beforeInstallPrompt', e => {
      e.preventDefault();
      // window.alert('prompted');
      if(isMounted) {
        setPrompt(e);
        setPromptAvailable(true);
      }
    });

    if(window.innerWidth<767 && isMounted) {
      setButtonSize("small");
    } 
    return () => { isMounted = false; };
  },[]);

  useEffect(() => {
    let isMounted = true;
    if(isMounted) {
      setPageViewsSinceNudge(pageViewsSinceNudge+1);
    }
    if(pageViewsSinceNudge > 4) {
      if(isMounted) {
        setShowError(false);
        setErrorMessage("");
      }
    }
    return () => { isMounted = false; };
  },[history]);

  useEffect(() => {
    let isMounted = true;
    if(showError && isMounted) {
      setPageViewsSinceNudge(0);
    }
    return () => { isMounted = false; };
  },[showError]);

  useEffect(() => {
    let isMounted = true;
    if(promptAvailable && isMounted) {
      // if(getUser() && getUser().role==='ADMIN') { // ADMIN CONTROL - Prompting PWM
      prompt.prompt(); // 
      // }
    }
    return () => { isMounted = false; };
  },[promptAvailable])

  // This may have an issue of getting called before the user is stored.  Next page view should fix it?
  useEffect(() => {
    let isMounted = true;
    if(pwaInstalled && getUser() && isMounted) {
      updateUserPWA(getUser().userId,true);
    }
    return () => { isMounted = false; };
  },[pwaInstalled]);

  const token = getToken();
  if(token) {
    const config = {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    axios.get(
      `${process.env.REACT_APP_API_URL}/users/me`,
      config
    ).then(response => {
      setUserSession(token, response.data);
      // setAuthLoading(false);
    }).catch(error => { // Expired or fake token
      removeUserSession();
      // console.log(error);
      props.history.push(`/login`)
    });
  }

  function handleLogout() {
    removeUserSession();
    props.history.push('/login');
  }

  const withErrorHandling = WrappedComponent => ({ showError, children }) => {
    // const errorContent = useMemo(() => errorMessage,[]);
    return (
      <WrappedComponent>
        {showError && <div className={errorStatus}>{errorMessage}</div>}
        {children}
      </WrappedComponent>
    );
  };

  const DivWithErrorHandling = withErrorHandling(({children}) => <div>{children}</div>);

  return (
    <DivWithErrorHandling showError={showError}>
      <div className="App container">
        <Navbar fluid collapseOnSelect> {/*style={{backgroundColor:"#297ACC"}*/}
          <Navbar.Header>
            <Navbar.Brand style={{padding:"0px"}}>
              {getToken()!=null
              ? <Link to="/dashboard"><img src={Image} alt="Bedrock Wireless" /></Link>
              : <Link to="/"><img src={Image} alt="Bedrock Wireless" /></Link>
              }
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {getToken()!=null 
              ? <React.Fragment>
                  <LinkContainer to="/dashboard">
                    <NavItem>Dashboard</NavItem>
                  </LinkContainer>
                  {getUser().role==="ADMIN" && (
                    <LinkContainer to="/admin">
                      <NavItem>Admin</NavItem>
                    </LinkContainer>
                  )}
                  <LinkContainer to="/help">
                    <NavItem>Help</NavItem>
                  </LinkContainer>
                  {getUser().role!=="PAYMENTS" && (
                    <NavDropdown title="My Account" id="collapsible-nav-dropdown">
                      <LinkContainer to="/account/info">
                        <NavItem>Account Info</NavItem>
                      </LinkContainer>
                      {/* <NavDropdown title="Notifications"> */}
                        {/* <LinkContainer to="/account/notifications/master">
                          <NavItem>Master Control</NavItem>
                        </LinkContainer> */}
                        <LinkContainer to="/account/notifications">
                          <NavItem>Notifications</NavItem>
                        </LinkContainer>
                        {/* <LinkContainer to="/account/notifications/motion">
                          <NavItem>Motion Notifications</NavItem>
                        </LinkContainer> */}
                      {/* </NavDropdown> */}
                      <LinkContainer to="/account/password">
                        <NavItem>Change Password</NavItem>
                      </LinkContainer>
                      <LinkContainer to="/account/integrations">
                        <NavItem>Integrations</NavItem>
                      </LinkContainer>
                      {(getUser().role==="ADMIN" || getUser().role==="LABELER") && (
                        <LinkContainer to="/labels">
                          <NavItem>Labels</NavItem>
                        </LinkContainer>
                      )}
                      <NavItem onClick={handleLogout}>
                        Logout
                      </NavItem>
                      {buttonSize==="small" && (<MenuItem divider />)}
                    </NavDropdown>
                  )}
                </React.Fragment>
              : <React.Fragment>
                <LinkContainer to="/signup">
                  <NavItem>Sign Up</NavItem>
                </LinkContainer>
                <LinkContainer to="/login">
                  <NavItem>Login</NavItem>
                </LinkContainer>
                </React.Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes appProps={{ 
          showError, 
          setShowError, 
          errorMessage, 
          setErrorMessage, 
          errorStatus, 
          setErrorStatus
        }} />
      </div>
    </DivWithErrorHandling>
  );
}

export default withRouter(App);