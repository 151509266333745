import React, {useState, useEffect} from "react";
import { 
    PageHeader,
    Table,
    FormGroup,
    Checkbox,
    Glyphicon,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    patchUser,
    getSharedCaps,
    getCaps,
    patchSharing
} from "../utils/bedrockAPI";
import {getUser, mobileCheck} from "../utils/Common";
import { View } from "react-native";

export default function PowerNotifications({appNotificationsFromMaster, ...props}) {
    const [appNotifications, setAppNotifications] = useState(false);
    const [desktopNotifications, setDesktopNotifications] = useState(false);
    const [permissionState, setPermissionState] = useState();
    const [capShare, setCapShare] = useState(null);
    const [caps, setCaps] = useState(null);
    const [buttonSize, setButtonSize] = useState("large");
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        let isMounted = true;

        navigator.permissions
        .query({ name: 'notifications' })
        .then(permission => {if(isMounted) setPermissionState(permission.state)});

        if(window.innerWidth<767) {
            setButtonSize("small");
        }

        async function getCapShare(userId) {
            const tempShare = await getSharedCaps(userId);
            if(isMounted) {
                setCapShare(tempShare);
            
                const tempCaps = await getCaps();
                if(isMounted) {
                    setCaps(tempCaps);
                }
            }
        }

        const user = getUser();
        if(user) {
            getCapShare(user.userId);
            let updateUser = false;
            if(user.settings!=null) {
                if(user.settings.powerSummaryNotifications == null) {
                    user.settings.powerSummaryNotifications = false;
                    updateUser = true;
                }
                if(user.settings.email == null) {
                    user.settings.email = true;
                    updateUser = true;
                }
                if(isMounted)
                    setSettings(user.settings);
            } else {
                const tempSettings = {powerSummaryNotifications:false};
                user.settings = tempSettings;
                if(isMounted) {
                    setSettings(tempSettings);
                    updateUser = true;
                }
            }
            if(updateUser) {
                const newUser = {userId:user.userId,settings:user.settings};
                patchUser(user.userId,newUser)
            }
            if(user.settings && user.settings.appEnabled) { // Mobile Device Notifications
                if(Notification.permission==="granted") {
                    if(isMounted)
                        setAppNotifications(true);
                }
            } else if(user.settings && user.settings.desktopEnabled) { // Desktop Device Notifications
                if(Notification.permission==="granted") {
                    if(isMounted) {
                        setDesktopNotifications(true);
                    }
                }
            }
        }
        return () => { isMounted = false; };
    },[]);

    useEffect(() => {
        setAppNotifications(appNotificationsFromMaster);
    },[appNotificationsFromMaster]);

    useEffect(() => {
        let isMounted = true;

        if(permissionState==="granted") {
            const u = getUser();
            if(u.settings) {
                if(u.settings.appEnabled) {
                    if(isMounted)
                        setAppNotifications(true);
                } else if (u.settings.desktopEnabled) {
                    if(isMounted)
                        setDesktopNotifications(true);
                }
            }
        } else if (permissionState==="denied") {
            if(mobileCheck()) {
                if(isMounted)
                    setAppNotifications(false);
            } else {
                if(isMounted)
                    setDesktopNotifications(false);
            }
        } else {
            console.log("permission state:",permissionState);
        }
        return () => { isMounted = false; };
    },[permissionState]);

    function getCapName(capId) {
        if(caps) {
            for(var i=0; i<caps.length; i++) {
                if(caps[i].capid===capId) {
                    return caps[i].neighborhood ? caps[i].name + ", "+caps[i].neighborhood : caps[i].name;
                }
            }
        }
    }

    async function handleSummaryChange() {
        var newSettings = JSON.parse(JSON.stringify(settings));
        newSettings.powerSummaryNotifications = !newSettings.powerSummaryNotifications;
        setSettings(newSettings);
        const user = getUser();
        const newUser = {userId:user.userId,settings:newSettings};
        await patchUser(user.userId,newUser);
    }

    async function handleNotificationChange(userCap,i,app=false) {
        if(app) {
            const currentAN = userCap.appNotifications;
            const newShare = {userCap:userCap.userCapId,appNotifications:!currentAN}
            await patchSharing(userCap.userCapId,newShare);
            let tempUserCap = JSON.parse(JSON.stringify(capShare));
            tempUserCap[i].appNotifications = !currentAN;
            setCapShare(tempUserCap);
        } else {
            const currentPN = userCap.powerNotifications;
            const newShare = {userCapId:userCap.userCapId,powerNotifications:!currentPN}
            await patchSharing(userCap.userCapId,newShare);
            let tempUserCap = JSON.parse(JSON.stringify(capShare));
            tempUserCap[i].powerNotifications = !currentPN;
            setCapShare(tempUserCap);
        }
    }

    const enableAppNotifications = (
        <Tooltip id="enableApp" style={{fontSize:"18px"}}>
            Enable App Notifications above to get specific cap power app notifications
        </Tooltip>
    )
    

    return (
        <View>
            <PageHeader>
                <div style={{fontSize:buttonSize==="small"?"24px":"36px"}}>
                    Power Notifications
                </div>
            </PageHeader>
            <View>
                <Table condensed striped bordered hover>
                    <thead>
                        {/* <tr>
                            <th></th>
                            <th colSpan={appNotifications ? "2" : "1"}>Power Notifications</th>
                        </tr> */}
                        <tr>
                            <th style={{textAlign:"center"}}>CAP</th>
                            <th style={{textAlign:"center"}}>Email</th>
                            <th style={{textAlign:"center"}}>App</th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign:"center"}}>
                        {getUser().role==="ADMIN" ? caps && capShare && caps.map((cap,i) => ( // ADMIN PERMANENT - handles user_cap ADMIN oddities
                            <React.Fragment key={i}>
                            {i==0 ? (
                                <tr style={{width:"100%"}}>
                                    <td colSpan={appNotifications ? "3" : "2"}>ADMIN--Showing a few caps as an example</td>
                                </tr>
                            ) : (cap.status === 'ACTIVE' || cap.status==='BUILD' ) && i<50 && (
                                <tr style={{width:"100%"}}>
                                    <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                        {getCapName(cap.capid)}
                                    </td>
                                    <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                        <form>
                                            <FormGroup controlId={i.toString()}>
                                                <Checkbox checked={true} readOnly={true} disabled={true}>
                                                    <Glyphicon glyph="enveolpe" />
                                                </Checkbox>
                                            </FormGroup>
                                        </form>
                                    </td>
                                    {appNotifications ? (
                                        <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                            <form>
                                                <FormGroup controlId={cap.capid}>
                                                    <Checkbox disabled={!appNotifications} checked={true} readOnly={true} disabled={true}>
                                                        <Glyphicon glyph="enveolpe" />
                                                    </Checkbox>
                                                </FormGroup>
                                            </form>
                                        </td>
                                    ) : (
                                        <OverlayTrigger placement="left" overlay={enableAppNotifications}>
                                            <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                                <form>
                                                    <FormGroup controlId={cap.capid}>
                                                        <Checkbox disabled={!appNotifications} checked={true} readOnly={true} disabled={true}>
                                                            <Glyphicon glyph="enveolpe" />
                                                        </Checkbox>
                                                    </FormGroup>
                                                </form>
                                            </td>
                                        </OverlayTrigger>
                                    )}
                                </tr>
                            )}
                            </React.Fragment>
                        )) : caps && capShare && capShare.map((cs,i) => (
                            <tr key={i} style={{width:"100%"}}>
                                <td style={{fontSize:buttonSize==="large"?"16px":"12px",wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                    {getCapName(cs.capId)}
                                </td>
                                <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                    <form>
                                        <FormGroup controlId={i.toString()}>
                                            <Checkbox checked={cs.powerNotifications} onChange={() => handleNotificationChange(cs,i)}>
                                                <Glyphicon glyph="envelope" />
                                            </Checkbox>
                                        </FormGroup>
                                    </form>
                                </td>
                                {appNotifications ? (
                                    <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                        <form>
                                            <FormGroup controlId={cs.userCapId}>
                                                <Checkbox disabled={!appNotifications} checked={cs.appNotifications} onChange={() => handleNotificationChange(cs,i,true)}>
                                                    <Glyphicon glyph="phone" />
                                                </Checkbox>
                                            </FormGroup>
                                        </form>
                                    </td>
                                ) : (
                                    <td style={{wordWrap:"break-word",whiteSpace:"pre-wrap"}}>
                                        <OverlayTrigger placement="left" overlay={enableAppNotifications}>
                                            <form>
                                                <FormGroup controlId={cs.userCapId}>
                                                    <Checkbox disabled={!appNotifications} checked={cs.appNotifications} onChange={() => handleNotificationChange(cs,i,true)}>
                                                        <Glyphicon glyph="phone" />
                                                    </Checkbox>
                                                </FormGroup>
                                            </form>
                                        </OverlayTrigger>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </View>
            <PageHeader>
                <div style={{fontSize:buttonSize==="small"?"24px":"36px"}}>
                    End of Day Power Reminders
                </div>
            </PageHeader>
            <View>
                <table>
                    <thead>
                        <tr style={{textAlign:"center"}}>
                            <td style={{paddingLeft:buttonSize==="large"?"0px":"25px",fontSize:buttonSize==="large" ? "24px" : "18px",wordWrap:"break-word",maxWidth:"850px",whiteSpace:"pre-wrap"}}>
                                Do you want to receive daily power summary emails?
                            </td>
                            <td style={{fontSize:"24px"}}>
                                <FormGroup controlId="powerSummary">
                                    <Checkbox checked={settings ? settings.powerSummaryNotifications!= null ? settings.powerSummaryNotifications : false : false} onChange={handleSummaryChange} >
                                        <Glyphicon glyph="envelope" />
                                    </Checkbox>
                                </FormGroup>
                            </td>
                        </tr>
                    </thead>
                </table>

            </View>
        </View>
    );
}