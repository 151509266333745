/**
 * Convert pair of raw coordinates to a div position
 * to render a rectangle on the screen.
 * @param {Object} imgProps Image properties, including the img
 *  element's `offsetX`, `offsetY`, `height` and `width`.
 * @param {Object} rawBoxCoords Box coordinates of  `startX`, `startY`, 
 *  currX`, and `currY`
 */
 export function calculateRectPosition(imgProps, rawBoxCoords) {
    var left = Math.min(
      rawBoxCoords.startX,
      rawBoxCoords.currX
    );
    var top = Math.min(
      rawBoxCoords.startY,
      rawBoxCoords.currY
    );
    var right = Math.max(
      rawBoxCoords.startX,
      rawBoxCoords.currX
    );
    var bottom = Math.max(
      rawBoxCoords.startY,
      rawBoxCoords.currY
    );
    
    // width of div border
    const DIV_BORDER = 0
    const width = imgProps.width - DIV_BORDER;
    const height = imgProps.height - DIV_BORDER;
  
    // limit rectangles to the size of the image
    // so user can't draw rectangle that spill out of image
    left = Math.max(imgProps.offsetX, left);
    top = Math.max(imgProps.offsetY, top);
    right = Math.min(width + imgProps.offsetX, right);
    bottom = Math.min(height + imgProps.offsetY, bottom);
  
    return {
      left: left - imgProps.offsetX,
      top: top - imgProps.offsetY,
      width: right - left,
      height: bottom - top
    };
  }
  
  /**
   * A predicate that returns true if the rectangle defined
   * by the supplied `position` argument is too small.
   * @param {Object} position Contains width and height as keys
   */
  export function isRectangleTooSmall(position) {
    if (position.width < 10 || position.height < 10) return true;
    return false;
  }

  export function convertImageLabelsToBoxes(labels,classes,imageProps) {
    var boxes = [];
    for(var i=0; i<labels.length; i++) {
      var newBox = {};
      newBox.position = convertImageLabelToBox(labels[i],imageProps);
      newBox.id = i;
      newBox.imageLabelId=labels[i].imageLabelId;
      let label;
      for(var j=0; j<classes.length; j++) {
        if(classes[j].labelClassId===labels[i].labelClassId) {
          label = classes[j];
          break;
        }
      }
      newBox.label = label;
      boxes.push(newBox);
    }
    return boxes;
  }

  export function convertImageLabelToBox(label,imageProps) {
    var rawBox = {}
    var xc = Math.floor(label.xCenter*imageProps.width)-Math.floor(label.width*imageProps.width/2)+imageProps.offsetX;
    var yc = Math.floor(label.yCenter*imageProps.height)-Math.floor(label.height*imageProps.height/2)+imageProps.offsetY;
    rawBox.startX = xc
    rawBox.startY = yc;
    rawBox.currX = xc+(label.width*imageProps.width);
    rawBox.currY = yc+(label.height*imageProps.height);
    return calculateRectPosition(imageProps,rawBox);
  }

  export function convertBoxesToImageLabels(boxes,imageProps,userId,imageId) {
    var labels = [];
    for(var i=0; i<boxes.length; i++) {
      var newLabel = {}
      if(boxes[i].imageLabelId) {
        newLabel.imageLabelId=boxes[i].imageLabelId;
      }
      newLabel.imageId=imageId;
      newLabel.userId=userId;
      newLabel.status="NEW"
      newLabel.labelClassId = boxes[i].label.labelClassId;
      newLabel.width = (boxes[i].position.width)/imageProps.width;
      newLabel.height = (boxes[i].position.height)/imageProps.height;
      newLabel.xCenter = (boxes[i].position.left+(boxes[i].position.width/2.))/imageProps.width;
      newLabel.yCenter = (boxes[i].position.top+(boxes[i].position.height/2.))/imageProps.height;
      labels.push(newLabel);
    }
    return labels;
  }