import React, {useState, useEffect, useRef} from "react";
import { getCameraToken, setCameraToken, setCameras, getCameras, getMonitorsGroupById, getCap, storeCap, getLatestUrls, storeLatestUrls, useKeyPress, reformatDate, getUserInformation, getUser } from "../utils/Common";
import { Button, Glyphicon, Tooltip, OverlayTrigger, ButtonGroup, Label } from "react-bootstrap";
import { View } from "react-native";
import "./Camera.css";
// import { loginToCameras, getMonitorStatus } from "../utils/cameraAPI";
import { loadCap, getLatestStoredImages, getMonitors, getImagesFromCapThroughCloud, getMonitorImages, recordUserAction, loginToCamerasThroughAPI } from "../utils/bedrockAPI";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const UPDATE_INTERVAL = 2; //TODO change this back
const TIMEOUT = 90;
// const ORDER = ['house','neighbor','street','driveway'];

export default function Camera(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [cap,setCap] = useState(null);
    const [renderError, setRenderError] = useState(false);
    const [localCameras, setLocalCameras] = useState([]);
    const [bigCamera, setBigCamera] = useState(null);
    const [mediumCamera, setMediumCamera] = useState(null);
    const [url, setUrl] = useState("");
    const [savedUrl, setSavedUrl] = useState("");
    const [time, setTime] = useState(0);
    const [update, setUpdate] = useState(0);
    const [streaming, setStreaming] = useState(true);
    const [fullyLoaded, setFullyLoaded] = useState(false);
    const [streamQuality, setStreamQuality] = useState("medium");
    // const [monitorStatus, setMonitorStatus] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    const [prevImage, setPrevImage] = useState(null);
    const [nextImage, setNextImage] = useState(null);
    const [imgDate, setImgDate] = useState(null);
    const transformer = useRef(null);
    const leftPress = useKeyPress("ArrowLeft");
    const rightPress = useKeyPress("ArrowRight");

    // 1 - Start clocks
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        // console.log(window.innerWidth);
        const interval = setInterval(() => {
            setTime(time => time+1);
            setUpdate(update => update+1);
        },1000);
        return () => clearInterval(interval);
    },[]);

    // Don't let the user keep pulling live images while AFK
    useEffect(() => {
        if(streaming && time > 0 && time%TIMEOUT===0) {
            setTime(0);
            setStreaming(false);
        }
    },[time]);

    // async function checkMonitorStatus() {
    //     const connected = await getMonitorStatus(cap.port,bigCamera);
    //     setMonitorStatus(connected.Status==="Connected");
    // }

    // Reload the live urls (forcing an image reload) every update interval
    useEffect(() => {
        if(update > 0 && update%UPDATE_INTERVAL===0) {
            if(streaming) {
                loadUrl();
                // if(!monitorStatus) {
                //     checkMonitorStatus();
                // }
            }
            setUpdate(0);
        }
    },[update]);

    // 2 - Load the cap from memory 1st then api
    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);                    
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"camera",props.match.params.capid,{'monitorId':props.match.params.monitorId,'userData':getUserInformation()});
    },[props.match.params.capid])

    async function cameralogin() {
        try {
            const camCredentials = await loginToCamerasThroughAPI(cap.capId);
            setCameraToken(camCredentials.access_token);
        } catch(e) {
            console.log(e);
            setCameraToken(null);
        }
    }

    // 3 - Load camera metadata and saved urls from cloud images
    useEffect(() => {
        async function handleLoadCameras() {
            const tempCams = await getMonitors(cap.capId);
            setLocalCameras(tempCams);
            setCameras(tempCams);
        }
        async function loadBigAndSmallCameras() {
            try {
                const cams = getCameras();
                if(cams==null) {
                    handleLoadCameras();
                } else if(cams!=null && cams.capId !== cap.capId) {
                    handleLoadCameras();
                } else {
                    setLocalCameras(cams);
                }
            } catch(e) {
                console.log(e);
            }
        }
        async function requestSavedCamera() {
            try {
                var tempImageUrls = getLatestUrls();
                if(tempImageUrls == null) {
                    tempImageUrls = await getLatestStoredImages(props.match.params.capid);
                    storeLatestUrls(tempImageUrls);
                }
                setSavedUrl(tempImageUrls[props.match.params.monitorid]);
            } catch (e) {
                setRenderError(true);
                console.log(e);
            }
        }
        // let rerender = true;
        if(cap!=null && getCameraToken()==null) {
            // if(rerender)
            cameralogin();
            // if(rerender)
            nextPreviousImages();
        }
        if(cap!=null) {
            setIsLoading(true);
            // if(rerender)
            requestSavedCamera();
            // if(rerender)
            loadBigAndSmallCameras();
            setIsLoading(false);
        }
        // return () => rerender = false;
    },[cap]);

    // 4a - Set the different resolution monitors
    useEffect(() => {
        // monitorid in url is the high res stream (medium), get the grouping and assign the ultra res stream
        if(cap && localCameras) {
            const camerasAtDirection = getMonitorsGroupById(localCameras,props.match.params.monitorid);
            setBigCamera(camerasAtDirection.high);
            setMediumCamera(camerasAtDirection.medium);
        }
    },[localCameras]);

    // 4b - Set the visual image url based on what is available (cloud vs live)
    useEffect(() => {
        // let rerender = true;
        if(savedUrl && bigCamera && mediumCamera) {
            if(!fullyLoaded) {
                // if(rerender)
                loadUrl();
            }
        }
        // return () => rerender = false;
    },[savedUrl, bigCamera, mediumCamera]);

    // useEffect(() => {
    //     // let rerender = true;
    //     if(bigCamera!=null) {
    //         // if(rerender)
    //         checkMonitorStatus();
    //     }
    //     // return () => rerender = false;
    // },[bigCamera])

    // 5 - Attempt to load live image (either medium or high res based on availability)
    useEffect(() => {
        // Load medium res image first, then attempt high res image
        // let rerender = true;
        if(url && !fullyLoaded) {
            const img = new Image()
            // if(rerender) {
            img.src = url;
            img.onload = (function(event) {
                setFullyLoaded(true);
                setStreamQuality("high");
            });
            img.onerror = (function(event) {setFullyLoaded(false)});
            // }
        }
        // return () => rerender = false;
    },[url])

    // 6 - Switch to loading hires stream if available
    // useEffect(() => {
    //     if(monitorStatus===true) {
    //         setStreamQuality("high");
    //     }
    // },[monitorStatus]);

    useEffect(() => {
        // let rerender = true;
        if(streamQuality==="high") {
            // if(rerender)
            loadUrl();
        }
        // return () => rerender = false;
    },[streamQuality]);

    async function handlePrevious(event=null) {
        if(prevImage) {
            setIsLoading(true);
            setStreamQuality("history");
            setStreaming(false);
            setUrl(prevImage.url);
            // console.log(prevImage);
            setImgDate(new Date(prevImage.captureDate));
            nextPreviousImages(new Date(prevImage.captureDate));
            setIsLoading(false);
        }
    }

    async function handleNext(event=null) {
        setIsLoading(true);
        if(nextImage==null) {
            setStreaming(true);
            // setMonitorStatus(false);
            // checkMonitorStatus();
            // setStreamQuality("medium");
            // loadUrl("medium");
            setTime(0);
        } else {
            setStreamQuality("history");
            setStreaming(false);
            setUrl(nextImage.url);
            // console.log(nextImage);
            setImgDate(new Date(nextImage.captureDate));
            nextPreviousImages(new Date(nextImage.captureDate));
        }
        setIsLoading(false);
    }

    // Handle arrow key navigation
    useEffect(() => {
        // let rerender = true;
        if(url && leftPress) {
            // if(rerender)
            handlePrevious();
        }
        // return () => rerender = false;
    }, [leftPress]);

    useEffect(() => {
        // let rerender = true;
        if(url && rightPress) {
            // if(rerender)
            handleNext();
        }
        // return () => rerender = false;
    }, [rightPress]);

    async function nextPreviousImages(captureDate=null) {
        // console.log(prevImage);
        const nextImages = await getMonitorImages(props.match.params.monitorid,"capture_date ASC",captureDate);
        const prevImages = await getMonitorImages(props.match.params.monitorid,"capture_date DESC",captureDate);
        // console.log(prevImages);
        if(nextImages.length>1) {
            setNextImage(nextImages[1]);
        } else {
            setNextImage(null);
        }
        if(prevImages.length>1) {
            setPrevImage(prevImages[0]);
        } else {
            setPrevImage(null);
        }
    }

    async function loadUrl(sq=null) {
        if(sq==null) sq = streamQuality;
        let tempCloudImage;
        if(bigCamera && sq==="high") {
            const cam = [bigCamera];
            try {
                tempCloudImage = await getImagesFromCapThroughCloud(cap,cam,getCameraToken());
            } catch(e) {
                // skip to next frame?
            }
            if(tempCloudImage && bigCamera.monitorId in tempCloudImage) {
                setUrl(tempCloudImage[bigCamera.monitorId].url);
                setImgDate(new Date(tempCloudImage[bigCamera.monitorId].captureDate));
                // console.log(tempCloudImage[bigCamera.monitorId])
            } else if (!tempCloudImage) {
                // skip to next frame?
            } else {
                cameralogin();
                // console.log(prevImage);
                handlePrevious();
            }
        } else if(mediumCamera && sq==="medium") {
            const cam = [mediumCamera];
            const tempCloudImage = await getImagesFromCapThroughCloud(cap,cam,getCameraToken());
            if(mediumCamera.monitorId in tempCloudImage) {
                setUrl(tempCloudImage[mediumCamera.monitorId].url);
                setImgDate(new Date(tempCloudImage[mediumCamera.monitorId].captureDate));
            } else {
                cameralogin();
            }
        }
        if(tempCloudImage)
            nextPreviousImages();
    }

    function renderLargeCamera() {
        function backToCaps() { 
            setIsLoading(true);
            props.history.push(`/caps/${props.match.params.capid}`)
        }

        // function handlePrevious(event) {
        //     setIsLoading(true);
        //     let nextCam = null;
        //     let foundCam = false;

        //     if(localCameras) {
        //         // Make list of 1,4,3,2,1
        //         ORDER.concat(ORDER[0]).reverse().forEach((direction,i) => {
        //             if(nextCam == null && foundCam) {
        //                 nextCam = localCameras[direction].medium;
        //             }
        //             if(localCameras[direction].medium.monitorId===props.match.params.monitorid) {
        //                 foundCam = true;
        //             }
        //         });
        //         props.history.push(`/caps/${props.match.params.capid}/cameras/${nextCam.monitorId}`);
        //     }
        //     setFullyLoaded(false);
        //     setIsLoading(false);
        // }

        // function handleNext(event) {
        //     setIsLoading(true);
        //     let nextCam = null;
        //     let foundCam = false;
        //     if(localCameras) {
        //         // Make list of 1,2,3,4,1 (append the first to the end)
        //         ORDER.concat(ORDER[0]).forEach((direction,i) => {
        //             if(nextCam == null && foundCam) {
        //                 nextCam = localCameras[direction].medium;
        //             }
        //             if(localCameras[direction].medium.monitorId===props.match.params.monitorid) {
        //                 foundCam = true;
        //             }
        //         });
        //         props.history.push(`/caps/${props.match.params.capid}/cameras/${nextCam.monitorId}`);
        //     }
        //     setFullyLoaded(false);
        //     setIsLoading(false);
        // }

        const tooltip=(
            <Tooltip id="refresh" style={{fontSize: "18px"}} disabled={buttonSize==="small"}>
                Refresh if stream is not loading
            </Tooltip>
        )

        return (
            <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <View ref={transformer}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingLeft: "5px", paddingBottom: "5px"}}>
                        <Button
                            bsStyle="primary"
                            bsSize={buttonSize}
                            disabled={isLoading}
                            onClick={!isLoading ? backToCaps : null}
                        >
                            Back to CAP
                        </Button>
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', paddingRight:"5px", flexWrap: 'wrap'}}>
                            <View style={{paddingRight: "5px", paddingLeft: "5px"}}>
                                <OverlayTrigger placement="left" overlay={tooltip}>
                                    <Button 
                                        bsStyle="info"
                                        bsSize={buttonSize}
                                        disabled={isLoading}
                                        onClick={() => window.location.reload(false)}
                                    >
                                        <Glyphicon glyph="refresh"></Glyphicon>
                                    </Button>
                                </OverlayTrigger>
                            </View>
                            <View style={{paddingRight: "5px"}}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={isLoading || !savedUrl}
                                    href={fullyLoaded ? url : savedUrl ? savedUrl.url : null}
                                >
                                    <Glyphicon glyph="download-alt" />
                                </Button>
                            </View>
                            <ButtonGroup style={{paddingRight:"5px", paddingBottom:"5px"}}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={isLoading}
                                    onClick={zoomIn}
                                >
                                    <Glyphicon glyph="zoom-in" />
                                </Button>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={isLoading}
                                    onClick={zoomOut}
                                >
                                    <Glyphicon glyph="zoom-out" />
                                </Button>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={isLoading}
                                    onClick={resetTransform}
                                >
                                    <Glyphicon glyph="fullscreen" />
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup style={{paddingRight:"5px"}}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={isLoading || !prevImage} // || prevUrl?
                                    onClick={handlePrevious}
                                >
                                    <Glyphicon glyph="arrow-left"></Glyphicon>
                                </Button>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={true}
                                >
                                    {/* <Glyphicon glyph="repeat" style={{paddingRight:"5px"}} /> */}
                                    <Glyphicon glyph="time" />
                                </Button>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    disabled={isLoading || streaming}
                                    onClick={handleNext}
                                >
                                    <Glyphicon glyph="arrow-right"></Glyphicon>
                                </Button>
                            </ButtonGroup>
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection: "row", justifyContent: "space-between", fontWeight:600,padding:"5px"}}>
                        {
                            savedUrl!=null ?
                                fullyLoaded ? 
                                    streaming ? (
                                        <View style={{fontSize: "28px", flex:1, flexDirection: "row"}}>
                                            <Label bsStyle="success" bsSize={buttonSize}>
                                                Live
                                                </Label>
                                            <div style={{paddingLeft: "5px"}}>
                                                {
                                                    update<2 ? 
                                                    "Now" : 
                                                    (update)+"s ago"
                                                } 
                                            </div>
                                        </View> ) : (
                                        <View style={{flex:1, flexDirection:"row",justifyContent:"space-between"}}>
                                            <View style={{paddingRight:"5px"}}>
                                                <Button
                                                    bsStyle="default"
                                                    bsSize={buttonSize}
                                                    disabled={streaming}
                                                    onClick={() => window.location.reload(false)}
                                                >
                                                    Go Live
                                                </Button>
                                            </View>
                                            <View>
                                                <h4>
                                                    {reformatDate(imgDate,null)}
                                                </h4>
                                            </View>
                                        </View>
                                        ) : (
                                            <h4>
                                                Image from {reformatDate(new Date(savedUrl.captureDate))} 
                                            </h4>
                                        ) : (
                                            <h4>
                                                Loading...
                                            </h4>
                                        )
                        }
                        {/* <View style={{flex:1, flexDirection:"row", justifyContent:"flex-end"}}>
                            <ButtonGroup>
                                <Button
                                    bsStyle="default"
                                    bsSize={buttonSize}
                                    onClick={() => getPrevious()}
                                >
                                    <Glyphicon glyph="chevron-left" />
                                </Button>
                                <Button
                                    bsStyle="default"
                                    bsSize={buttonSize}
                                    disabled={true}
                                >
                                    <Glyphicon glyph="time" />
                                </Button>
                                <Button
                                    bsStyle="default"
                                    bsSize={buttonSize}
                                    onClick={() => getNext()}
                                >
                                    <Glyphicon glyph="chevron-right" />
                                </Button>
                            </ButtonGroup>
                        </View> */}
                    </View>
                    {(url || (savedUrl && savedUrl.url)) ? (
                        // <View style={{flex:1}}>
                                <TransformComponent className="test">
                                    <img 
                                        style={{padding: 5, width: "100%", height: "100%", cursor: "pointer" }} 
                                        src={fullyLoaded ? url : savedUrl.url} 
                                        alt={bigCamera ? `${bigCamera.name} Missing ` : `Can't locate image`}
                                        // width={window.innerWidth*0.8}
                                        // onClick={() => {if(streamQuality==="high") setShowFullScreen(!showFullScreen)}}
                                    />
                                </TransformComponent>
                        // </View>
                    ) : <TransformComponent /> }
                </View>
            )}
            </TransformWrapper>
        )
    }

    function renderMissing() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Stream cannot be found, please refresh or go back to all cameras</h3>
            </div>
        )
    }
    
    return (
        <div className="Cameras">
            {renderError ? renderMissing() : (!isLoading && renderLargeCamera())}
        </div>
    );
}