import React, { Component } from "react";
// import DeleteBoxButtonContainer from "../containers/DeleteBoxButtonContainer";
import DeleteBoxButton from "../components/DeleteBoxButton";
import BoxLabel from "../components/BoxLabel";

export default class BoundingBox extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
    this.timer = null;
    this.state = {
      mouseOver: false
    };
  }

  mouseOverHandler() {
    if (!this.state.mouseOver && !this.props.isDrawing) {
      this.timer = setTimeout(() => {
        this.setState({ mouseOver: true });
      }, 100);
    }
  }

  mouseLeaveHandler() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.setState({ mouseOver: false });
  }

  render() {
    var boxStyle = {...this.props.box.position};
    boxStyle.border="2px solid "+this.props.box.label.labelColor;
    boxStyle.fontSize = this.props.box.label.name.length > 6 ? "12px" : "16px";
    return (
        <div
            className="BoundingBox"
            // style={this.props.box.position}
            style={boxStyle}
            onMouseOver={this.mouseOverHandler}
            onMouseLeave={this.mouseLeaveHandler}
        >
            <BoxLabel
                box={this.props.box}
                isDrawing={this.props.isDrawing}
                showTextLabels={this.props.showTextLabels}
                changeLabel={this.props.changeLabel}
            />
            {this.state.mouseOver && (
                <DeleteBoxButton
                    boxId={this.props.box.id}
                    isDrawing={this.props.isDrawing}
                    handleDelete={this.props.handleDelete}
                />
            )}
        </div>
    );
  }
}