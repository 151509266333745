import React, { useState, useEffect, useRef } from "react";
import "./ProjectFiles.css"
import { Tooltip, Button, OverlayTrigger, Glyphicon, ListGroup, ListGroupItem, PageHeader, FormControl, FormGroup } from "react-bootstrap";
import { View } from "react-native";
import { loadCap, loadFiles, uploadFile, deleteFile, recordUserAction } from "../utils/bedrockAPI";
import { getCap, storeCap, getFiles, storeFiles, getUser, getUserInformation } from "../utils/Common";
// import ContentEditable from "react-contenteditable";
// import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Progress } from 'reactstrap';

export default function ProjectFiles(props) {
    const [cap, setCap] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [buttonSize, setButonnSize] = useState("large");
    const [files, setFiles] = useState([]);
    // const [newName, setNewName] = useState("Upload File");
    const file = useRef(null);
    const [progress,setProgress] = useState(-1);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if(window.innerWidth < 767) {
            setButonnSize("small");
        }
    },[]);

    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"files",props.match.params.capid,{'userData':getUserInformation()});
    },[props.match.params.capid])

    useEffect(() => {
        async function accessFiles() {
            try {
                var tempFiles = getFiles();
                if(tempFiles == null || tempFiles.capId !== props.match.params.capid) {
                    tempFiles = await loadFiles(props.match.params.capid);
                    storeFiles(tempFiles);
                }
                setFiles(tempFiles);
            } catch(e) {
                console.log(e);
            }
        }
        if(cap) {
            accessFiles();
        }
    },[cap]);
    
    useEffect(() => {
        console.log(progress);
    },[progress]);

    function renderFiles() {
        function download(url) {
            window.open(url);
        }
        async function removeFile(file) {
            setIsLoading(true);
            if (window.confirm(`Are you sure you wish to delete ${file.name}?`)) {
                await deleteFile(cap.capId,file.projectFileId);
                let newFiles = [];
                for(var i=0; i<files.length; i++) {
                    if(files[i].projectFileId!==file.projectFileId) {
                        newFiles.push(files[i]);
                    }
                }
                setFiles(newFiles);
            }
            setIsLoading(false);
        }
        return (
            files.map((file,i) => (
                <View key={file.projectFileId} style={{felx:1, flexDirection:"row"}}>
                    <ListGroupItem header={file.name} onClick={() => download(file.url)}>
                        {(new Date(file.addDate)).toString()}
                    </ListGroupItem>
                    {getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                        <Button
                            bsStyle="danger"
                            bsSize="large"
                            onClick={() => removeFile(file)}
                        >
                            <Glyphicon glyph="remove"/>
                        </Button>
                    )}
                </View>
            ))
        );
    }

    function renderContent() {
        // async function createNew() {
        //     setIsLoading(true);
        //     const newFile = await makeNewFile(newName);
        //     console.log(newFile);
        //     // TODO: show success of new cap
        //     setIsLoading(false);
        // }
        // var handleNameChange = function(event) {
        //     setNewName(event.target.value.replace(/(<([^>]+)>)/ig,""));
        // };
      
        // var pastAsPlainText = function(event) {
        //     event.preventDefault();
        
        //     const text = event.clipboardData.getData('text/plain');
        //     document.execCommand('insertHTML',false,text);
        // };
      
        // var disableNewLines = function(event) {
        //     const keyCode = event.keyCode || event.which;
    
        //     if(keyCode === 13) {
        //         event.returnValue = false;
        //         if (event.preventDefault) event.preventDefault()
        //     }
        // };
      
        // var highlightAll = function(event) {
        //     setTimeout(() => {
        //         document.execCommand('selectAll', false, null);
        //     }, 0);
        // }
        async function handleUpload() {
            let attachment;
            event.preventDefault();
            if(file.current && file.current.size > process.env.REACT_APP_MAX_ATTACHMENT_SIZE) {
                alert(
                    `Please pick a file smaller than ${process.env.REACT_APP_MAX_ATTACHMENT_SIZE/1000000} MB`
                );
                return;
            }
            setIsUploading(true);

            try {
                if(file.current) {
                    attachment = await uploadFile(cap.capId,file.current,setProgress);
                }
                attachment[0].addDate = new Date();
                const a = [...files,attachment[0]];
                console.log(a);
                setFiles(a);
            } catch (e) {
                console.log(e);
            }
            setIsUploading(false);
        }

        // function formatFilename(str) {
        //     return str.replace(/^\w+-/, "");
        // }
        
        function handleFileChange(event) {
            file.current = event.target.files[0];
            setProgress(0);
        }

        return (
            <ListGroup>
                {/* <View key="new" style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <ListGroupItem style={{flex: 1, flexDirection: 'column', background: "#EEE"}}>
                        <ContentEditable
                        html={newName}
                        // className="ContentEditable-header"
                        disabled={isLoading}
                        onPaste={pastAsPlainText}
                        onKeyPress={disableNewLines}
                        onFocus={highlightAll}
                        onChange={handleNameChange}
                        />
                    </ListGroupItem>
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        disabled={isLoading}
                        onClick={createNew}
                    >
                        <Glyphicon glyph="plus"></Glyphicon>
                    </Button>
                </View> */}
                {getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                    <form onSubmit={handleUpload}>
                        <View style={{flex:1, flexDirection:"row", justifyContent:"space-between"}}>
                            <View style={{flex:1, flexDirection:"column"}}>
                                <ListGroupItem header="Upload New File" bsStyle="info" style={{flex:1}}>
                                    {/* {file !=null && file.attachment && ( // This is for loading old files...
                                        <FormGroup>
                                            <ControlLabel>
                                                Attachment
                                            </ControlLabel>
                                            <FormControl.Static>
                                                <a 
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={file.url}
                                                >
                                                    {formatFilename(file.attachment)}
                                                </a>
                                            </FormControl.Static>
                                        </FormGroup>
                                    )} */}
                                    <FormGroup controlId="file">
                                        <FormControl onChange={handleFileChange} type="file" />
                                    </FormGroup>
                                </ListGroupItem>
                                {isUploading && (<Progress max="100" color="success" value={progress}>{Math.round(progress,2)}%</Progress>)}
                            </View>
                            <LoaderButton
                                type="submit"
                                bsSize="large"
                                bsStyle="primary"
                                disabled={file.current==null}
                                isLoading={isLoading}
                            >
                                <b>Save</b>
                            </LoaderButton>
                        </View>
                    </form>
                )}
                {renderFiles()}
            </ListGroup>
        );
    }

    function renderHeader() {
        const tooltip = (
            <Tooltip id="refresh" style={{fontSize: "18px"}}>
                Refresh if stream is not loading
            </Tooltip>
        );
        return (
            <View>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{flex: 1, flexDirection: 'row', flexGrow: 10, flexWrap: 'wrap'}}>
                        <View style={{paddingRight: "5px", paddingTop: "5px"}}>
                            <Button
                                bsStyle="primary"
                                bsSize={buttonSize}
                                // disabled={isLoading}
                                onClick={() => props.history.push(`/caps/${cap.capId}`)}
                            >
                                Back to CAP
                            </Button>
                        </View>
                    </View>
                </View>
                <PageHeader style={{marginTop:"-10px"}}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            {cap.name}<small> ({cap.neighborhood})</small>
                        </div>
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1}}>
                            <OverlayTrigger placement="left" overlay={tooltip}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    // disabled={isLoading}
                                    onClick={() => {window.location.reload(false)}}
                                >
                                    <Glyphicon glyph="refresh"></Glyphicon>
                                </Button>
                            </OverlayTrigger>
                        </View>
                    </View>
                </PageHeader>
            </View>
        );
    }

    return (
        <div className="ProjectFiles"> 
            {!isLoading && renderHeader()}
            {!isLoading && renderContent()}
        </div>
    )
}