import React, { useEffect, useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";
import axios from "axios";
import { getNewCapSignup, setNewCapSignup, setUserSession } from '../utils/Common';
import LoaderButton from '../components/LoaderButton';

//TODO: Move api calls out of this file

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  useEffect(() => {
    if(props.location && props.location.state && props.location.state.signupnewcap) {
      setNewCapSignup(true);
    }
    // props.setErrorMessage("We're currently experiencing an issue with our servers.  We're working on a fix and will update you shortly.");
    // props.setErrorStatus("error-message");
    // props.setShowError(true);
  },[])

  function handleLogin(event) {
    event.preventDefault();

    setIsLoading(true);
    const fixedEmail = email.trim().toLocaleLowerCase();

    axios.post(
      `${process.env.REACT_APP_API_URL}/users/login`,
      {"email":fixedEmail,"password":password},
      {headers:{'Content-Type':'application/json','accept':'application/json'}}
    ).then(response => {
      const config = {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${response.data.token}`
        }
      }
      axios.get(
        `${process.env.REACT_APP_API_URL}/users/me`,
        config
      ).then(response2 => {
        props.setShowError(false);
        // console.log(response.data.token);
        setUserSession(response.data.token,response2.data);
        if(getNewCapSignup()) {
          props.history.push('/signupnewcap');
        }
        else if(props.location.state && props.location.state.from && props.location.state.from.pathname) {
          props.history.push(props.location.state.from.pathname);
        } else {
          props.history.push('/dashboard');
        }
      }).catch(error => {
        props.setShowError(true);
        props.setErrorStatus("error-message");
        props.setErrorMessage("Invaild Email or Password, please try again");
        console.log(error);
      })
    }).catch(error => {
      props.setShowError(true);
      props.setErrorStatus("error-message");
      props.setErrorMessage("Invaild Email or Password, please try again");
      console.log(error);
    });

    setIsLoading(false);
  }

  return (
    <div className="Login">
      <form onSubmit={handleLogin}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
        <br />
        <div style={{textAlign:"center"}}>
          <a href="/passwordreset">Forgot your password?</a>
        </div>
        <div style={{textAlign:"center",paddingTop:"10px"}}>
          New User?  <a href="/signup">Sign up here.</a>
        </div>
      </form>
    </div>
  );
}
