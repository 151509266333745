import React, {useState, useEffect, useRef, useLayoutEffect} from "react";
import { getCameraToken, setCameraToken, setCameras, getCameras, getMonitorsGroupById, getCap, storeCap, getLatestUrls, storeLatestUrls, useKeyPress, reformatDate, getUserInformation, getUser } from "../utils/Common";
import { 
    Button, 
    Glyphicon, 
    Tooltip, 
    OverlayTrigger, 
    ButtonGroup, 
    Label,
    Well,
    Collapse
} from "react-bootstrap";
import { View } from "react-native";
import "./Camera.css";
// import { getMonitorStatus } from "../utils/cameraAPI";
import { loadCap, getLatestStoredImages, getMonitors, getImagesFromCapThroughCloud, getMonitorImages, recordUserAction, loginToCamerasThroughAPI, getMonitorAlarmStatus, postStreamControl } from "../utils/bedrockAPI";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoaderButton from "../components/LoaderButton";
import axios from "axios";

const UPDATE_INTERVAL = 2; // Everytime the background snapshot is loaded
const TIMEOUT = 120; // timeout so data doesn't balloon
// const TIMEOUT = 50; // timeout so data doesn't balloon // TODO ^ switch back
const REFRESH_INTERVAL = 30;
const CONN_KEY_RANGE = 999999;
// const ORDER = ['house','neighbor','street','driveway'];

export default function Camera2(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [cap, setCap] = useState(null);
    const [renderError, setRenderError] = useState(false);
    const [localCameras, setLocalCameras] = useState([]);
    const [bigCamera, setBigCamera] = useState(null);
    const [mediumCamera, setMediumCamera] = useState(null);
    const [url, setUrl] = useState("");
    const [savedUrl, setSavedUrl] = useState("");
    const [latestStreamImage, setLatestStreamImage] = useState("");
    const [camToken, setCamToken] = useState(null);
    // const [alarmStatus, setAlarmStatus] = useState(null);
    const [time, setTime] = useState(0);
    const [streaming, setStreaming] = useState(false);
    const [fullyLoaded, setFullyLoaded] = useState(false);
    const [streamQuality, setStreamQuality] = useState("medium");
    const [highLoaded, setHighLoaded] = useState(false);
    const [lowLoaded, setLowLoaded] = useState(false);
    const [highUrl, setHighUrl] = useState(null);
    const [lowUrl, setLowUrl] = useState(null);
    const [connKey, setConnKey] = useState(Math.floor(Math.random()*CONN_KEY_RANGE));
    const [streamScale, setStreamScale] = useState(100);
    const [maxFPS, setMaxFPS] = useState(30);
    const [mjpegTime, setMjpegTime] = useState(null);
    const [signalWeak, setSignalWeak] = useState(false);
    // const [backgroundImg, setBackgroundImg] = useState([]);
    // const [monitorStatus, setMonitorStatus] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [playPauseLoading, setPlayPauseLoading] = useState(false);
    const [changeStreamLoading, setChangeStreamLoading] = useState(false);
    const [showResolutionHelp, setShowResolutionHelp] = useState(false);
    const [imgDate, setImgDate] = useState(null);
    const transformer = useRef();
    const componentMounted = useRef(true);
    const lowFeed = useRef();
    const highFeed = useRef();
    // const leftPress = useKeyPress("ArrowLeft");
    // const rightPress = useKeyPress("ArrowRight");

    // 1 - Start clocks
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        // console.log(window.innerWidth);
        const interval = setInterval(() => {
            setTime(time => time+1);
        },1000);

        return () => {
            // setUrl("");
            clearInterval(interval);
            componentMounted.current = false;
            // console.log(highFeed,lowFeed);
            // if(highFeed.current) {
            //     highFeed.current.src = "";
            //     highFeed.current.onload = null;
            //     delete highFeed.current.src;
            // }
            // if(lowFeed.current) {
            //     lowFeed.current.src = "";
            //     lowFeed.current.onload = null;
            //     delete lowFeed.current.src;
            // }
            // console.log(highFeed,lowFeed);
            // const highImg = document.getElementById("mjpeg-high");
            // const lowImg = document.getElementById("mjpeg-low");
            // if(highImg) {
            //     highImg.src = "";
            //     highImg.onload = null;
            // }
            // if(lowImg) {
            //     lowImg.src = "";
            //     lowImg.onload = null;
            // }
            // console.log(lowImg,highImg);
        };
    },[]);

    async function cancelStreams(oldConnKey) {
        if(lowFeed.current || highFeed.current) { // Don't run at initial connection
            // console.log("Canceling",oldConnKey);
            axios.post(`${process.env.REACT_APP_MJPEG_URL}close/${oldConnKey}`).then(response => {
                console.log(response);
            }).catch(err => {
                // console.log("HERE",err);
                // rej(err);
            });
            console.log("Canceling",oldConnKey+1);
            axios.post(`${process.env.REACT_APP_MJPEG_URL}close/${oldConnKey+1}`).then(response => {
                console.log(response);
            }).catch(err => {
                // console.log("HERE",err);
                // rej(err);
            });
        }
    }

    useLayoutEffect(() => {
        return () => {
            // Special close websocket requests (DANGEROUS)
            cancelStreams(connKey);

            // Remove by telling browser to remove (SAFE) - DOES NOT WORK
            // setUrl("");
            // if(highFeed.current) {
            //     highFeed.current.src = "";
            //     highFeed.current.onload = null;
            //     delete highFeed.current.src;
            // }
            // if(lowFeed.current) {
            //     lowFeed.current.src = "";
            //     lowFeed.current.onload = null;
            //     delete lowFeed.current.src;
            // }

            // console.log(highFeed,lowFeed);
        }
    },[connKey]);

    // Don't let the user keep pulling live images while AFK
    useEffect(() => {
        if(streaming) {
            if(time > 0 && time%TIMEOUT===0) {
                setTime(0);
                playPause();
            } else if(camToken && mediumCamera && time > 0 && time%UPDATE_INTERVAL===0) {
                // fetchAlarmStatus();
            }
            // } else if(url && streamQuality==="high" && time > 0 && time%REFRESH_INTERVAL===0) {
            //     // Refresh the stream to current time every so often
            //     let baseUrl = url.split('?')[0];
            //     const newUrl = baseUrl+"?rand="+Math.random();
            //     console.log(newUrl);
            //     setUrl(newUrl);
            // }

            // This was attempt to get onLoad to call every second for the image, no dice
            // if(url) {
            //     const tempUrl = url.split('?')[0];
            //     if(tempUrl) {
            //         const newUrl = tempUrl+`?rand=${Math.floor(Math.random()*CONN_KEY_RANGE)}`;
            //         console.log("UPDATE:\n",tempUrl,'\n',newUrl);
            //         setUrl(newUrl);
            //     }
            // }
        }
    },[time]);

    // 2 - Load the cap from memory 1st then api
    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);                    
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"camera",props.match.params.capid,{'monitorId':props.match.params.monitorId,'userData':getUserInformation()});
    },[props.match.params.capid]);

    async function cameralogin(relogin=false) {
        console.log("Logging into cameras");
        try {
            let camCredentials;
            let t = {};
            if(relogin) {
                camCredentials = (await loginToCamerasThroughAPI(cap.capId)).access_token;
            } else {
                t = getCameraToken();
                if(t && typeof t === 'object' && cap.capId in t) {
                    camCredentials = t[cap.capId];
                    console.log("Using stored camToken");
                } else {
                    camCredentials = (await loginToCamerasThroughAPI(cap.capId)).access_token;
                }
            }
            if(componentMounted.current) {
                setCamToken(camCredentials);
                console.log(camCredentials);
                setCameraToken({...t,...{[cap.capId]:camCredentials}});
            }
        } catch(e) {
            console.log(e);
        }
    }

    // 3 - Load camera metadata and saved urls from cloud images
    useEffect(() => {
        let isMounted = true;

        async function loadBigAndSmallCameras() {
            try {
                const cams = getCameras();
                if(cams==null || cams.capId !== cap.capId) {
                    const tempCams = await getMonitors(cap.capId);
                    if(isMounted) {
                        setLocalCameras(tempCams);
                        setCameras(tempCams);
                    }
                } else {
                    if(isMounted) {
                        setLocalCameras(cams);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        async function requestSavedCamera() {
            try {
                var tempImageUrls = getLatestUrls();
                if(tempImageUrls == null) {
                    tempImageUrls = await getLatestStoredImages(props.match.params.capid);
                    if(isMounted) {
                        storeLatestUrls(tempImageUrls);
                    }
                }
                if(isMounted) {
                    setSavedUrl(tempImageUrls[props.match.params.monitorid]);
                }
            } catch (e) {
                if(isMounted) {
                    setRenderError(true);
                }
                console.log(e);
            }
        }

        const t = getCameraToken();
        if(cap!=null) {
            if(cap.bandwidth === "LOW") {
                setStreamScale(50);
                setMaxFPS(1);
            }
            if((t==null || typeof t !== 'object' || !(cap.capId in t))) {
                cameralogin();
            } else {
                setCamToken(t[cap.capId]);
            }
            if(isMounted) {
                setIsLoading(true);
                requestSavedCamera();
                loadBigAndSmallCameras();
                if(isMounted)
                    setIsLoading(false);
            }
        }
        return () => { isMounted = false; };
    },[cap]);

    async function fetchAlarmStatus() {
        try {
            const s = await getMonitorAlarmStatus(cap.capId,mediumCamera.monitorId,camToken);
            console.log(s);
            // if(componentMounted.current)
            //     setAlarmStatus(s);
        } catch(e) {
            console.log("Couldn't fetch alarm status, attempting camera relogin");
            setCamToken(null);
            cameralogin(true);
        }
    }

    // 4a - Set the different resolution monitors
    useEffect(() => {
        // monitorid in url is the high res stream (medium), get the grouping and assign the ultra res stream
        if(cap && localCameras) {
            const camerasAtDirection = getMonitorsGroupById(localCameras,props.match.params.monitorid);
            setBigCamera(camerasAtDirection.high);
            setMediumCamera(camerasAtDirection.medium);
        }
    },[localCameras]);

    // // 4b - Load the alarm status to verify the camToken
    // useEffect(() => {
    //     if(camToken && mediumCamera) {
    //         fetchAlarmStatus();
    //     }
    // },[camToken, mediumCamera]);

    // 4c - Load the medium stream
    useEffect(() => {
        if(savedUrl && bigCamera && mediumCamera && camToken) {
            if(!fullyLoaded) {
                // if(rerender)
                // loadUrl();

                setStreaming(true);
                // const img = new Image();
                const img = new Image();
                if(!lowFeed.current) {
                    lowFeed.current = img;
                }
                let tempUrl;
                const scale = 100;
                tempUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${mediumCamera.zmId}/${scale}/${maxFPS}/${camToken}/${connKey}`;
                lowFeed.current.onload = function() {
                    setLowUrl(tempUrl);
                    setFullyLoaded(true);
                    setLowLoaded(true);
                };
                lowFeed.current.src= tempUrl;
                // lowFeed.current = img;
                // img.onload = (() => {
                //     if(!fullyLoaded) {
                //         console.log("here2")
                //         // setFullyLoaded(true);
                //         setUrl(tempUrl);
                //         // let current = document.getElementById("mjpeg");
                //         // current = this;
                //     }
                // });
            }
        }
    },[savedUrl, bigCamera, mediumCamera, camToken]);

    // 5 - low feed loaded, go to high feed
    useEffect(() => {
        if(lowLoaded && fullyLoaded && lowUrl && url.localeCompare(lowUrl)!==0) {
            console.log("low:",lowUrl);
            setUrl(lowUrl);
            // if(highFeed.current) highFeed.current.src = "";
            // lowFeed.current.src = lowUrl;
        }
    },[lowLoaded,fullyLoaded,lowUrl]);

    // 5 - Attempt to load live image (either medium or high res based on availability)
    // useEffect(() => {
    //     // Load medium res image first
    //     if(url && !fullyLoaded) {
    //         setFullyLoaded(true);
    //         // const img = new Image();
    //         // const scale = 100;
    //         // img.src = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${mediumCamera.zmId}/${scale}/${maxFPS}/${camToken}/${connKey}`;
    //         // img.onload = (function(event) {
    //         //     if(url.includes("stream.bedrockwireless.com")) {
    //         //         if(componentMounted.current) {
    //         //             setFullyLoaded(true); // meium res stream is showing
    //         //         }
    //         //     }
    //         // });
    //         // img.onerror = (function(event) {
    //         //     if(componentMounted.current) {
    //         //         setFullyLoaded(false)
    //         //     }
    //         // });
    //         // lowFeed.current = img;
    //     }
    // },[url]);

    // 6a - Once the low res stream has loaded, attempt high res
    useEffect(() => {
        if(streaming) {
            setStreamQuality("high"); // First load
        }
    },[streaming]);

    // 6b - Load the highres mjpeg stream once the streamquality has been okayed
    //    - Also load when user/bandwidth changes steram quality
    useEffect(() => {
        if(streamQuality && camToken && bigCamera && mediumCamera) {
            if(streamQuality==="high" && !highLoaded) { // First time the high res is loaded
                // const img = new Image();
                let tempUrl;
                const fps = 1;
                const img = new Image();
                if(!highFeed.current) {
                    highFeed.current = img;
                }
                tempUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${bigCamera.zmId}/${streamScale}/${fps}/${camToken}/${connKey+1}`;
                highFeed.current.onload = function() {
                    setHighUrl(tempUrl);
                    // setTimeout(() => setHighLoaded(true),1000);
                    setHighLoaded(true)
                    setChangeStreamLoading(false);
                };
                // highFeed.current = img;
                highFeed.current.src = tempUrl;
            } else if(streamQuality==="medium" && !lowLoaded) {  // Probably shouldn't happen
                // const img = new Image();
                let tempUrl;
                console.log("is this ever called?");
                const scale = 100;
                tempUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${mediumCamera.zmId}/${scale}/${maxFPS}/${camToken}/${connKey}`;
                lowFeed.current.onload = () => {
                    setLowUrl(tempUrl);
                    setLowLoaded(true);
                    setChangeStreamLoading(false);
                }
                // lowFeed.current = img;
                lowFeed.current.src = tempUrl;
            } else if(streamQuality==="medium") { // They hit the button to switch to low
                setUrl(lowUrl);
                // setChangeStreamLoading(false);
                lowFeed.current.src = lowUrl;
            } else if(streamQuality==="high") { // They hit the button to switch to high
                setUrl(highUrl);
                // setChangeStreamLoading(false);
                if(highFeed.current) {
                    highFeed.current.src = highUrl;
                } else {
                    playPause();
                }
            }
            
            // return () => {
            //     // if(lowFeed.current) lowFeed.current.src = "";
            //     // if(highFeed.current) highFeed.current.src = "";
            // }
        }
    },[streamQuality,camToken]);

    //6c - transfer to high feed
    useEffect(() => {
        if(highLoaded && highUrl) {
            console.log("high:",highUrl);
            setUrl(highUrl);
            // highFeed.current.src = highUrl;
            // if(lowFeed.current) lowFeed.current.src = "";
        }
    },[highLoaded,highUrl]);

    // useEffect(() => {
    //     console.log("Scale:",streamScale);
    //     if(streamScale && streamScale <100 && bigCamera && camToken) {
    //         const img = new Image();
    //         const fps = 1;
    //         const tempUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${bigCamera.zmId}/${streamScale}/${fps}/${camToken}/${connKey}`;
    //         img.src = tempUrl;
    //         img.onload = (function(event) {
    //             if(url.includes("4111")) {
    //                 if(componentMounted.current) {
    //                     setUrl(tempUrl);
    //                 }
    //             }
    //         });
    //         setBackgroundImg(backgroundImg => [...backgroundImg,img]);
    //     }
    // },[streamScale]);

    // useEffect(() => {
    //     console.log("MaxFPS:",maxFPS);
    //     const localConnKey = Math.floor(Math.random()*CONN_KEY_RANGE);
    //     setConnKey(localConnKey);
    //     if(maxFPS!==30) loadUrl(localConnKey);
    // },[maxFPS]);

    function imgLoaded() {
        setChangeStreamLoading(false);
        if(mjpegTime) console.log("DELTA:",time-mjpegTime);
        if(streaming && streamQuality=="high") {
            setFullyLoaded(true);
            if(mjpegTime==null) {
                setMjpegTime(time);
            } else if(mjpegTime===0) {
                setMjpegTime(time);
            } else {
                setMjpegTime(mjpegTime => mjpegTime+1);
                if(streamScale===100 && time-mjpegTime>20) {
                    setSignalWeak(true);
                    setMjpegTime(null);
                    changeStreamScale();
                }
            }
        } else if(streamQuality==="medium" && time-mjpegTime>5) {
            // Should reload the medium res stream with the new fps but doesn't as of 2024-07-07
            setMjpegTime(null);
            // Lower the max fps of the stream
            setMaxFPS(1);
        }
        // highFeed.current.onLoad = imgLoaded;
    }

    async function changeStreamScale() {
        setHighLoaded(false);
        if(streamScale===100) {
            // This should only happen when the connection DELTA goes > 5
            const newStreamScale = 50;
            cancelStreams(connKey);
            setStreamScale(newStreamScale);
            const newKey = Math.floor(Math.random()*CONN_KEY_RANGE);
            setConnKey(newKey);
            // const img = new Image();
            const fps = 1;
            const tempUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${bigCamera.zmId}/${newStreamScale}/${fps}/${camToken}/${newKey+1}`;
            highFeed.current.onload = function() {
                setHighUrl(tempUrl);
                setHighLoaded(true);
            };
            highFeed.current.src = tempUrl;
            // highFeed.current = img;
        } else if(streamScale===50) {
            changeStreamQuality(); // Load the lower res stream and not just a scaled version of the highres
            setStreamScale(25);
        } else {
            // Loop back to big (only for testing)
            setStreamScale(100);
            changeStreamQuality();
        }
    }

    async function changeStreamQuality() {
        setChangeStreamLoading(true);
        if(bigCamera && mediumCamera && camToken) {
            if(streamQuality==="high") {
                // setLowLoaded(false);
                // postStreamControl(cap.capId,bigCamera.monitorId,camToken,connKey+1,"pause");
                // postStreamControl(cap.capId,mediumCamera.monitorId,camToken,connKey,"play");
                setStreamQuality("medium");
            } else {
                // setHighLoaded(false);
                // postStreamControl(cap.capId,mediumCamera.monitorId,camToken,connKey,"pause");
                // postStreamControl(cap.capId,bigCamera.monitorId,camToken,connKey+1,"play");
                setStreamQuality("high");
            }
        }
        // setChangeStreamLoading(false);
    }

    async function playPause() {
        setPlayPauseLoading(true);
        if(streaming) {
            setImgDate(new Date());
            setMjpegTime(null);
            if(bigCamera && camToken) {
                await postStreamControl(cap.capId,bigCamera.monitorId,camToken,connKey+1,"pause");
            }
            if(mediumCamera && camToken) {
                await postStreamControl(cap.capId,mediumCamera.monitorId,camToken,connKey,"pause");
            }
        } else {
            if(bigCamera && camToken && streamQuality==="high") {
                await postStreamControl(cap.capId,bigCamera.monitorId,camToken,connKey+1,"play");
            } else if(mediumCamera && camToken && streamQuality==="medium") {
                await postStreamControl(cap.capId,mediumCamera.monitorId,camToken,connKey,"play");
            }
            setTime(0);
            setMjpegTime(null);
        }
        setStreaming(!streaming);
        setPlayPauseLoading(false);
    }

    // Useful for download button
    async function loadSnapshot() {
        setDownloadLoading(true);
        let tempSnapshot;
        if(bigCamera && streamQuality==="high") {
            try {
                tempSnapshot = await getImagesFromCapThroughCloud(cap,[bigCamera],camToken);
            } catch(e) {
                console.log("Not able to pull snapshot (high)",e);
                cameralogin(true);
            }
            if(tempSnapshot && bigCamera.monitorId in tempSnapshot) {
                if(componentMounted.current) {
                    setLatestStreamImage(tempSnapshot[bigCamera.monitorId].url);
                    setDownloadLoading(false);
                    window.location.href = tempSnapshot[bigCamera.monitorId].url;
                }
            }
        } else if(mediumCamera && streamQuality==="medium") {
            try {
                tempSnapshot = await getImagesFromCapThroughCloud(cap,[mediumCamera],camToken);
            } catch(e) {
                console.log("Not able to pull snapshot (med)", e);
                cameralogin(true);
            }
            if(tempSnapshot && mediumCamera.monitorId in tempSnapshot) {
                if(componentMounted.current) {
                    setLatestStreamImage(tempSnapshot[mediumCamera.monitorId].url);
                    setDownloadLoading(false);
                    window.location.href = tempSnapshot[mediumCamera.monitorId].url;
                }
            }
        }
    }

    async function loadUrl(conn=null) {
        let tempCloudImage;
        if(conn==null) conn = connKey;
        if(bigCamera && streamQuality==="high") {
            try {
                if(streaming && mediumCamera) {
                    // Pauses the medium stream
                    // postStreamControl(cap.capId,mediumCamera.monitorId,camToken,connKey,"pause");
                }
                const fps = 1;
                let liveUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${bigCamera.zmId}/${streamScale}/${fps}/${camToken}/${conn+1}`;
                const now = (new Date()).toISOString();
                tempCloudImage = {[bigCamera.monitorId]:{captureDate:now,id:bigCamera.zmId,url:liveUrl}}
                if(componentMounted.current)
                    setStreaming(true);
            } catch(e) {
                // What would amke this fail?
            }
            if(tempCloudImage && bigCamera.monitorId in tempCloudImage) {
                if(componentMounted.current) {
                    setUrl(tempCloudImage[bigCamera.monitorId].url);
                    setImgDate(new Date(tempCloudImage[bigCamera.monitorId].captureDate));
                }
            } else {
                cameralogin(true); // can this get triggered?
            }
        } else if(mediumCamera && streamQuality==="medium") {
            try {
                if(streaming && bigCamera) {
                    // Pauses the highres stream
                    // postStreamControl(cap.capId,bigCamera.monitorId,camToken,connKey,"pause");
                }
                const scale = 100;
                let liveUrl = `${process.env.REACT_APP_MJPEG_URL}${cap.port}/${mediumCamera.zmId}/${scale}/${maxFPS}/${camToken}/${conn}`;
                console.log(liveUrl);
                const now = (new Date()).toISOString();
                tempCloudImage = {[mediumCamera.monitorId]:{captureDate:now,id:mediumCamera.zmId,url:liveUrl}};
                if(componentMounted.current)
                    setStreaming(true);
            }  catch(e) {
                // What would make this fail?
            }
            if(tempCloudImage && mediumCamera.monitorId in tempCloudImage) {
                if(componentMounted.current) {
                    setUrl(tempCloudImage[mediumCamera.monitorId].url);
                    setImgDate(new Date(tempCloudImage[mediumCamera.monitorId].captureDate));
                }
            } else {
                cameralogin(true) // can this get triggered?
            }
        }
    }

    function renderLargeCamera() {
        function backToCaps() { 
            // setIsLoading(true);
            // lowFeed.current.src = "";
            // delete lowFeed.current.src;
            // highFeed.current.src = "";
            // delete highFeed.current.src;
            props.history.push(`/caps/${props.match.params.capid}`)
        }

        const tooltip=(
            <Tooltip id="refresh" style={{fontSize: "18px"}} disabled={buttonSize==="small"}>
                Refresh if stream is not loading
            </Tooltip>
        );

        function isItHigh() {
            if(streamQuality==="high" && highUrl && highLoaded) {
                return true;
            }
            if(streamQuality==="medium" && lowUrl && lowLoaded) {
                return false;
            }
            return null;
        }

        return (
            <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <View ref={transformer}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingLeft: "5px", paddingBottom: "5px"}}>
                        <View style={{flex:1,flexDirection:"column",maxWidth:buttonSize==="small"?"100px":"200px"}}>
                            <Button
                                bsStyle="primary"
                                bsSize={buttonSize}
                                disabled={isLoading}
                                onClick={!isLoading ? backToCaps : null}
                            >
                                {buttonSize==="small"?"Back":"Back to All Views"}
                            </Button>
                            <View style={{paddingTop:"5px"}}>
                                {!streaming && fullyLoaded ? (
                                    <LoaderButton
                                        bsStyle={"warning"}
                                        bsSize={buttonSize}
                                        isLoading={playPauseLoading}
                                        onClick={() => playPause()}
                                    >Refresh</LoaderButton>
                                ) : (
                                    <Label 
                                        style={buttonSize==="small"?{lineHeight:2,fontSize:"12px"}:{lineHeight:2,fontSize:"18px"}} 
                                        bsStyle={streaming&&fullyLoaded?"success":"warning"}
                                    >
                                        {streaming&&fullyLoaded?"Live":streaming?("Loading"+(time%3===0?".":time%3===1?"..":"...")):"Refresh"}
                                    </Label>
                                )}
                            </View>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', paddingRight:"5px", paddingLeft:"20px", flexWrap: 'wrap'}}>
                            <View style={{flex:1, maxWidth:"400px", flexDirection:"column"}}>
                                <View style={{flex:1,flexDirection:"row"}}>
                                    <View style={{flex:1,flexGrow:1,paddingRight:"5px"}}>
                                        <Label
                                            bsStyle="info"
                                            style={buttonSize==="small"?{lineHeight:2,fontSize:"12px"}:{lineHeight:2,fontSize:"18px"}}
                                        >Stream Resolution</Label>
                                    </View>
                                </View>
                                <View style={{paddingTop:"5px",flex:1,flexDirection:"row"}}>
                                    <ButtonGroup justified style={{width:"100%"}}>
                                        <LoaderButton
                                            bsStyle="default"
                                            bsSize={buttonSize}
                                            style={{width:"50%"}}
                                            active={streamQuality==="medium"}
                                            disabled={streamQuality==="medium"||!highLoaded}
                                            isLoading={changeStreamLoading&&streamQuality==="medium"}
                                            onClick={changeStreamQuality}
                                        >Standard</LoaderButton>
                                        <LoaderButton
                                            bsStyle="default"
                                            bsSize={buttonSize}
                                            style={{width:"50%"}}
                                            active={streamQuality==="high"}
                                            disabled={streamQuality==="high"||!highLoaded}
                                            isLoading={changeStreamLoading&&streamQuality==="high"}
                                            onClick={changeStreamQuality}
                                        >High</LoaderButton>
                                    </ButtonGroup>
                                    <View style={{paddingLeft:"5px"}}>
                                        <Button
                                            bsStyle="default"
                                            bsSize={buttonSize}
                                            onClick={() => setShowResolutionHelp(!showResolutionHelp)}
                                        ><Glyphicon glyph="info-sign" /></Button>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{padding:"5px"}}>
                        <Collapse in={showResolutionHelp}>
                            <Well>
                                <b>High Resolution:</b> If your site has a fast cellular connection, you can use this setting to pull high-resolution images. It will automatically pull between 2MP to 4MP images depending on cellular speed. At peak speed, it will pull one image per second. If you want an even faster video, Standard Resolution is a good option.<br /><br />
                                <b>Standard Resolution:</b> We recommend using this resolution if the construction site's cellular connection is slow. Under normal conditions, this setting can pull between 5-24 images per second to create a smooth video.
                            </Well>
                        </Collapse>
                    </View>
                    <View style={{paddingLeft:"10px"}}>
                        <span style={{fontSize:buttonSize==="small"?"12px":"16px"}}>
                            {streaming && signalWeak ? "Connection is weak, stream may lag behind real time..." : 
                            streaming ? "" : 
                            fullyLoaded ? "Stream timed out, click refresh to reload the live view" :
                            ""
                            }
                        </span>
                    </View>
                    {(url || (savedUrl && savedUrl.url)) ? (
                        <TransformComponent className="test">
                            {componentMounted.current && (
                                <img 
                                    style={{padding: 5, width: "100%", height: "100%", cursor: "pointer" }} 
                                    // src={componentMounted.current ? url : ""}
                                    // display: isItHigh()===false ? "block" : "none",
                                    src = {url && isItHigh()!==null ? url : savedUrl.url}
                                    // alt={bigCamera ? (time%3===0?".":time%3===1?"..":"...") : `Can't locate image`}
                                    alt = "Loading......"
                                    onLoad={imgLoaded}
                                    // id="mjpeg-low"
                                    ref={isItHigh() ? highFeed : lowFeed}
                                />
                            ) 
                            }
                        </TransformComponent>
                    ) : <TransformComponent /> }
                    {/* <View style={{paddingTop:"5px",flex:1,flexDirection:"row",alignContent:"center",justifyContent:"center"}}>
                        <Button
                            bsSize={buttonSize}
                            bsStyle="default"
                            onClick={() => setMjpegTime(mjpegTime => mjpegTime-1)}
                        >DEBUG</Button>
                        <View style={{paddingLeft:"5px"}}>
                        <Button
                            bsSize={buttonSize}
                            bsStyle="warning"
                            onClick={() => props.history.push(`/caps/${cap.capId}/cameras/${props.match.params.monitorid}`)}
                        >Previous Version</Button>
                        </View>
                    </View> */}
                </View>
            )}
            </TransformWrapper>
        )
    }

    function renderMissing() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Stream cannot be found, please refresh or go back to all cameras</h3>
            </div>
        )
    }
    
    return (
        <div className="Cameras">
            {renderError ? renderMissing() : (!isLoading && renderLargeCamera())}
        </div>
    );
}