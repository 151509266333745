import React, { useEffect, useState } from "react";
import { postShareWithEmail } from "../utils/bedrockAPI";
import { Modal,FormGroup, Checkbox, Tooltip, OverlayTrigger, Glyphicon, Button } from "react-bootstrap";
import { View, TextInput } from "react-native";
import "./SharingModal.css";

export default function SharingModal({show,cap,setShowError,setErrorMessage,setErrorStatus,setShowSharingWindow,...props}) {
    const [sharedEmail, setSharedEmail] = useState("");
    const [sharedType,setSharedType] = useState("DEMO");
    const [emailFocused, setEmailFocused] = useState(false);
    const [motionCheckBox, setMotionCheckBox] = useState(false);
    const [editingCheckBox, setEditingCheckBox] = useState(false);
    const [powerCheckBox, setPowerCheckBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [showSharingWindow, setShowSharingWindow] = useState(show);
    const [sharableCap, setSharableCap] = useState(cap);
    const [buttonSize, setButtonSize] = useState("large");

    useEffect(() => {
      if(window.innerWidth<767) {
        setButtonSize("small");
      }
    },[]);

    useEffect(() => {
      setSharableCap(cap);
    },[cap]);

    // useEffect(() => {
    //   setShowSharingWindow(show);
    // },[show]);

    async function share() {
      if(!sharedEmail || !sharedType) {
        alert("Please make sure you provide an email address");
        return
      }
      if((sharedEmail.match(/@/g)||[]).length > 1) {
        alert("Please only share with one email at a time");
        return
      }
      setIsLoading(true);
      const fixedEmail = sharedEmail.trim().toLocaleLowerCase(); // TODO: put in API
      // console.log(fixedEmail, sharedType);
      try {
        let c = JSON.parse(JSON.stringify(sharableCap));
        if(c.capid) {
          c.capId = c.capid;
        }
        // await shareCapWithUser(sharableCap.capid, sharedEmail, sharedType);
        await postShareWithEmail(c.capId, fixedEmail, sharedType, powerCheckBox);
        setErrorStatus("success-message");
        setErrorMessage("Successfully shared CAP")
        setShowError(true);
      } catch (e) {
        setErrorStatus("error-message");
        setErrorMessage("User already shared on CAP");
        setShowError(true);
        console.log(e);
      }
      setShowSharingWindow(false);
      setIsLoading(false);
    }

    const shareTypes = [{value:"DEMO",label:"Allow camera live view ONLY"}]
    shareTypes.push({value:"VIEWER",label:"Allow viewing of files, motion events, and live view"});
    shareTypes.push({value:"OWNER",label:"Allow sharing, deleting, and viewing of files, events, and live view"})
    const emailTooltip = (
      <Tooltip id="emailShare" style={{fontSize:"12pt"}}>
        Please enter the email address of the user with whom you're sharing this camera.<br /><br />
        This will allow them to view the camera's live feeds.<br /><br />
        If the user does not already have an account, they will receive an email to create one.
      </Tooltip>
    );
    // const liveViewTooltip = (
    //   <Tooltip id="liveViewShare" style={{fontSize:"12pt"}}>
    //     This gives the user access to live images from the CAP. Users get access to this by default
    //   </Tooltip>
    // );
    const MotionTooltip = (
      <Tooltip id="motionShare" style={{fontSize:"12pt"}}>
        This will allow the user to view and search through the camer's motion history
      </Tooltip>
    );
    const EditingTooltip = (
      <Tooltip id="editingShare" style={{fontSize:"12pt"}}>
        This will allow the user to share this camera with others.<br /><br />
        Users with sharing permissions will always have motion history permissions
      </Tooltip>
    );
    const PowerTooltip = (
      <Tooltip id="powerShare" style={{fontSize:"12pt"}}>
        This will subscribe the user to power notification emails.  
      </Tooltip>
    )

    const handleSharingCheckboxes = (event) => {
      if(event.target.name==="motion") {
        if(motionCheckBox) {
          setSharedType("DEMO");
        } else {
          setSharedType("VIEWER");
        }
        setMotionCheckBox(!motionCheckBox);
        if(editingCheckBox) {
          setEditingCheckBox(false);
        }
      } else if (event.target.name==="editing") {
        if(editingCheckBox) {
          setSharedType("VIEWER");
        } else {
          setSharedType("OWNER");
        }
        setEditingCheckBox(!editingCheckBox);
        if(!motionCheckBox) {
          setMotionCheckBox(true);
        }
      } else if (event.target.name==="power") {
        setPowerCheckBox(!powerCheckBox);
      }
    }

    return (
      <Modal
        show={show}
        onHide={() => setShowSharingWindow(false)}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName={buttonSize==="small"?"modal-dialog-phone":"modal-dialog"}
        {...props}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title" style={{fontSize:"32px"}}>
            {sharableCap == null ? "Loading..." : "Share '"+sharableCap.name+"'"}
          </Modal.Title> */}
          <Modal.Title style={{fontSize:buttonSize==="small"?"24px":"32px",fontFamily:"Open sans, sans-serif"}}>
            {sharableCap == null ? "Loading..." : sharableCap.name + (sharableCap.neighborhood ? ", "+sharableCap.neighborhood : "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontFamily:"Open sans, sans-serif"}}>
          <View style={{flex: 1, flexDirection: 'column', justifyContent: 'space-around'}}>
            <View style={{flex:1, flexDirection:'column', justifyContent: 'space-around'}}>
              <h2 style={{marginTop:"0px",fontSize:buttonSize==="small"?"22px":"28px"}}>User's Email Address&nbsp;<OverlayTrigger placement="bottom" overlay={emailTooltip}><Glyphicon glyph="question-sign" /></OverlayTrigger></h2>
              {/* <Select 
                options={allEmails} 
                onChange={e => setSharedEmail(e.value)}
                styles={{menuPortal: base => ({...base,zIndex:9999})}}
                menuPortalTarget={document.body}
              /> */}
              <View style={emailFocused ? {borderWidth:"1px",borderRadius:"4px",borderColor:"#2684FF",boxShadow:"0 0 0 1px #2684FF"} : {borderWidth:"1px",borderRadius:"4px",borderColor:"hsl(0,0%,80%)"}}>
                <TextInput 
                  style={{padding:"6px",paddingLeft:"8px",fontSize:"16px",fontFamily:"Open Sans, sans-serif", top:"50%"}}
                  onChangeText={text=>setSharedEmail(text)}
                  onFocus={() => setEmailFocused(true)}
                  onBlur={() => setEmailFocused(false)}
                />
              </View>
            </View>
            <View sytle={{paddingTop:'20px', flex:1, flexDirection:'column'}}>
              <h2 style={{zIndex:"0",fontSize:buttonSize==="small"?"22px":"28px"}}>Sharing Options</h2>
              {/* <Select
                options={shareTypes}
                onChange={e => setSharedType(e.value)}
                styles={{menuPortal: base => ({...base,zIndex:9999})}}
                menuPortalTarget={document.body}
              /> */}
              <h3 style={{paddingLeft:"10px", marginTop:"-10px"}}>
                <form>
                  <FormGroup controlId="share-select">
                    {/* <Checkbox name="liveView" checked={true} readOnly>Live View <OverlayTrigger placement="right" overlay={liveViewTooltip}><Glyphicon glyph="question-sign" /></OverlayTrigger></Checkbox> */}
                    <Checkbox name="motion" checked={motionCheckBox} onChange={handleSharingCheckboxes}>Motion History <OverlayTrigger placement="right" overlay={MotionTooltip}><Glyphicon glyph="question-sign" /></OverlayTrigger></Checkbox>
                    <Checkbox name="editing" checked={editingCheckBox} onChange={handleSharingCheckboxes}>Camera Sharing <OverlayTrigger placement="right" overlay={EditingTooltip}><Glyphicon glyph="question-sign" /></OverlayTrigger></Checkbox>
                    <Checkbox name="power" checked={powerCheckBox} onChange={handleSharingCheckboxes}>Power Notifications <OverlayTrigger placement="right" overlay={PowerTooltip}><Glyphicon glyph="question-sign" /></OverlayTrigger></Checkbox>
                  </FormGroup>
                </form>
              </h3>
            </View>
            <View style={{flex: 1, flexDirection: 'row', justifyContent:"space-between", paddingTop: '20px'}}>
              <div style={{fontSize:buttonSize==="small"?"12px":"16px"}}>
                Contact <a href="mailto: help@bedrockwireless.com">help@bedrockwireless.com</a> for help with sharing
              </div>
              <Button
                bsStyle="success"
                bsSize="large"
                disabled={sharedEmail==="" || sharedType===""}
                onClick={share}
              >
                Share
              </Button>
            </View>
          </View>
        </Modal.Body>
        {props.children}
      </Modal>
    );
  }