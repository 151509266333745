import React, {useState, useEffect, useRef} from "react";
import "./Events.css";
import { Glyphicon, Button, PageHeader, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { View } from "react-native";
import { getCap, storeCap, getUser, getUserInformation } from "../utils/Common";
import { loadCap, getCapture, getCaptureVideos, putCaptureName, recordUserAction, getEvent } from "../utils/bedrockAPI";
import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from "video-react";
import "video-react/dist/video-react.css";
import ContentEditable from "react-contenteditable";

export default function Replay360(props) {
    const [cap, setCap] = useState([]);
    const [capture, setCapture] = useState(null);
    const [motionEvent, setMotionEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [renderError, setRendorError] = useState(false);
    const [videoUrls, setVideoUrls] = useState(null);
    const [paused, setPaused] = useState(true);
    const [buttonSize, setButtonSize] = useState("large");
    var captureName = useRef("");
    var housePlayer = useRef(null);
    var neighborPlayer = useRef(null);
    var streetPlayer = useRef(null);
    var drivewayPlayer = useRef(null);
    const nameElement = useRef(null);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
                // setRenderError(true);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"replay360",props.match.params.capid,{'eventId':props.match.params.eventid,'userData':getUserInformation()});
    },[props.match.params.capid])

    useEffect(() => {
        async function accessPrimaryEvent() {
            try {
                const tempEvent = await getEvent(props.match.params.eventid);
                setMotionEvent(tempEvent);
                captureName.current = tempEvent.name;
                // console.log(tempEvent);
            } catch(e) {
                setRendorError(true);
            }
        }
        if(props.match.params.eventid) {
            accessPrimaryEvent();
        }
    },[props.match.params.eventid])

    useEffect(() => {
        async function loadEvent() {
            try {
                setIsLoading(true);
                const tempCapture = await getCapture(props.match.params.captureid);
                setCapture(tempCapture);
                if(!props.match.params.eventid) {
                    var tempMotionEvent = null;
                    for(var i=0; i<tempCapture.events.length; i++) {
                        if(tempCapture.events[i].motion) {
                            tempMotionEvent = tempCapture.events[i];
                            break;
                        }
                    }
                    if(tempMotionEvent) {
                        setMotionEvent(tempMotionEvent);
                        captureName.current = tempMotionEvent.name;
                    }
                    if(tempCapture.name) {
                        captureName.current = tempCapture.name;
                    }
                }
                setIsLoading(false);
            } catch(e) {
                console.log(e);
                setRendorError(true);
            }
        }
        loadEvent();
    },[props.match.params.captureid]);

    useEffect(() => {
        async function loadVideoUrl() {
            try {
                setIsLoading(true);
                const tempVideoUrls = await getCaptureVideos(props.match.params.captureid);
                var videos = {}
                // console.log(tempVideoUrls); 
                for(var i=0; i<tempVideoUrls.length; i++) {
                    videos[tempVideoUrls[i].monitor] = tempVideoUrls[i];
                }
                setVideoUrls(videos);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setRendorError(true);   
            }
        }
        if(capture) {
            loadVideoUrl();
        }
    },[capture])

    // useEffect(() => {
    //     console.log(videoUrls);
    // },[videoUrls]);

    var highlightAll = function(e) {
        var range = document.createRange();
        range.selectNodeContents(nameElement.current.getEl());
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    var playAll = function() {
        setPaused(false);
        if(housePlayer.current) {
            housePlayer.current.play();
        }
        if(neighborPlayer.current) {
            neighborPlayer.current.play();
        }
        if(streetPlayer.current) {
            streetPlayer.current.play();
        }
        if(drivewayPlayer.current) {
            drivewayPlayer.current.play();
        }
    }

    var pauseAll = function() {
        setPaused(true);
        if(housePlayer.current) {
            housePlayer.current.pause();
        }
        if(neighborPlayer.current) {
            neighborPlayer.current.pause();
        }
        if(streetPlayer.current) {
            streetPlayer.current.pause();
        }
        if(drivewayPlayer.current) {
            drivewayPlayer.current.pause();
        }
    }

    var rewind = function() {
        if(paused) {
            if(housePlayer.current) {
                housePlayer.current.seek(0);
            }
            if(neighborPlayer.current) {
                neighborPlayer.current.seek(0);
            }
            if(streetPlayer.current) {
                streetPlayer.current.seek(0);
            }
            if(drivewayPlayer.current) {
                drivewayPlayer.current.seek(0);
            }
        }
    }

    var housePlayerStateChange = function() {
        if(housePlayer.current.getState().player.paused) {
            if(!paused) pauseAll();
        } else {
            if(paused) playAll();
        }
    }
    var neighborPlayerStateChange = function () {
        if(neighborPlayer.current.getState().player.paused) {
            if(!paused) pauseAll();
        } else {
            if(paused) playAll();
        }
    }
    var streetPlayerStateChange = function () {
        if(streetPlayer.current.getState().player.paused) {
            if(!paused) pauseAll();
        } else {
            if(paused) playAll();
        }
    }
    var drivewayPlayerStateChange = function () {
        if(drivewayPlayer.current.getState().player.paused) {
            if(!paused) pauseAll();
        } else {
            if(paused) playAll();
        }
    }

    // Bind the video state effects to the above functions
    useEffect(() => {
        if(housePlayer.current) {
            housePlayer.current.subscribeToStateChange(housePlayerStateChange);
        }
        if(neighborPlayer.current) {
            neighborPlayer.current.subscribeToStateChange(neighborPlayerStateChange);
        }
        if(streetPlayer.current) {
            streetPlayer.current.subscribeToStateChange(streetPlayerStateChange);
        }
        if(drivewayPlayer.current) {
            drivewayPlayer.current.subscribeToStateChange(drivewayPlayerStateChange);
        }
    })

    function renderReplay() {
        var handleNameChange = async function(e) {
            const name = e.target.value.replace(/(<([^>]+)>)/ig,"");
            captureName.current = name;
        };

        var saveToServer = function(e) {
            putCaptureName(props.match.params.captureid,captureName.current);
        }
      
        var pasteAsPlainText = function(e) {
            e.preventDefault();
        
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertHTML',false,text);
        };
      
        var disableNewLines = function(e) { 
            const keyCode = e.keyCode || e.which;
    
            if(keyCode === 13) {
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
                const element = (e.currentTarget || e.srcElement);
                element.blur();
            }
        };

        function renderVideoPlayer(player,urlDirection) {
            var playerStyle = {width:"50%",maxWidth:"704px", height: undefined, aspectRatio: 1.4667, padding: "5px"}
            if(motionEvent && motionEvent.eventId===urlDirection.eventId) {
                // console.log(urlDirection,motionEvent);
                playerStyle = Object.assign({},playerStyle,{borderColor:"red",border:"solid"})
            }
            return (
                videoUrls && urlDirection && (
                    <View style={playerStyle}>
                        <Player ref={player}>
                            <source src={urlDirection.url} />
                            <BigPlayButton position="center" />
                            <ControlBar>
                                <VolumeMenuButton disabled />
                            </ControlBar>
                        </Player>       
                    </View>
                )
            );
        }

        return (
            <View style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <PageHeader style={{marginTop:"-10px"}}>
                    <View style={{flex:1, flexDirection:"row",justifyContent:'space-between'}}>
                        {/* {console.log(getUser().caps[props.match.params.capid])} */}
                        {captureName && captureName.current && (
                            <ContentEditable
                                html={captureName.current}
                                // className="ContentEditable-header"
                                disabled={isLoading || getUser().caps[props.match.params.capid].accessType!=="OWNER"}
                                onPaste={pasteAsPlainText}
                                onKeyPress={disableNewLines}
                                onFocus={highlightAll}
                                onChange={handleNameChange}
                                onBlur={saveToServer}
                                ref={nameElement}
                            />
                        )}
                    </View>
                </PageHeader>
                {capture && videoUrls && (
                    <View style={{flex: 1, flexDirection: "column", justifyContent: "center"}}>
                        <View style={{flex:1, flexDirection:"row", justifyContent: "center", alignItems: "flex-start"}}>
                            {videoUrls && videoUrls.house && renderVideoPlayer(housePlayer,videoUrls.house)}
                            {videoUrls && videoUrls.neighbor && renderVideoPlayer(neighborPlayer,videoUrls.neighbor)}
                        </View>
                        <View style={{flex:1, flexDirection:"row", justifyContent: "center", alignItems: "flex-start"}}>
                            {videoUrls && videoUrls.street && renderVideoPlayer(streetPlayer,videoUrls.street)}
                            {videoUrls && videoUrls.driveway && renderVideoPlayer(drivewayPlayer,videoUrls.driveway)}
                        </View>
                    </View>
                )}
            </View>
        )
    }

    function renderHeader() {

        // async function removeEvent() {
        //     setIsLoading(true);
        //     if(window.confirm(`Are you sure you wish to delete ${event.name}?`)) {
        //         await deleteCaptureVideo(props.match.params.captureid);
        //         props.history.push(`/caps/${cap.capId}/events`)
        //     }
        //     setIsLoading(false);
        // }

        // const deleteTooltip = (
        //     <Tooltip id="deleteEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
        //         Delete Event
        //     </Tooltip>
        // );
        // const editTooltip = (
        //     <Tooltip id="editEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
        //         Edit Event Name
        //     </Tooltip>
        // );
        const playTooltip = (
            <Tooltip id="playEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Play
            </Tooltip>
        );
        const pauseTooltip = (
            <Tooltip id="pauseEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Pause
            </Tooltip>
        );
        const restartTooltip = (
            <Tooltip id="restartEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Start from Beginning
            </Tooltip>
        );
        const refreshTooltip = (
            <Tooltip id="refreshEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Reload the page
            </Tooltip>
        );
        const snapshotTooltip = (
            <Tooltip id="snapshot" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                High Res Snapshot
            </Tooltip>
        );
        const eventTooltip = (
            <Tooltip id="motionEvent" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Review Motion Event
            </Tooltip>
        );

        return (
            <div>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between',padding:10}}>
                    <Button
                        bsStyle="primary"
                        bsSize={buttonSize}
                        // disabled={isLoading}
                        onClick={() => props.history.push(`/caps/${cap.capId}/events`)}
                    >
                        Event Dashboard
                    </Button>
                    <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                        <OverlayTrigger placement="bottom" overlay={refreshTooltip}>
                            <Button
                                bsStyle="info"
                                bsSize={buttonSize}
                                // disabled={isLoading}
                                onClick={() => window.location.reload(false)}
                            >
                                <Glyphicon glyph="refresh"></Glyphicon>
                            </Button>
                        </OverlayTrigger>
                        {capture && capture.events && (
                            <ButtonGroup style={{paddingRight: "5px"}}>
                                <OverlayTrigger placement="bottom" overlay={restartTooltip}>
                                    <Button
                                        bsStyle="info"
                                        bsSize={buttonSize}
                                        disabled={!paused}
                                        onClick={rewind}
                                    >
                                        <Glyphicon glyph="step-backward" />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={paused ? playTooltip : pauseTooltip}>
                                    <Button
                                        bsStyle="info"
                                        bsSize={buttonSize}
                                        onClick={paused ? playAll : pauseAll}
                                    >
                                        <Glyphicon glyph={paused ? "play" : "pause"} />
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                        )}
                        {getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                            <React.Fragment>
                                {/* <OverlayTrigger placement="bottom" overlay={deleteTooltip}>
                                    <View style={{paddingRight: "5px"}}>
                                        <Button
                                            bsStyle="danger"
                                            bsSize={buttonSize}
                                            onClick={() => removeEvent()}
                                        >
                                            <Glyphicon glyph="remove" />
                                        </Button>
                                    </View>
                                </OverlayTrigger> */}
                                {/* <OverlayTrigger placement="bottom" overlay={editTooltip}>
                                    <View style={{paddingRight: "5px"}}>
                                        <Button
                                            bsStyle="success"
                                            bsSize={buttonSize}
                                            onClick={() => highlightAll()}
                                        >
                                            <Glyphicon glyph="edit" />
                                        </Button>
                                    </View>
                                </OverlayTrigger> */}
                                {motionEvent && motionEvent.eventId && (
                                    <OverlayTrigger placement="bottom" overlay={eventTooltip}>
                                        <View style={{paddingRight:"5px"}}>
                                            <Button
                                                bsStyle="primary"
                                                bsSize={buttonSize}
                                                onClick={() => props.history.push(`/caps/${cap.capId}/events/${motionEvent.eventId}`)}
                                            >
                                                <Glyphicon glyph="film" />
                                            </Button>
                                        </View>
                                    </OverlayTrigger>
                                )}
                                {capture && capture.events && capture.events[0].imageId && (
                                    <View style={{paddingRight: "5px"}}>
                                        <OverlayTrigger placement="bottom" overlay={snapshotTooltip}>
                                            <Button
                                                bsStyle="warning"
                                                bsSize={buttonSize}
                                                onClick={() => props.history.push(
                                                    motionEvent ? `/caps/${cap.capId}/events/${motionEvent.eventId}/snapshot` : 
                                                    `/caps/${cap.capId}/360events/${props.match.params.captureid}/snapshot`
                                                )}
                                            >
                                                <Glyphicon glyph="picture" />
                                            </Button>
                                        </OverlayTrigger>
                                    </View>
                                )}
                            </React.Fragment>
                        )}
                    </View>
                </View>
                {renderReplay()}
            </div>
        )
    }

    function renderNoEvent() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Unable to locate event, please refresh</h3>
            </div>
        )
    }

    return (
        <div className="Replay">
            {renderError ? renderNoEvent() : renderHeader()}
        </div>
    )
}