import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common'

// handles routes for logged in users
function PrivateRoute({ component: Component, appProps, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => getToken() ? <Component {...props} {...appProps} /> 
            : <Redirect to={
                props.location.pathname==="/signupnewcap" ? {
                    pathname: '/login', state: { from: props.location, signupnewcap: true}
                } :
                { pathname: '/login', state: { from: props.location } }
            } />}
        />
    )
}

export default PrivateRoute;