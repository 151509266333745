import React, { useEffect, useState } from "react";
import { PageHeader } from "react-bootstrap";
import { View } from "react-native";
import { firefoxBrowser, jumpToInstructions } from "../../utils/Common";
import Chrome from "../../images/chrome.png";
import NotificationsToggle from "../../images/notificatinos-toggle.jpg";
import AllowedNotifications from "../../images/allowed-notifications.png";
import Tuning from "../../images/tune-icon.jpg";
import AllowedNotificationsFirefox from "../../images/allow-notifications-firefox.jpg";
import Firefox from "../../images/firefox.png";
import FirefoxNotifications from "../../images/firefox-notifications.png";

export default function EnableDesktopNotifications() {
    const [isFirefox, setIsFirefox] = useState(false);

    useEffect(() => {
        if(firefoxBrowser()) {
            setIsFirefox(true); // DO I NEED FOR DESKTOP?
        }
    },[]);

    return (
        <View>
            <PageHeader>How to Enable Desktop Notifications in Your Browser</PageHeader>
            <View>
                {jumpToInstructions(isFirefox,"firefox","desktop")}
                <h2 id="chrome">
                    Google Chrome
                </h2>
                <h3>
                    <ol>
                        <li className="helpList">
                            Navigate to our web app at <a href="https://data.bedrockwireless.com">data.bedrockwireless.com</a> in your chrome browser<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={Chrome} alt="Chrome Browser" />
                            </div>
                        </li>
                        <li className="helpList">
                            You may be prompted to allow notifications automatically, in which case you can allow<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={AllowedNotifications} alt="Allow Notifications" />
                            </div>
                        </li>
                        <li className="helpList">
                            If not, you can click the tuning button for site specific settings<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={Tuning} alt="Tune Site Settings" />
                            </div>
                        </li>
                        <li className="helpList">
                            Then, toggle the 'Notifications' switch to enabled<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={NotificationsToggle} alt="Notifications Toggle" />
                            </div>
                        </li>
                    </ol>
                </h3>
                <h2 id="firefox">
                    Firefox
                </h2>
                <h3>
                    <ol>
                        <li className="helpList">
                            Navigate to our web app at <a href="https://data.bedrockwireless.com">data.bedrockwireless.com</a> in your firefox browser<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={Firefox} alt="Chrome Browser" />
                            </div>
                        </li>
                        <li className="helpList">
                            You may be prompted to allow notifications automatically, in which case you can allow<br /><br />
                            <div className="center">
                                <img style={{width:"80%"}} src={AllowedNotificationsFirefox} alt="Allow Notifications" />
                            </div>
                        </li>
                        <li className="helpList">
                            If not, you can click the notifications button to enable<br /><br />
                            <div className="center">
                                <img style={{width:"100px"}} src={FirefoxNotifications} alt="Tune Site Settings" />
                            </div>
                        </li>
                    </ol>
                </h3>
            </View>
        </View>
    )
}