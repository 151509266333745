import React, {useState, useEffect} from "react";
import { 
    ControlLabel, 
    FormControl, 
    FormGroup, 
    PageHeader, 
    Button, 

} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {
    getUser, 
    getToken,
    setUserSession,
    mobileCheck,
    retrieveFCMToken
} from "../utils/Common";
import {
    putUser, 
    getUserMe
} from "../utils/bedrockAPI";
import { View } from "react-native";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function AccountInfo(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [pwmApp,setPwmApp] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    const [fixingUser, setFixingUser] = useState(false);
    const [fcmToken, setFcmToken] = useState("");

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            console.log("This is running as standalone.");
            setPwmApp(true);
        }
        const user = getUser();
        if(user) {
            if(user.firstName!=null) setFirstName(user.firstName);
            if(user.lastName!=null) setLastName(user.lastName);
            if(user.email!=null) setEmail(user.email);
            if(user.company!=null) setCompany(user.company);
            if(user.phone!=null) setPhone(user.phone);
        }
        props.setShowError(false);
        if(!user.firstName||user.firstName==="") {
            props.setErrorStatus("success-message");
            props.setErrorMessage("Please update your name in our records");
            props.setShowError(true);
            setFixingUser(true);
        }

        if(window.innerWidth<767) {
            setButtonSize("small");
        }

        const t = retrieveFCMToken();
        console.log(t);
        setFcmToken(t);
    },[])

    async function handleChangeAccountInfo(e) {
        e.preventDefault();
        if(window.confirm('Are you sure the information looks correct?')) {
            setIsLoading(true);
            let changes = getUser();
            changes.email = email;
            changes.phone = phone;
            changes.firstName = firstName;
            changes.lastName = lastName;
            changes.company = company;
            delete(changes.caps);
            delete(changes.userId);
            delete(changes.role);
            delete(changes.address);
            delete(changes.city);
            delete(changes.zip);
            delete(changes.state);
            delete(changes.settings);
            await putUser(getUser().userId,changes);
            const newUser = await getUserMe();
            setUserSession(getToken(),newUser);
            setIsLoading(false);
            props.setShowError(false);
            if(fixingUser) {
                props.history.push('/dashboard');
            }
        }
    }

    return (
        <View>
            <PageHeader style={{textAlign:"center"}}>
                <div style={{fontSize:buttonSize==="small"?"24px":"36px"}}>
                    Account Information
                </div>
            </PageHeader>
            {getUser().role==="ADMIN" && (  // ADMIN PERMANENT -- access tokens
                <View style={{flex:1,flexDirection:"column"}}>
                    <View style={{flex:1, flexDirection:"row"}}>
                        {/* {console.log(getToken())} */}
                        <CopyToClipboard
                            text={getToken()}
                        >
                            <Button
                                bsSize="large"
                                bsStyle="default"
                            >AccessToken</Button>
                        </CopyToClipboard>  
                    </View>
                    <View style={{paddingTop:"10px"}}>
                        <FormGroup controlId="fcm" bsSize="large">
                            <FormControl
                                value={fcmToken}
                                disabled
                            />
                        </FormGroup>
                    </View>
                </View>
            )}
            <form className="info" onSubmit={(e) => handleChangeAccountInfo(e)}>
                <View style={{flex:1, flexDirection:"column"}}>
                    <View style={{flex:1, flexDirection:"row"}}>
                        <View style={{flex:1, paddingRight: "5px"}}>
                            <FormGroup controlId="firstName" bsSize="large">
                                <ControlLabel>First Name</ControlLabel>
                                <FormControl
                                    autoFocus={pwmApp ? false : true}
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1, paddingLeft:"5px"}}>
                            <FormGroup controlId="lastName" bsSize="large">
                                <ControlLabel>Last Name</ControlLabel>
                                <FormControl
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection:"row"}}>
                        <View style={{flex:1, paddingRight:"5px"}}>
                            <FormGroup controlId="company" bsSize="large">
                                <ControlLabel>Company Name</ControlLabel>
                                <FormControl
                                    value={company}
                                    onChange={e => setCompany(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1, paddingLeft: "5px"}}>
                            <FormGroup controlId="phone" bsSize="large">
                                <ControlLabel>Phone Number</ControlLabel>
                                <FormControl
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection:"row"}}>
                        <View style={{flex:1, paddingRight: "5px"}}>
                            <FormGroup controlId="email" bsSize="large">
                                <ControlLabel>Account Email</ControlLabel>
                                <FormControl
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                </View>
                <View style={{flex:1, flexDirection:"row", justifyContent:"center", paddingTop:"20px"}}>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        style={{maxWidth:"320px"}}
                        // disabled={!validateForm()}
                    >
                        Save
                    </LoaderButton>
                </View>
            </form>
        </View>
    )
}