import React, {useState, useEffect} from "react";
import {
    Nav,
    NavItem,
    Tab,
    TabContainer,
} from "react-bootstrap";
import {getUser, mobileCheck} from "../utils/Common";
import { View } from "react-native";
import "./Account.css";
import MasterNotifications from "./MasterNotifications";
import PowerNotifications from "./PowerNotifications";
import MotionNotifications from "./MotionNotifications";
import { getSharedCaps, patchUser } from "../utils/bedrockAPI";

export default function Notifications(props) {
    const [buttonSize, setButtonSize] = useState("large");
    const [notifyTab, setNotifyTab] = useState("power");
    const [sharedCaps, setSharedCaps] = useState(null);
    const [demoOnly, setDemoOnly] = useState(true);
    const [appNotifications, setAppNotifications] = useState(false);

    useEffect(() => {
        let isMounted=true;
        if(window.innerWidth<767) {
            setButtonSize("small");
        }

        async function fetchSharedCaps(userId) {
            const tempSharedCaps = await getSharedCaps(userId);
            if(isMounted)
                setSharedCaps(tempSharedCaps);
        }
        fetchSharedCaps(getUser().userId);

        return () => { isMounted = false; };
    },[]);

    useEffect(() => {
        let t = props.match.params.tab
        if(t && ["power","motion"].includes(t)) {
            setNotifyTab(t);
        } else if(t==="nudge") {
            const u = getUser();
            const m = mobileCheck();
            if(u.settings.nudge) {
                if(m && u.settings.nudge.appNotifications && u.settings.nudge.appNotifications.fibonacci) {
                    let update = JSON.parse(JSON.stringify(u.settings));
                    update.nudge.appNotifications.fibonacci += 5;
                    patchUser(u.userId,{settings:update});
                } else if(!m && u.settings.nudge.desktopNotifications && u.settings.nudge.desktopNotifications.fibonacci) {
                    let update = JSON.parse(JSON.stringify(u.settings));
                    update.nudge.desktopNotifications.fibonacci += 5;
                    patchUser(u.userId,{settings:update});
                }
            }
        }
    },[props.match.params.tab]);

    useEffect(() => {
        if(sharedCaps) {
            let tempDemoOnly = true;
            if(getUser().role==='ADMIN') {
                tempDemoOnly = false;
            } else {
                for(var i=0; i<sharedCaps.length; i++) {
                    if(['VIEWER','OWNER'].includes(sharedCaps[i].accessType)) {
                        tempDemoOnly = false;
                        break;
                    }
                }
            }
            setDemoOnly(tempDemoOnly);
        }
    },[sharedCaps]);

    // useEffect(() => {
    //     if(notificationsMenuKey) {
    //         window.history.replaceState(null,"New Page Title","/account/notifications/"+notificationsMenuKey);
    //     }
    // },[notificationsMenuKey])
    
    return (
        <View>
            <MasterNotifications setAppNotificationsParent={setAppNotifications} />
            <TabContainer id="notifications" activeKey={notifyTab} onSelect={k => setNotifyTab(k)}>
                <View>
                    <Nav bsStyle="tabs" >
                        <NavItem eventKey="power">
                            <b>{buttonSize==="small" ? "Power" : "Power Notifications"}</b>
                        </NavItem>
                        {!demoOnly && ( // UNDO THIS TO ENABLE MOTION 
                            <NavItem eventKey="motion">
                                <b>{buttonSize==="small" ? "Motion" : "Motion Notifications"}</b>
                            </NavItem>
                        )}
                    </Nav>
                    <Tab.Content style={{borderLeft:"1px solid #ddd",borderRight:"1px solid #ddd",borderRadius:"0 0 4px 4px",padding:"10px"}}>
                        <Tab.Pane eventKey="power">
                            <PowerNotifications appNotificationsFromMaster={appNotifications} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="motion">
                            <MotionNotifications />
                        </Tab.Pane>
                    </Tab.Content>
                </View>
            </TabContainer>
        </View>
    )
}