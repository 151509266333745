import React, {useEffect, useState} from "react";
import { Modal, Button, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { View } from "react-native";
import { createBuilder } from "../utils/bedrockAPI";

export default function AddBuilderModal({show,setShowAddBuilder,...props}) {
    const [name, setName] = useState("");
    const [address,setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [stripe, setStripe] = useState("");

    useEffect(() => {
        setName("");
        setAddress("");
        setCity("");
        setState("");
        setZip("");
        setStripe("");
    },[show]);

    async function createNewBuilder(e) {
        e.preventDefault();
        const newBuilder = {
            name: name,
            businessAddress: address,
            businessCity: city,
            businessState: state,
            businessZip: zip,
            stripeId: stripe==null || stripe==="" ? null : stripe
        }
        await createBuilder(newBuilder);
        setShowAddBuilder(false);
    }

    function validateForm() {
        return !(
            name &&
            name.length > 0 &&
            address &&
            address.length > 0 &&
            city && city.length > 0 &&
            state && state.length > 0 &&
            zip && zip.length > 0
        );
    }

    return (
        <Modal
            show={show}
            onHide={() => setShowAddBuilder(false)}
            container={this}
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Builder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => {createNewBuilder(e)}}>
                    <View style={{flex:1,flexDirection:"row"}}>
                        <View style={{flex:1,paddingRight:"5px"}}>
                            <FormGroup controlId="address" bsSize="large">
                                <ControlLabel>Business Name</ControlLabel>
                                <FormControl
                                    autoFocus
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1,paddingLeft:"5px"}}>
                            <FormGroup controlId="city" bsSize="large">
                                <ControlLabel>Stripe ID</ControlLabel>
                                <FormControl
                                    value={stripe}
                                    onChange={e => setStripe(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                    <View style={{flex:1,flexDirection:"row"}}>
                        <View style={{flex:1,paddingRight:"5px"}}>
                            <FormGroup controlId="address" bsSize="large">
                                <ControlLabel>Street Address</ControlLabel>
                                <FormControl
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1,paddingLeft:"5px"}}>
                            <FormGroup controlId="city" bsSize="large">
                                <ControlLabel>City</ControlLabel>
                                <FormControl
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection:"row",justifyContent:"space-evenly"}}>
                        <View style={{flex:1, paddingRight: "5px"}}>
                            <FormGroup controlId="state" bsSize="large">
                                <ControlLabel>State</ControlLabel>
                                <FormControl
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1, paddingLeft:"5px"}}>
                            <FormGroup controlId="zip" bsSize="large">
                                <ControlLabel>Zip</ControlLabel>
                                <FormControl
                                    value={zip}
                                    onChange={e => setZip(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection:"row", justifyContent:"flex-end", paddingTop:"20px"}}>
                        <Button
                            type="submit"
                            bsSize="large"
                            disabled={validateForm()}
                        >
                            Save
                        </Button>
                    </View>
                </form>
            </Modal.Body>
            {props.children}
        </Modal>

    )
}