// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from "firebase/messaging";
import { updateUserSubscription } from "./utils/bedrockAPI";
import { setUserSession, mobileCheck } from "./utils/Common";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkdiAecbO8mzWUdbvuOqCccJ7XOE7N6G0",
  authDomain: "bedrockwireless.firebaseapp.com",
  projectId: "bedrockwireless",
  storageBucket: "bedrockwireless.appspot.com",
  messagingSenderId: "661275018508",
  appId: "1:661275018508:web:a7c8a58af6b2c407eeb529",
  measurementId: "G-0DYVBEQ84V"
};

const publicKey = 'BD9KGGyTMxsogzu3k-1YzAAylOPnkZdmMMNfwCqkRoMe33XRGVoHe6HKsRrTB48VaDB0iHWUg5RQkpGAsEP1b-U';

initializeApp(firebaseConfig);

const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging();
        }
        console.log('Firebase not supported this browser');
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
})();

export const getFCMToken = async (user,bedrockToken,subscribe=true,desktop=false) => {
    try {
        const messagingResolve = await messaging;
        if(subscribe) {
            return await getToken(messagingResolve, {
                vapidKey: publicKey,
            }).then((currentToken) => {
                if (currentToken) {
                    // console.log(currentToken);
                    let userCopy = JSON.parse(JSON.stringify(user));
                    if(!userCopy.settings) {
                        userCopy.settings = {};
                    }
                    if(desktop) {
                        updateUserSubscription(user.userId,{fcmToken:currentToken,mobile:mobileCheck()},'Yes',true);
                        userCopy.settings.desktopNotifications = {fcmToken:currentToken,mobile:mobileCheck()};
                    } else {
                        updateUserSubscription(user.userId,{fcmToken:currentToken,mobile:mobileCheck()});
                        userCopy.settings.appNotifications = {fcmToken:currentToken,mobile:mobileCheck()};
                    }
                    setUserSession(bedrockToken,userCopy);
                    return currentToken;
                } else {
                    // Show permission request UI
                    console.log("No registration token available. Probably hasn't authorized yet.");
                }
            }).catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
            });
        } else {
            getToken(messagingResolve).then((currentToken) => {
                deleteToken(messagingResolve).then(() => {
                    console.log("Firebase token deleted",currentToken);
                    let userCopy = JSON.parse(JSON.stringify(user));
                    if(desktop) {
                        updateUserSubscription(user.userId,{fcmToken:currentToken,mobile:mobileCheck()},null,true);
                        userCopy.settings.desktopNotifications = null;
                    } else {
                        updateUserSubscription(user.userId,{fcmToken:currentToken,mobile:mobileCheck()},null);
                        userCopy.settings.appNotifications = null;
                    }
                    setUserSession(bedrockToken,userCopy);
                }).catch((err) => {
                    console.log("Unable to remove FCM token",err);
                });
            }).catch((err) => {
                console.log("Error retrieving current token for removal",err);
            });
        }
    } catch (err) {
        console.log("Can't get token?",err);
    }
}
    
export const onMessageListener = async () => new Promise((resolve) => 
    (async () => {
        const messagingResolve = await messaging;
        if(messagingResolve) {
            onMessage(messagingResolve,(payload) => {
                console.log(payload);
                resolve(payload);
            });
        }
    })()
);