import React, { useEffect, useState } from "react";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    PageHeader,
    Collapse,
    Well
} from "react-bootstrap";
import "./Login.css";
import LoaderButton from "../components/LoaderButton";
import { resetPasswordInit, resetPasswordFinish } from "../utils/bedrockAPI";

export default function PasswordReset(props) {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [resetKey, setResetKey] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfirm, setNewConfirm] = useState("");
    const [passwordRequirements, setPasswordRequirements] = useState("");

    useEffect(() => {
        if(props.match.params.resetkey) {
            if(props.match.params.resetkey.length===36) {
                setResetKey(props.match.params.resetkey);
            } else {
                props.setErrorMessage("Password Rest Link is invalid or expired, please request a new one");
                props.setErrorStatus("error-message");
                props.setShowError(true);
            }
        }
    },[props.match.params.resetkey]);

    useEffect(() => {
        if(newPassword.length > 0 && newPassword.length < 8 && newConfirm===newPassword) {
            setPasswordRequirements("Password must be at least 8 characters");
        } else if(newPassword.length > 7 && newConfirm===newPassword) {
            setPasswordRequirements("");
        } else if(newPassword.length > 7 && newConfirm!==password) {
            setPasswordRequirements("Please make sure your passwords match");
        }
    },[newPassword,newConfirm]);

    async function handlePasswordReset(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            await resetPasswordInit(email);
        } catch(e) {
            console.log(e.response);
            props.setErrorMessage(e.response.data.error.message);
            props.setErrorStatus("error-message");
            props.setShowError(true);
            setIsLoading(false);
            return;
        }
        props.setErrorMessage("We sent a reset link to your email, please check it");
        props.setErrorStatus("success-message");
        props.setShowError(true);
        setIsLoading(false);
    }

    async function handlePasswordChange(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            await resetPasswordFinish({password:newPassword,resetKey:resetKey});
        } catch(e) {
            console.log(e.response);
            props.setErrorMessage(e.response.data.error.message);
            props.setErrorStatus("error-message");
            props.setShowError(true);
            setResetKey("");
            setIsLoading(false);
            return;
        }
        props.setErrorMessage("Password Changed, please login");
        props.setErrorStatus("success-message");
        props.setShowError(true);
        setIsLoading(false);
        props.history.push('/login');
    }

    function validateForm() {
        return email && email.length > 0
    }

    function validatePassword() {
        return (
            newPassword && newPassword.length > 7 &&
            newConfirm && newConfirm === newPassword
        )
    }

    return resetKey && resetKey.length===36 ? (
        <div className="Login">
            <PageHeader className="password">
                Reset Your Password
            </PageHeader>
            <div style={{paddingTop:"15px"}}>
                <form onSubmit={handlePasswordChange}>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            autoFocus
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlId="confirmPassword" bsSize="large">
                        <ControlLabel>Confirm Password</ControlLabel>
                        <FormControl
                            type="password"
                            value={newConfirm}
                            onChange={e => setNewConfirm(e.target.value)}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        disabled={!validatePassword()}
                    >
                        Update Password
                    </LoaderButton>
                    <Collapse in={passwordRequirements.length>0}>
                        <Well style={{backgroundColor:"#FF7272", fontWeight:"bold",color:"white"}}>
                            {passwordRequirements}
                        </Well>
                    </Collapse>
                </form>
            </div>
        </div>
    ) : (
        <div className="Login">
            <PageHeader className="password">
                Reset Your Password
            </PageHeader>
            <div style={{paddingTop:"15px"}}>
                <form onSubmit={handlePasswordReset}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Reset Password
                    </LoaderButton>
                </form>
            </div>
        </div>
    )
}