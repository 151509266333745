import React, {useState, useEffect} from "react";
import { 
    ControlLabel, 
    FormControl, 
    FormGroup, 
    PageHeader, 
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {
    getUser, 
    getToken
} from "../utils/Common";
import {
    putUser, 
    getUserMe
} from "../utils/bedrockAPI";
import { View } from "react-native";

export default function renderBillingInfo(props) {
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [zip, setZip] = useState("");
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        const user = getUser();
        if(user) {
            if(user.state!=null) setAddressState(user.state);
            if(user.city!=null) setCity(user.city);
            if(user.zip!=null) setZip(user.zip);
            if(user.address!=null) setAddress(user.address);
        }
    },[]);

    async function handleChangeAccountInfo() {
        if(window.confirm('Are you sure the information looks correct?')) {
            setIsLoading(true);
            let changes = getUser();
            changes.state = addressState;
            changes.address = address;
            changes.city = city;
            changes.zip = zip;
            delete(changes.email);
            delete(changes.firstName);
            delete(changes.lastName);
            delete(changes.phone);
            delete(changes.company);
            delete(changes.caps);
            delete(changes.userId);
            delete(changes.role);
            await putUser(getUser().userId,changes);
            const newUser = await getUserMe();
            setUserSession(getToken(),newUser);
            setIsLoading(false);
            props.setShowError(false);
            if(fixingUser) {
                props.history.push('/dashboard');
            }
        }
    }

    return (
        <View>
            <PageHeader style={{textAlign:"center"}}>
                Billing Information
            </PageHeader>
            <form className="info" onSubmit={handleChangeAccountInfo}>
                <View style={{flex:1,flexDirection:"column"}}>
                    <View style={{flex:1,flexDirection:"row"}}>
                        <View style={{flex:1,paddingRight:"5px"}}>
                            <FormGroup controlId="address" bsSize="large">
                                <ControlLabel>Street Address</ControlLabel>
                                <FormControl
                                    autoFocus
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1,paddingLeft:"5px"}}>
                            <FormGroup controlId="city" bsSize="large">
                                <ControlLabel>City</ControlLabel>
                                <FormControl
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                    <View style={{flex:1, flexDirection:"row",justifyContent:"space-evenly"}}>
                        <View style={{flex:1, paddingRight: "5px"}}>
                            <FormGroup controlId="state" bsSize="large">
                                <ControlLabel>State</ControlLabel>
                                <FormControl
                                    value={addressState}
                                    onChange={e => setAddressState(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                        <View style={{flex:1, paddingLeft:"5px"}}>
                            <FormGroup controlId="zip" bsSize="large">
                                <ControlLabel>Zip</ControlLabel>
                                <FormControl
                                    value={zip}
                                    onChange={e => setZip(e.target.value)}
                                />
                            </FormGroup>
                        </View>
                    </View>
                </View>
                <View style={{flex:1, flexDirection:"row", justifyContent:"center", paddingTop:"20px"}}>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        style={{maxWidth:"320px"}}
                        // disabled={!validateForm()}
                    >
                        Save
                    </LoaderButton>
                </View>
            </form>
        </View>
    );
}