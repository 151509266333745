import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
// import { View, Text } from 'react-native'
import { LinkContainer } from 'react-router-bootstrap';
import { getFCMToken, onMessageListener } from '../Firebase';
import { getUser, getToken, mobileCheck, setFCMToken } from '../utils/Common';
import { v4 as uuidv4 } from 'uuid';
import "./notification.css";

export default function Notification() {
    const [notification, setNotification] = useState({title:'',body:'',link:'',icon:'',data:null});
    const [mobile, setMobile] = useState(false);
    const [activeToasts, setActiveToasts] = useState(null);

    useEffect(() => {
        if(window.innerWidth<767) {
            setMobile(true);
        }

        async function fcmHandler() {
            let u = getUser();
            if(u && u.settings) {
                let t;
                if(mobileCheck() && u.settings.appNotifications) {
                    t = await getFCMToken(u,getToken(),'Yes');
                } else if(!mobileCheck() && u.settings.desktopNotifications) {
                    t = await getFCMToken(u,getToken(),'Yes',true);
                }
                if(t) {
                    setFCMToken(t);
                }
            }
        }
        fcmHandler();

    },[]);

    useEffect(() => {
        if(notification?.title) {
            const baseUrl = "https://data.bedrockwireless.com";
            const devUrl = "http://localhost:3000"
            let link;
            if(notification.link) {
                if(notification.link.includes(baseUrl)) {
                    link = notification.link.split(baseUrl)[1];
                } else if(notification.link.includes(devUrl)) {
                    link = notification.link.split(devUrl)[1];
                }
            }
            if(!link) {
                link = '/dashboard';
            }
            let tempToastId = notification.data && notification.data.tag ? notification.data.tag : uuidv4();
            if(activeToasts) {
                setActiveToasts([activeToasts,...tempToastId]);
            } else {
                setActiveToasts([tempToastId]);
            }
            async function dismiss(tId) {
                if(activeToasts) {
                    for(var i=0; i<activeToasts.length; i++) {
                        if(activeToasts[i]===tId) {
                            let tempActiveToasts = [...activeToasts];
                            tempActiveToasts.splice(i,1);
                            setActiveToasts(tempActiveToasts);
                        }
                    }
                }
                toast.dismiss(tId);
            }
            // This, in theory, preloads the image, so that the toast's calculation of height
            // includes the image height
            const IconImg = function() {
                return (
                    <img src={notification.icon} alt={'../../public/android-chrome-512x512.png'} />
                );
            }
            toast((t) => (
                <span style={{flex:1,flexDirection:"column",paddingLeft:"0px"}}>
                    <LinkContainer to={link} onClick={() => dismiss(t.id)}>
                        <p className={mobile ? "notification-text-mobile-title" : "notification-text-title"}>
                            <b>{notification?.title.length>24 ? notification?.title.substring(0,24)+"..." : notification.title}</b>
                        </p>
                    </LinkContainer>
                    <LinkContainer to={link} onClick={() => dismiss(t.id)}>
                        <p role="status" className={mobile ? "notification-text-mobile" : "notification-text"}>
                            {notification.body}
                        </p>
                    </LinkContainer>
                    {!mobile && (
                        <LinkContainer to='/account/notifications' onClick={() => dismiss(t.id)}>
                            <p className="notification-tiny">
                                (Click here to change your notification settings)
                            </p>
                        </LinkContainer>
                    )}
                    {notification.icon && (
                        <LinkContainer to={link} onClick={() => dismiss(t.id)}>
                            <p>
                                <div className={mobile ? "notification-img-mobile" : "notification-img"}>
                                    <div className="img-img">
                                        <IconImg />
                                    </div>
                                </div>
                            </p>
                        </LinkContainer>
                    )}
                    <button
                        className="close-button"
                        type="button"
                        onClick={() => {
                            dismiss(t.id); 
                        }}
                    >x</button>
                </span>
            ),{
                icon: notification?.title.startsWith("Power Restored") ? '⚡' : 
                    notification?.title.startsWith("Power Loss") ? '🔌' : 
                    notification?.body.startsWith("A Person") ? '🙋' :
                    notification?.body.startsWith("A Vehicle") ? '🚗' :
                    notification?.body.startsWith("You have") ? '🔔' :
                    '⏰',
                duration: 15000,
                style: mobile? { 
                    maxWidth: "300px",
                    width:"100%",
                    fontSize:"24px",
                    lineHeight:"24px",
                    background:"#4178C6",
                    color:"#EEEEEE",
                    alignItems:"flex-start",
                } : {
                    maxWidth:"600px",
                    width:"100%",
                    fontSize:"36px",
                    lineHeight:"24px",
                    background:"#4178C6",
                    color:"#EEEEEE",
                    alignItems:"flex-start",
                },
                id: tempToastId
            });
        }
    },[notification]);

    try {
        onMessageListener().then((payload) => {
            const body = payload?.notification?.body;
            const data = payload?.data;
            const link = payload?.fcmOptions?.link;
            const user = getUser();
            let icon = null;
            if(payload.notification.icon && payload.notification.icon.includes('thumb.jpg')) {
                icon = payload?.notification?.icon;
            }
            if(data.tag && activeToasts) {
                for(var i=0; i<activeToasts.length; i++) {
                    if(activeToasts[i]===data.tag) {
                        toast.dismiss(data.tag);
                    }
                }
            }
            // The rare case that someone else logs in on the same device
            if(user.userId===data.userId) {
                setNotification({
                    title:payload?.notification?.title,
                    body:body,
                    link:link,
                    icon:icon,
                    data:data
                });
            }
            // if(navigator.setAppBadge) {
            //     navigator.setAppBadge(1);
            // }
        }).catch((err) => {
            console.log('Failed to Grab Notification:',err);
        });
    } catch (err) {
        console.log("Messaging not supported on this browser");
    }
    

    return (
        <Toaster />
    )
}