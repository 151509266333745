
import React, { Component } from "react";

export default class BoxLabel extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    console.log("Change Box Label " + this.props.box.id);
    this.props.changeLabel(this.props.box.id);
  }

  render() {
    var boxLabelStyle = {backgroundColor:this.props.box.label.labelColor,color:this.props.box.label.textColor};
    if(this.props.box.label.name.length > 6) {
      if(this.props.box.label.name.length > 12) {
        boxLabelStyle.fontSize = "12px";
      }
    }
    return (
      <div className="BoxLabel" style={boxLabelStyle} onClick={this.clickHandler}>
        {this.props.showTextLabels ? (this.props.isDrawing ? "" : this.props.box.label.name) : ""}
      </div>
    );
  }
}