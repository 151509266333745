import React, {useState, useEffect} from "react";
import { View } from "react-native";
import { Button, PageHeader, Glyphicon, ButtonGroup } from "react-bootstrap";
import { loadCap, getCapture, getCaptureFrameImages, getFrames, recordUserAction } from "../utils/bedrockAPI";
import { getCap, storeCap, reformatDate, useKeyPress, getUserInformation, getUser, renderAccessDenied } from "../utils/Common";
import "./FrameHistory.css";

export default function FrameHistory(props) {
    const [buttonSize, setButtonSize] = useState("large");
    const [isLoading, setIsLoading] = useState(true);
    const [cap, setCap] = useState(null);
    const [capture, setCapture] = useState(null);
    const [captureImages, setCaptureImages] = useState(null);
    const [nextCapture, setNextCapture] = useState(null);
    const [prevCapture, setPrevCapture] = useState(null);
    const leftPress = useKeyPress("ArrowLeft");
    const rightPress = useKeyPress("ArrowRight");

    // 1 - Screen size
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    useEffect(() => {
        let isMounted = true;
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    if(isMounted)
                        storeCap(tempCap);                    
                }
                if(isMounted) {
                    setCap(tempCap);
                    setIsLoading(false);
                }
            } catch(e) {
                console.log(e);
            }
        }
        if(props.match.params.capid) {
            accessCap();
            recordUserAction(getUser().userId,"frameHistory",props.match.params.capid,{'captureId':props.match.params.captureid,'userData':getUserInformation()});
        }
        return () => { isMounted = false; };
    },[props.match.params.capid]);

    useEffect(() => {
        let isMounted = true;
        async function accessCapture() {
            try {
                var tempCapture = await getCapture(props.match.params.captureid);
                if(isMounted)
                    setCapture(tempCapture);
            } catch(e) {
                console.log(e);
            }
        }
        setIsLoading(true);
        accessCapture();
        if(isMounted)
            setIsLoading(false);
        return () => { isMounted = false; };
    },[props.match.params.captureid]);

    // async function getNextCaptureData(newCapture) {
    //     const nextFrames = await getFrames(cap.capId,0,"captureDate ASC",[],new Date(newCapture.captureDate),null,1);
    //     if(nextFrames.length>1) {
    //         return await getCapture(nextFrames[1].captureId);
    //     } else {
    //         return null;
    //     }
    // }
    // async function getPrevCaptureData(newCapture) {
    //     const prevFrames = await getFrames(cap.capId,0,"captureDate DESC",[],null,new Date(newCapture.captureDate),1);
    //     if(prevFrames.length>0) {
    //         return await getCapture(prevFrames[0].captureId);
    //     } else {
    //         return null;
    //     }
    // }

    useEffect(() => {
        let isMounted = true;
        async function accessCaptureImages() {
            try {
                var tempImages = await getCaptureFrameImages(capture.captureId);
                if(isMounted)
                    setCaptureImages(tempImages);
            } catch(e) {
                console.log(e);
            }
        }
        // async function getNextAndPrevious() {
        //     try {
        //         setNextCapture(await getNextCaptureData(capture));
        //         setPrevCapture(await getPrevCaptureData(capture));
        //     } catch(e) {
        //         console.log(e);
        //     }
        // }
        // if(capture && !prevCapture && !nextCapture) {
        //     getNextAndPrevious();
        // }
        if(capture) {
            // setIsLoading(true);
            accessCaptureImages();
            // setIsLoading(false);
        }
        if(!cap && capture) {
            async function capper() {
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== capture.capId) {
                    tempCap = await loadCap(capture.capId);
                    if(isMounted)
                        storeCap(tempCap);                    
                }
                setCap(tempCap);
            }
            capper();
        }
        return () => { isMounted = false; };
    },[capture])

    // async function getNextCapture() {
    //     if(nextCapture) {
    //         setPrevCapture(capture);
    //         setCapture(nextCapture);
    //         setNextCapture(await getNextCaptureData(nextCapture));
    //     }
    // }

    // async function getPrevCapture(cancelRequest) {
    //     if(prevCapture) {
    //         if(!cancelRequest)
    //             setNextCapture(capture);
    //         if(!cancelRequest)
    //             setCapture(prevCapture);
    //         if(!cancelRequest)
    //             setPrevCapture(await getPrevCaptureData(prevCapture));
    //     }
    // }

    // // Handle arrow key navigation
    // useEffect(() => {
    //     let cancelRequest = false;
    //     if(capture && leftPress) {
    //         if(!cancelRequest)
    //             getPrevCapture(cancelRequest);
    //     }
    //     return () => cancelRequest = true;
    // }, [leftPress]);

    // useEffect(() => {
    //     if(capture && rightPress) {
    //         getNextCapture();
    //     }
    // }, [rightPress]);

    function renderFrameImages() {
        return (
            <View style={{flex:1, flexDirection:"column", justifyContent:"center"}}>
                <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                    <View style={{width:"50%",maxWidth:"704px", padding:"5px"}}>
                        {captureImages && captureImages.house && (
                            <img src={captureImages.house.url} alt="" />
                        )}
                    </View>
                    <View style={{width:"50%",maxWidth:"704px", padding:"5px"}}>
                        {captureImages && captureImages.neighbor && (
                            <img src={captureImages.neighbor.url} alt="" />
                        )}
                    </View>
                </View>
                <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                    <View style={{width:"50%",maxWidth:"704px", padding:"5px"}}>
                        {captureImages && captureImages.street && (
                            <img src={captureImages.street.url} alt="" />
                        )}
                    </View>
                    <View style={{width:"50%",maxWidth:"704px", padding:"5px"}}>
                        {captureImages && captureImages.driveway && (
                            <img src={captureImages.driveway.url} alt="" />
                        )}
                    </View>
                </View>
            </View>
        );
    }

    function renderFrameHistory() {
        return (
            <View>
                <View style={{flex:1, flexDirection:"row", justifyContent:"space-between"}}>
                    <Button
                        bsStyle="primary"
                        bsSize={buttonSize}
                        onClick={() => props.history.push(`/caps/${cap.capId}/frames`)}
                    >
                        Frame History
                    </Button>
                    {/* <View style={{flex:1, flexDirection:"row", justifyContent:"flex-end"}}>
                        <ButtonGroup>
                            <Button
                                bsStyle="info"
                                bsSize={buttonSize}
                                onClick={getPrevCapture}
                                disabled={!prevCapture}
                            >
                                <Glyphicon glyph="arrow-left" />
                            </Button>
                            <Button
                                bsStyle="info"
                                bsSize={buttonSize}
                                onClick={getNextCapture}
                                disabled={!nextCapture}
                            >
                                <Glyphicon glyph="arrow-right" />
                            </Button>
                        </ButtonGroup>
                    </View> */}
                </View>
                {!isLoading && capture && (
                    <PageHeader style={{marginTop:"-10px"}}>
                        {/* {console.log(capture.captureDate,new Date(capture.captureDate))}
                        {console.log((new Date(capture.captureDate)).toLocaleString('en-US'))}
                        {console.log((new Date(capture.captureDate)).toLocaleString('en-US',{timeZone:'EST'}))} */}
                        {reformatDate(capture.captureDate,null)}
                    </PageHeader>
                )}
                {!isLoading && renderFrameImages()}
            </View>
        );
    }

    return (
        <div className="FrameHistory">
            {!(props.match.params.capid || getUser().role==="ADMIN") ? renderAccessDenied() : renderFrameHistory()}
        </div>
    );
}