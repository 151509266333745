import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { Checkmark } from "react-checkmark";
import { postUnsubscribe } from "../utils/bedrockAPI";


export default function Unsubscribe(props) {
    const [unsubscribed, setUnsubscribed] = useState(false);

    useEffect(() => {
        let isMounted = true;
        async function unsubscribe(userId) {
            const temp = await postUnsubscribe(userId);
            console.log(temp);
            if(temp && temp.success === true) {
                if(isMounted) setUnsubscribed(true);
            }
        }
        if(props.match.params.userid) {
            unsubscribe(props.match.params.userid);
        }
        return () => {
            isMounted = false;
        };
    },[props.match.params.userid]);

    return (
        <View style={{paddingTop:"50px",flex:1,flexDirection:"row",justifyContent:"center"}}>
            {unsubscribed && (
                <View style={{width:"400px"}}>
                    <h1>You've been unsubscribed</h1>
                    <br />
                    <Checkmark size='xlarge' color="#240058"/>
                </View>
            )}
        </View>
    )
}