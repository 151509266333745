import React, { useState, useEffect, useRef } from "react";
import { 
    PageHeader, 
    Button, 
    Glyphicon, 
    OverlayTrigger,
    Tooltip,
    DropdownButton,
    MenuItem
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {
    authorizeCompanyCam,
    removeCCCapProject,
    getSharedCaps,
    getUserIntegrations,
    getCaps
} from "../utils/bedrockAPI";
import { getUser } from "../utils/Common";
import { View } from "react-native";
import MonitorSelectionModal from "../components/MonitorSelectionModal";
import Image from "../images/company_cam.png";

export default function Integrations() {
    const [userIntegrations, setUserIntegrations] = useState(null);
    const [newConnection, setNewConnection] = useState(false);
    const [capConnection, setCapConnection] = useState(null);
    const [projectConnection, setProjectConnection] = useState(null);
    const [showMonitorSelectionWindow,setShowMonitorSelectionWindow] = useState(null);
    const [userId,setUserId] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [capShare,setCapShare] = useState(null);
    const [capsById, setCapsById] = useState(null);
    const [caps, setCaps] = useState(null);
    const componentMounted = useRef(true);

    async function fetchUserIntegrations(userId) {
        const tempUserIntegrations = await getUserIntegrations(userId);
        if(componentMounted.current)
            setUserIntegrations(tempUserIntegrations);
    }

    useEffect(() => {
        async function getCapShare(userId) {
            const tempShare = await getSharedCaps(userId);
            if(componentMounted.current)
                setCapShare(tempShare);
            const tempCaps = await getCaps();
            if(componentMounted.current)
                setCaps(tempCaps);
        }
        const user = getUser();
        if(user) {
            if(user.userId!=null) setUserId(user.userId);
            getCapShare(user.userId);
            fetchUserIntegrations(user.userId);
        }
        return () => { componentMounted.current = false; };
    },[]);

    useEffect(() => {
        if(!showMonitorSelectionWindow) {
            // console.log("refteching userIntegrations");
            fetchUserIntegrations(getUser().userId);
        }
    },[showMonitorSelectionWindow]);

    useEffect(() => {
        if(caps) {
            let tempCapsById = {}
            for(var i=0; i<caps.length; i++) {
                tempCapsById[caps[i].capid] = caps[i]
            }
            // console.log(tempCapsById)
            setCapsById(tempCapsById);
        }
    },[caps]);

    function findProjectById(projects,projectId) {
        if(projects && projects.length > 0) {
            for(var i=0; i<projects.length; i++) {
                if(projects[i].id===projectId) {
                    return projects[i];
                }
            }
        }
        return null;
    }
    async function addUserIntegration(e) {
        e.preventDefault();
        setShowMonitorSelectionWindow(true);
        setNewConnection(false);
        // if(capConnection && projectConnection) {
        //     if(userIntegrations && userIntegrations.length > 0 && userIntegrations[0].integration) {
        //         await addCCCapProject(userIntegrations[0],capConnection,projectConnection);
        //         fetchUserIntegrations(getUser().userId);
        //         setNewConnection(false);
        //     }
        // }
    }
    async function deleteCapProject(e,capProject) {
        e.preventDefault();
        if(capProject && userIntegrations && userIntegrations.length > 0 && userIntegrations[0].integration) {
            await removeCCCapProject(userIntegrations[0],capProject);
            fetchUserIntegrations(getUser().userId);
        }
    }
    const tooltip = (
        <Tooltip id="remove_connection" style={{fontSize: "24px"}}>
            Remove Connection
        </Tooltip>
    );
    return (
        <View>
            <PageHeader style={{textAlign:"center"}}>
                Integrations
            </PageHeader>
            <View>
                <View style={{flex:1,flexDirection:"row",justifyContent:"space-between"}}>
                    <img className="logo" src={Image} style={{width:"200px"}} alt="Company Cam" />
                    <View style={{paddingLeft:"10px",paddingTop:"20px"}}>
                        {userIntegrations && userIntegrations.length > 0 && userIntegrations[0].name === "CompanyCam" ? (
                            <Button
                                block
                                bsSize="large"
                                bsStyle="success"
                                disabled={true}
                            >
                                Connected
                            </Button>
                        ) : (
                            <LoaderButton
                                block
                                bsSize="large"
                                bsStyle="info"
                                isLoading={isLoading}
                                href={authorizeCompanyCam()}
                                // disabled={!validateForm()}
                            >
                                Connect Your Daily Feed to Company Cam
                            </LoaderButton>
                        )}
                    </View>
                </View>
                {
                    userIntegrations && 
                    userIntegrations.length > 0 && 
                    userIntegrations[0].name==="CompanyCam" && 
                    userIntegrations[0].integration && 
                    userIntegrations[0].integration.cap_projects &&
                    Object.keys(userIntegrations[0].integration.cap_projects).length > 0 && 
                    (
                        <View style={{paddingTop:"25px"}}>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"space-between"}}>
                                <div style={{flex:1, fontWeight:"bold",borderStyle:"solid",borderWidth:"1px",textAlign:"center"}}>
                                    Bedrock CAP
                                </div>
                                <div style={{flex:1, fontWeight:"bold",borderStyle:"solid",borderWidth:"1px",textAlign:"center"}}>
                                    Company Cam Project
                                </div>
                                <div style={{width:"50px",borderStyle:"solid",borderWidth:"1px"}}>
                                </div>
                            </View>
                            {
                            (Object.keys(userIntegrations[0].integration.cap_projects)).map((cap_project,i) => (
                                <View key={i} style={{flex:1,flexDirection:"row",justifyContent:"space-between",paddingTop:"10px"}}>
                                    <div style={{flex:1,paddingLeft:"15px"}}>
                                        {capsById && capsById[cap_project] ? capsById[cap_project].name : "nope"}
                                    </div>
                                    <div style={{flex:1}}>
                                        {
                                            (findProjectById(userIntegrations[0].integration.projects,userIntegrations[0].integration.cap_projects[cap_project].project_id)) ? 
                                            (findProjectById(userIntegrations[0].integration.projects,userIntegrations[0].integration.cap_projects[cap_project].project_id)).name :
                                            ""
                                        }
                                    </div>
                                    <div style={{width:"50px"}}>
                                        <OverlayTrigger placement={"right"} overlay={tooltip}>
                                            <Button
                                                block
                                                bsSize="small"
                                                bsStyle="danger"
                                                onClick={(e) => deleteCapProject(e,cap_project)}
                                            >
                                                <b>X</b>
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </View>
                            ))
                            }
                        </View>
                    )
                }
                {newConnection && (
                    <View style={{flex:1,flexDirection:"row",justifyContent:"space-between",paddingTop:"10px",zIndex:1000}}>
                        <View style={{flex:1}}>
                            <DropdownButton 
                                title={capConnection ? capsById[capConnection].name : "Select a CAP"}
                                id="cap-list"
                                onSelect={(e) => setCapConnection(e)}
                                style={{width:"100%"}}
                            >
                                {caps && caps.length > 0 && caps.map((cap,i) => {
                                    if(cap.status==='ACTIVE') {
                                        return <MenuItem width="100%" eventKey={cap.capid}>{cap.name}</MenuItem>
                                    }
                                })}
                            </DropdownButton>
                        </View>
                        <View style={{flex:1}}>
                            <DropdownButton
                                title={projectConnection ? findProjectById(userIntegrations[0].integration.projects,projectConnection).name : "Select a Company Cam Project"}
                                id="project-list"
                                onSelect={(e) => setProjectConnection(e)}
                                style={{width:"100%"}}
                            >
                                {userIntegrations[0].integration.projects && userIntegrations[0].integration.projects.map((project,i) => {
                                    return <MenuItem eventKey={project.id}>{project.name}</MenuItem>
                                })}                                            
                            </DropdownButton>
                        </View>
                        <div style={{width:"50px"}}>
                            <Button
                                bsStyle="success"
                                bsSize="small"
                                onClick={(e) => addUserIntegration(e)}
                                block
                            >
                                <Glyphicon glyph="check" />
                            </Button>
                        </div>
                    </View>   
                )}
                {userIntegrations && 
                userIntegrations.length > 0 && 
                userIntegrations[0].name==="CompanyCam" && 
                userIntegrations[0].integration && 
                userIntegrations[0].integration.projects &&
                userIntegrations[0].integration.projects.length > 0 &&
                !userIntegrations[0].integration.projects.every((p) => p.status==='deleted') && (
                    <View style={{flex:1,flexDirection:"row",justifyContent:"space-between",paddingTop:"10px"}}>
                        <div style={{flex:1}}></div>
                        <div style={{flex:1,textAlign:"right",paddingRight:"10px"}}><b>Connect a CAP to CC Project</b></div>
                        <div style={{felx:1,width:"50px",alignItems:"right"}}>
                            <Button
                                bsStyle="success"
                                bsSize="small"
                                block
                                disabled={newConnection}
                                onClick={(e) => setNewConnection(true)}
                            >
                                <b>+</b>
                            </Button>
                        </div>
                    </View>
                )}
                {userIntegrations && 
                userIntegrations.length > 0 && 
                userIntegrations[0].name=="CompanyCam" && 
                userIntegrations[0].integration &&
                (userIntegrations[0].integration.projects==null || userIntegrations[0].integration.projects.length===0 ||
                    userIntegrations[0].integration.projects.every((p) => p.status==='deleted')) && (
                    <div>
                        If you would like to connect a CAP to a CompanyCam project, please create a project at <a href="https://app.companycam.com/">CompanyCam</a>
                    </div>
                )}
            </View>
            {capConnection && (
                <MonitorSelectionModal 
                    show={showMonitorSelectionWindow}
                    cap={capConnection}
                    project={projectConnection}
                    integration={userIntegrations ? userIntegrations.length > 0 ? userIntegrations[0] : null : null}
                    setShowMonitorSelectionWindow={setShowMonitorSelectionWindow}
                />
            )}
        </View>
    )
}