import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./containers/Dashboard";
import Caps from "./containers/Caps";
import Camera from "./containers/Camera";
import Camera2 from "./containers/Camera2";
import Events from "./containers/Events";
import NewEvent from "./containers/NewEvent";
import Replay from "./containers/Replay";
import ProjectFiles from "./containers/ProjectFiles";
import Account from "./containers/Account";
import Snapshot from "./containers/Snapshot";
import Snapshot360 from "./containers/Snapshot360";
import Replay360 from "./containers/Replay360";
import Frames from "./containers/Frames.js";
import FrameHistory from "./containers/FrameHistory.js";
import Admin from "./containers/Admin.js";
import Zones from "./containers/Zones.js";
import Demo from "./containers/Demo.js";
import Activate from "./containers/Activate.js";
import SignupNewCap from "./containers/SignupNewCap.js"
import Labeler from "./containers/Labeler.js"
import Reservations from "./containers/Reservations.js"
import PasswordReset from "./containers/PasswordReset.js"
import AccountInfo from "./containers/AccountInfo.js";
import ChangePassword from "./containers/ChangePassword.js";
import Integrations from "./containers/Integrations.js";
import Notifications from "./containers/Notifications.js";
import Help from "./containers/Help.js";
import Unsubscribe from "./containers/Unsubscribe.js";
// import LiveFeed from "./containers/LiveFeed.js";
// import Payment from "./containers/Payment.js"

export default function Routes({ appProps }) {
  return (
    <Switch>
      <Route exact path="/" component={Home} appProps={appProps} />
      <Route exact path="/test" component={Demo} appProps={appProps} />
      <PublicRoute path="/login" exact component={Login} appProps={appProps} />
      <PublicRoute path="/signup" exact component={Signup} appProps={appProps} />
      <PublicRoute path="/passwordreset" exact component={PasswordReset} appProps={appProps} />
      <PublicRoute path="/passwordreset/:resetkey" exact component={PasswordReset} appProps={appProps} />
      <PublicRoute path="/unsubscribe/:userid" exact component={Unsubscribe} appProps={appProps} />
      {/* <PublicRoute path="/activate/74c45926-6f70-47dc-a60f-eff53fd8727b/:capid/:hash" exact component={Login} appProps={appProps} /> */}
      <PublicRoute path="/activate/:userid/:capid/:hash" exact component={Activate} appProps={appProps} />
      <PrivateRoute path="/account" exact component={Account} appProps={appProps} />
      <PrivateRoute path="/admin" exact component={Admin} appProps={appProps} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} appProps={appProps} />
      <PrivateRoute path="/caps/:capid" exact component={Caps} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/cameras/:monitorid" exact component={Camera} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/camera/:monitorid" exact component={Camera2} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/events" exact component={Events} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/events/new" exact component={NewEvent} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/events/:eventid" exact component={Replay} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/files" exact component={ProjectFiles} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/events/:eventid/snapshot" exact component={Snapshot} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/360events/:captureid/" exact component={Replay360} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/360events/:captureid/:eventid" exact component={Replay360} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/360events/:captureid/snapshot" exact component={Snapshot360} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/frames" exact component={Frames} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/frames/:captureid" exact component={FrameHistory} appProps={appProps} />
      <PrivateRoute path="/frames/:captureid" exact component={FrameHistory} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/zones" exact component={Zones} appProps={appProps} />
      <PrivateRoute path="/events/:eventid" exact component={Replay} appProps={appProps} />
      <PrivateRoute path="/signupnewcap" exact component={SignupNewCap} appProps={appProps} />
      <PrivateRoute path="/labels" exact component={Labeler} appProps={appProps} />
      <PrivateRoute path="/labels/:imageid" exact component={Labeler} appProps={appProps} />
      <PrivateRoute path="/labels/user/:userid" exact component={Labeler} appProps={appProps} />
      <PrivateRoute path="/reserve" exact component={Reservations} appProps={appProps} />
      {/* <PrivateRoute path="/account/:submenu" exact component={Account} appProps={appProps} /> */}
      {/* <PrivateRoute path="/account/:submenu/:notificationsmenu" exact component={Account} appProps={appProps} /> */}
      <PrivateRoute path="/account/info" exact component={AccountInfo} appProps={appProps} />
      <PrivateRoute path="/account/password" exact component={ChangePassword} appProps={appProps} />
      <PrivateRoute path="/account/integrations" exact component={Integrations} appProps={appProps} />
      <PrivateRoute path="/account/notifications" exact component={Notifications} appProps={appProps} />
      <PrivateRoute path="/account/notifications/:tab" exact component={Notifications} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/events/:eventid/notification/:notificationid" exact component={Replay} appProps={appProps} />
      <PrivateRoute path="/caps/:capid/events/notification/:notificationid" exact component={Events} appProps={appProps} />
      <PrivateRoute path="/help" exact component={Help} appProps={appProps} />
      <PrivateRoute path="/help/:helpkey" exact component={Help} appProps={appProps} />
      {/* <PrivateRoute path="/livefeed/:capid" exact component={LiveFeed} appProps={appProps} /> */}
      {/* <PublicRoute path="/payment" exact component={Payment} appProps={appProps} /> */}
      {/* <PrivateRoute path="/caps/:capid/cameras/:monitorid/:smallid" exact component={Camera} appProps={appProps} /> */}
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}