import React from "react";
import ZoneEditor from "../components/ZoneEditor";

export default function Demo(props) {
    return (
        <div>
            <ZoneEditor
                zone={[0,0,50,0,50,50,0,50]}
                url={"https://cms-assets.tutsplus.com/uploads/users/1251/posts/29830/preview_image/konvatutsplus.png"}
                w={400}
                h={277}
                type={"low"}
            />
        </div>
    )
}