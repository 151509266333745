import React, {useState, useEffect, Fragment } from "react";
import { 
    TabContainer, 
    Tab, 
    Nav, 
    NavItem, 
    PageHeader, 
    Button, 
    Grid, 
    Row, 
    Col, 
    Glyphicon, 
    Label, 
    FormGroup, 
    Checkbox,
    ControlLabel,
    FormControl,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { View } from "react-native";
// import LoaderButton from "../components/LoaderButton";
// import { Chart } from "react-charts";
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import renderCapManager from './Admin/CapManager';
// import renderCapManager2 from './Admin/CapManager2';
import { 
    getUserData, 
    getUserHourData, 
    getUserCapData, 
    getSharingGrid, 
    shareCapWithUser, 
    delSharing, 
    patchSharing,
    getAccessableCaps,
    getAllBuilders,
    getUsersByBuilder,
    getUserActions,
    getUserActionCount,
    getCapsByUserId,
    patchUser,
    getSharedCaps,
    getUsersBySharedOnCapsByBuilder,
    getCapsByBuilder
} from "../utils/bedrockAPI";
import { 
    getUser,
} from "../utils/Common";
import "./Account.css";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import AddBuilderModal from "../components/AddBuilderModal";

export default function Admin(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [hourData, setHourData] = useState(null);
    const [capData, setCapData] = useState(null);
    const [caps, setCaps] = useState(null);
    const [userGrid, setUserGrid] = useState(null);
    // const [showAdmin, setShowAdmin] = useState(false);
    const [builders, setBuilders] = useState(null);
    const [organizedCaps, setOrganizedCaps] = useState(null);
    const [focusedBuilder, setFocusedBuilder] = useState(null);
    const [activeTabKey, setActiveTabKey] = useState("caps");
    const [builderUsers, setBuilderUsers] = useState(null);
    const [builderUsersWithActions, setBuilderUsersWithActions] = useState(null);
    const [focusedUser, setFocusedUser] = useState(null);
    const [userChanges, setUserChanges] = useState({});
    const [builderCaps, setBuilderCaps] = useState(null);
    const [showAddBuilder, setShowAddBuilder] = useState(false);
    const [builderCapUsers, setBuilderCapUsers] = useState(null);

    function dateify(tempData) {
        for(var i=0; i<tempData.length; i++) {
            for(var j=0; j<tempData[i].data.length; j++) {
                tempData[i].data[j][0] = new Date(tempData[i].data[j][0]);
            }
        }
        // console.log(tempData);
        return tempData;
    }

    useEffect(() => {
        let isMounted = true;
        if(isMounted)
            props.setShowError(false);
        return () => { isMounted = false; };
    },[]);

    // async function accessUserGrid() {
    //     let tempGrid = await getSharingGrid();
    //     setUserGrid(tempGrid);
    // }

    useEffect(() => {
        // async function accessUserHourData() {
        //     var tempData = await getUserHourData();
        //     setHourData(tempData);
        // }
        let isMounted = true;

        async function accessUserCapData() {
            if(getUser() && getUser().role==='ADMIN') {
                var tempData = await getUserCapData();
                if(isMounted)
                    setCapData(tempData);
            }
        }

        async function accessCaps() {
            if(getUser() && getUser().role==='ADMIN') {
                var tempCaps = await getAccessableCaps();
                // var newTempCaps = [];
                // for(var i=0; i<tempCaps.length; i++) {
                //     if(tempCaps[i].status === 'ACTIVE' || tempCaps[i].status === 'BUILD') {
                //         newTempCaps.push(tempCaps[i]);
                //     }
                // }
                // console.log(tempCaps);
                tempCaps.sort((a,b) => (a.name < b.name) ? 1 : -1);
                if(isMounted)
                    setCaps(tempCaps);
            }
        }

        async function accessUserData() {
            if(getUser() && getUser().role==='ADMIN') {
                var tempData = await getUserData(false);  // Without ADMINs
                tempData = dateify(tempData);
                if(isMounted)
                    setData(tempData);
            }
        }

        async function accessBuilders() {
            if(getUser() && getUser().role==='ADMIN') {
                var tempBuilders = await getAllBuilders();
                if(isMounted)
                    setBuilders(tempBuilders);
            }
        }

        setIsLoading(true);
        accessUserData();
        // if(rerender)
        //     accessUserHourData();
        accessUserCapData();
        accessCaps();
        accessBuilders();
        // accessUserGrid();
        setIsLoading(false);
        return () => { isMounted = false; };
    }, []);

    // useEffect(() => {
    //     let isMounted = true;
    //     if(isMounted) accessUserData();
    //     return () => { isMounted = false; };
    // },[showAdmin])

    useEffect(() => {
        let isMounted = true;
        if(builders && caps && builders.length>0 && caps.length>0) {
            let tempOrganizedCaps = [];
            for(var i=0; i<builders.length; i++) {
                // Add Builder Headers
                if(builders[i].status!=='DEAD') {
                    tempOrganizedCaps.push(builders[i]);
                    // Match caps to builders
                    for(var j=0; j<caps.length; j++) {
                        if(caps[j].builderId && caps[j].builderId===builders[i].builderId) {
                            tempOrganizedCaps.push(caps[j]);
                        }
                    }
                }
            }
            let buildCaps = [];
            tempOrganizedCaps.push({"builder_id":null,"name":"Unassigned"})
            for(i=0; i<caps.length; i++) {
                let contains = false;
                // Find caps that aren't already added
                for(var j=0; j<tempOrganizedCaps.length; j++) {
                    let oc = tempOrganizedCaps[j];
                    if(oc.capId && oc.capId===caps[i].capId) {
                        contains = true;
                        break;
                    }
                }
                if(!contains) {
                    // First add the real ones
                    if(caps[i].status==='ACTIVE') {
                        tempOrganizedCaps.push(caps[i]);
                    }
                    else {
                        buildCaps.push(caps[i]);
                    }
                }
            }
            // Then add the build ones
            tempOrganizedCaps.push({"builder_id":null,"name":"NOT YET ACTIVATED"})
            for(i=0; i<buildCaps.length; i++) {
                tempOrganizedCaps.push(buildCaps[i]);
            }
            if(isMounted) {
                setOrganizedCaps(tempOrganizedCaps);
            }
        }
        return () => { isMounted = false; };
    },[builders,caps]);

    useEffect(() => {
        let isMounted = true;
        async function accessBuilderUsers() {
            var tempBuilderUsers = await getUsersByBuilder(focusedBuilder.builderId);
            if(isMounted) {
                setBuilderUsers(tempBuilderUsers);
            }
        }
        if(focusedBuilder && focusedBuilder.builderId) {
            accessBuilderUsers();
        }
        return () => { isMounted = false; };
    },[focusedBuilder]);

    useEffect(() => {
        let isMounted = true;
        async function accessUserInformation() {
            var tempBuilderUsers = JSON.parse(JSON.stringify(builderUsers));
            await Promise.all(tempBuilderUsers.map(async (user,i) => {
                const userActions = await getUserActions(user.userId);
                const userActionCount = await getUserActionCount(user.userId);
                const userCaps = await getCapsByUserId(user.userId);
                tempBuilderUsers[i].userActions = userActions;
                tempBuilderUsers[i].userActionCount = userActionCount;
                tempBuilderUsers[i].caps = userCaps;
            }));
            if(isMounted) {
                setBuilderUsersWithActions(tempBuilderUsers);
            }
            let tempBuilderCaps = {}
            for(var i=0;i<tempBuilderUsers.length;i++) {
                for(var j=0;j<tempBuilderUsers[i].caps.length;j++) {
                    if(tempBuilderCaps[tempBuilderUsers[i].caps[j].capId]==null) {
                        tempBuilderCaps[tempBuilderUsers[i].caps[j].capId] = tempBuilderUsers[i].caps[j];
                    }
                }
            }
            if(isMounted) {
                setBuilderCaps(Object.values(tempBuilderCaps));
            }
        }
        if(builderUsers && builderUsers.length > 0) {
            accessUserInformation();
        } else {
            async function accessCapBuilders() {
                var tempCaps = await getCapsByBuilder(focusedBuilder.builderId);
                if(isMounted)
                    setBuilderCaps(tempCaps);
            }
            if(focusedBuilder) {
                accessCapBuilders();
                if(isMounted)
                    setBuilderUsersWithActions([]);
            }
        }
        return () => { isMounted = false; };
    },[builderUsers]);

    useEffect(() => {
        let isMounted = true;
        async function accessBuilderCapUsers() {
            let tempBuilderCapUsers = await getUsersBySharedOnCapsByBuilder(focusedBuilder.builderId);
            for(var i=0; i<tempBuilderCapUsers.length; i++) {
                if(isMounted) {
                    const userCaps = await getSharedCaps(tempBuilderCapUsers[i].userId);
                    tempBuilderCapUsers[i].userCaps = userCaps;
                }
            }
            let reduced = [];
            if(builderUsersWithActions) {
                for(var i=0;i <tempBuilderCapUsers.length; i++) {
                    let contains = false;
                    for(var j=0; j<builderUsersWithActions.length; j++) {
                        if(tempBuilderCapUsers[i].userId === builderUsersWithActions[j].userId) {
                            contains = true;
                            break;
                        }
                    }
                    if(!contains) {
                        reduced.push(tempBuilderCapUsers[i]);
                    }
                }
            } else {
                reduced = tempBuilderCapUsers;
            }
            if(isMounted)
                setBuilderCapUsers(reduced);
        }
        if(focusedBuilder)
            accessBuilderCapUsers();
        return () => { isMounted = false; };
    },[builderUsersWithActions,focusedBuilder]);

    useEffect(() => {
        let isMounted = true;
        async function updateFocusedUser() {
            var tempFocusedUser = JSON.parse(JSON.stringify(focusedUser));
            const tempUserCaps = await getSharedCaps(focusedUser.userId);
            tempFocusedUser.userCaps = tempUserCaps;
            if(isMounted)
                setFocusedUser(tempFocusedUser);
        }
        if(focusedUser && !focusedUser.userCaps) {
            updateFocusedUser();
        }
        return () => { isMounted = false; };
    },[focusedUser])

    // const axes = useMemo(() => [
    //     { primary: true, type: 'utc', position: 'bottom' },
    //     { type: 'linear', position: 'left' }
    // ], [])

    // const hourAxes = useMemo(() => [
    //     { primary: true, type: 'linear', position: 'bottom' },
    //     { type: 'linear', position: 'left' }
    // ], [])

    // const capAxes = useMemo(() => [
    //     { primary: true, type: 'ordinal', position: 'bottom' },
    //     { type: 'linear', position: 'left' }
    // ], [])

    // const series = useMemo(() => ({
    //     type: 'line'
    // }),[]);

    // const hourSeries = useMemo(() => ({
    //     type: 'bar'
    // }),[]);

    function renderStatsMenus() {
        return (
            <View style={{flex:1, flexDirection:"column"}}>
                <TabContainer 
                    id="default" 
                    defaultActiveKey="caps"
                    activeKey={activeTabKey}
                    onSelect={(key) => setActiveTabKey(key)}
                >
                    <Row className="clearfix">
                        <Col sm={2}>
                            <Nav bsStyle="pills" stacked>
                                <NavItem eventKey="users">
                                    Login Data
                                </NavItem>
                                {/* <NavItem eventKey="userHours">
                                    User Stats By Hour
                                </NavItem> */}
                                {/* <NavItem eventKey="userCaps">
                                    Cap Stats By User
                                </NavItem> */}
                                {/* <NavItem eventKey="sharingManager" onClick={() => accessUserGrid()}>
                                    Sharing Manager
                                </NavItem> */}
                                <NavItem eventKey="caps">
                                    CAP Statuses
                                </NavItem>
                                {/* <NavItem eventKey="capManager">
                                    Cap Manager
                                </NavItem> */}
                                <NavItem eventKey="builder">
                                    Builder Info
                                </NavItem>
                                <NavItem eventKey="userInfo">
                                    User Info
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col sm={10} style={{padding:0}}>
                            <Tab.Content>
                                <Tab.Pane eventKey="users">{!isLoading && renderUsers()}</Tab.Pane>
                                {/* <Tab.Pane eventKey="userHours">{!isLoading && renderUserHours()}</Tab.Pane> */}
                                {/* <Tab.Pane eventKey="userCaps">{!isLoading && renderUserCaps()}</Tab.Pane> */}
                                {/* <Tab.Pane eventKey="sharingManager">{!isLoading && caps && renderSharingManager()}</Tab.Pane> */}
                                <Tab.Pane eventKey="caps">{!isLoading && renderCapStatuses()}</Tab.Pane>
                                {/* <Tab.Pane eventKey="capManager">{!isLoading && renderCapManager2(caps,isLoading)}</Tab.Pane> */}
                                <Tab.Pane eventKey="builder">{!isLoading && renderBuilderInfo()}</Tab.Pane>
                                <Tab.Pane eventKey="userInfo">{!isLoading && renderUserInfo()}</Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </TabContainer>
            </View>
        )
    }

    function renderUserInfo() {
        function changeUser(key,value) {
            var tempUser = JSON.parse(JSON.stringify(focusedUser));
            tempUser[key] = value;
            setFocusedUser(tempUser);
            var tempUserChanges = JSON.parse(JSON.stringify(userChanges));
            // if(value==="") value = null;
            tempUserChanges[key] = value; 
            setUserChanges(tempUserChanges);
        }
        async function updateUser(e) {
            e.preventDefault();
            // setIsLoading(true);
            // console.log(userChanges,focusedUser.userId)
            await patchUser(focusedUser.userId,userChanges);
            setUserChanges({});
            var tempBuilderUsersWithActions = JSON.parse(JSON.stringify(builderUsersWithActions));
            for(var i=0; i<tempBuilderUsersWithActions.length; i++) {
                if(tempBuilderUsersWithActions[i].userId===focusedUser.userId) {
                    tempBuilderUsersWithActions[i] = focusedUser;
                    break;
                }
            }
            setBuilderUsersWithActions(tempBuilderUsersWithActions);
            // setIsLoading(false);
        }
        function lookupAccess(capId) {
            if(focusedUser && focusedUser.userCaps) {
                for(var i=0;i<focusedUser.userCaps.length; i++) {
                    if(focusedUser.userCaps[i] && focusedUser.userCaps[i].capId===capId) {
                        return focusedUser.userCaps[i];
                    }
                }
            }
            return null;
        }

        function getBsStyle(userCap) {
            if(focusedUser && focusedUser.role==="ADMIN") {
                return "success";
            } else {
                if(userCap && userCap.accessType) {
                    if(userCap.accessType === "OWNER") {
                        return "success";
                    } else if(userCap.accessType === "VIEWER") {
                        return "info";
                    } else if(userCap.accessType === "DEMO") {
                        return "warning";
                    }
                } else {
                    return "danger";
                }
            }
        }
    
        function findUserCapIndex(uc) {
            for(var i=0; i<focusedUser.userCaps.length;i++) {
                if(focusedUser.userCaps[i].userCapId===uc.userCapId) {
                    return i;
                }
            }
        }
    
        async function handleUpdateAccess(cap) {
            // console.log(userId,cap)
            const userCap = lookupAccess(cap);
            if(userCap) {
                if(userCap.accessType==="DEMO") {
                    // set to VIEWER
                    var tempUser = JSON.parse(JSON.stringify(focusedUser));
                    const newShare = {userCapId:userCap.userCapId,accessType:"VIEWER"};
                    await patchSharing(userCap.userCapId,newShare);
                    tempUser.userCaps[findUserCapIndex(userCap)].accessType = "VIEWER";
                    setFocusedUser(tempUser);
                } else if(userCap.accessType==="VIEWER") {
                    // set to OWNER
                    tempUser = JSON.parse(JSON.stringify(focusedUser));
                    const newShare = {userCapId:userCap.userCapId,accessType:"OWNER"};
                    await patchSharing(userCap.userCapId,newShare);
                    tempUser.userCaps[findUserCapIndex(userCap)].accessType = "OWNER";
                    setFocusedUser(tempUser);
                } else if(userCap.accessType==="OWNER") {
                    // set to null
                    tempUser = JSON.parse(JSON.stringify(focusedUser));
                    await delSharing(userCap.userCapId);
                    delete tempUser.userCaps[findUserCapIndex(userCap)];
                    setFocusedUser(tempUser);
                }
            } else {    
                // Set to DEMO
                tempUser = JSON.parse(JSON.stringify(focusedUser));
                const newUserCap = (await shareCapWithUser(cap,focusedUser.userId,"DEMO")).data;
                tempUser.userCaps.push(newUserCap);
                setFocusedUser(tempUser);
            }
        }
    
        async function handleUpdatePowerNotifications(cap) {
            let userCap = lookupAccess(cap);
            if(userCap) {
                const currentPN = userCap.powerNotifications;
                var tempUser = JSON.parse(JSON.stringify(focusedUser));
                const newShare = {userCapId:userCap.userCapId,powerNotifications:!currentPN}
                await patchSharing(userCap.userCapId,newShare);
                tempUser.userCaps[findUserCapIndex(userCap)].powerNotifications = !currentPN;
                setFocusedUser(tempUser);
            }
        }

        return (
            <View>
                <Grid>
                    <Row key={-1}>
                        <Col md={10}>
                            <div className="builder">
                                <PageHeader>
                                    <View style={{flex:1,flexDirection:"row",justifyContent:"space-between"}}>
                                        <View style={{paddingRight:"10px"}}>
                                            <OverlayTrigger placement="left" overlay={(
                                                <Tooltip id="copier" style={{fontSize:"18px"}}>
                                                    Copy email
                                                </Tooltip>
                                            )}>
                                                <CopyToClipboard
                                                    text={focusedUser ? focusedUser.email : ""}
                                                >
                                                    <Button 
                                                        bsSize="large"
                                                        bsStyle="default"
                                                    ><Glyphicon glyph="duplicate" /></Button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                        </View>
                                        <div>
                                            {focusedUser && focusedUser.email}
                                        </div>
                                        <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                                            <View style={{paddingRight:"5px"}}>
                                                <OverlayTrigger placement="left" overlay={(
                                                    <Tooltip id="copier" style={{fontSize:"18px"}}>
                                                        Copy user_id
                                                    </Tooltip>
                                                )}>
                                                    <CopyToClipboard
                                                        text={focusedUser ? focusedUser.userId : ""}
                                                    >
                                                        <Button 
                                                            bsSize="large"
                                                            bsStyle="default"
                                                        ><Glyphicon glyph="duplicate" /></Button>
                                                    </CopyToClipboard>
                                                </OverlayTrigger>
                                            </View>
                                            <Button
                                                bsStyle="info"
                                                bsSize="large"
                                                onClick={() => {
                                                    setActiveTabKey("builder");
                                                }}
                                            >
                                                Builder
                                            </Button>
                                        </View>
                                    </View>
                                </PageHeader>
                            </div>
                        </Col>
                    </Row>
                    {focusedUser && (
                        <form className="info" onSubmit={(e) => updateUser(e)}>
                            <Row key={0} style={{paddingBottom:"5px"}}>
                                <Col md={5}>
                                    <FormGroup>
                                        <ControlLabel>First Name</ControlLabel>
                                        <FormControl
                                            autoFocus
                                            value={focusedUser.firstName}
                                            // disabled={true}
                                            onChange={(e) => changeUser("firstName",e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <ControlLabel>Last Name</ControlLabel>
                                        <FormControl
                                            value={focusedUser.lastName}
                                            // disabled={true}
                                            onChange={(e) => changeUser("lastName",e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row key={1} style={{paddingBottom:"10px"}}>
                                <Col md={10}>
                                    <View style={{flex:1, flexDirection:"row",justifyContent:"flex-end"}}>
                                        <LoaderButton
                                            type="submit"
                                            bsSize="large"
                                            disabled={Object.keys(userChanges).length===0}
                                            isLoading={isLoading}
                                        >
                                            Save Name Changes
                                        </LoaderButton>
                                    </View>
                                </Col>
                            </Row>
                        </form>
                    )}
                    {builderCaps && builderCaps.map((cap,i) => (
                        <Row key={i+2}>
                            <Col md={5}>
                                {cap.neighborhood ? cap.neighborhood+', '+cap.name : cap.name}
                            </Col>
                            <Col md={5}>
                                <form>
                                    <FormGroup controlId={cap.capId}>
                                        <Button 
                                            bsStyle={getBsStyle(lookupAccess(cap.capId))} 
                                            style={{width:"100%"}}
                                            disabled={focusedUser && focusedUser.role==="ADMIN"}
                                            onClick={() => handleUpdateAccess(cap.capId)}
                                        >
                                            {focusedUser && focusedUser.role==="ADMIN" ? "ADMIN" : lookupAccess(cap.capId) ? lookupAccess(cap.capId).accessType : "DENIED"}
                                        </Button> 
                                        {lookupAccess(cap.capId) && (
                                            <Checkbox checked={lookupAccess(cap.capId).powerNotifications} onChange={() => handleUpdatePowerNotifications(cap.capId)}>
                                                <Glyphicon glyph="envelope" />
                                            </Checkbox>
                                        )}
                                    </FormGroup>
                                </form>
                            </Col>
                        </Row>
                    ))}
                </Grid>
            </View>
        )
    }

    function renderBuilderInfo() {
        function convertDate(actionDate) {
            const date = new Date(actionDate);
            const today = new Date();
            let yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate()-1)
            if(date.getDate()===today.getDate()) {
                return "Today"
            } else if (date.getDate()===yesterday.getDate()) {
                return "Yesterday"
            } else {
                return date.getMonth()+1+"/"+date.getDate()+"/"+date.getFullYear();
            }
        }
        function calculateCapAccess(userId,capId) {
            for(var i=0; i<builderCapUsers.length; i++) {
                if(builderCapUsers[i].userId===userId) {
                    for(var j=0; j<builderCapUsers[i].userCaps.length; j++) {
                        if(builderCapUsers[i].userCaps[j].capId===capId) {
                            return builderCapUsers[i].userCaps[j].accessType;
                        }
                    }
                }
            }
            return "DENIED";
        }
        let builderLength = builderUsersWithActions ? builderUsersWithActions.length : 0;
        return (
            <View>
                <Grid>
                    <Row key={-2}>
                        <Col md={10}>
                            <div className="builder">
                                <PageHeader>
                                    <View style={{flex:1,flexDirection:"row",justifyContent:"space-between"}}>
                                        <div>
                                            {focusedBuilder && focusedBuilder.name}
                                        </div>
                                        <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                                            <View style={{paddingRight:"5px"}}>
                                                <OverlayTrigger placement="left" overlay={(
                                                    <Tooltip id="copier" style={{fontSize:"18px"}}>
                                                        Copy builder_id
                                                    </Tooltip>
                                                )}>
                                                    <CopyToClipboard
                                                        text={focusedBuilder ? focusedBuilder.builderId : ""}
                                                    >
                                                        <Button 
                                                            bsSize="large"
                                                            bsStyle="default"
                                                        >
                                                            <Glyphicon glyph="duplicate" />
                                                        </Button>
                                                    </CopyToClipboard>
                                                </OverlayTrigger>
                                            </View>
                                            <Button
                                                bsStyle="info"
                                                bsSize="large"
                                                onClick={() => {
                                                    setActiveTabKey("caps");
                                                }}
                                            >
                                                Statuses
                                            </Button>
                                        </View>
                                    </View>
                                </PageHeader>
                            </div>
                        </Col>
                    </Row>
                    <Row key={-1} style={{fontWeight:"bold",paddingBottom:"15px"}}>
                        <Col md={1}>
                            First
                        </Col>
                        <Col md={1}>
                            Last
                        </Col>
                        <Col md={3}>
                            Email
                        </Col>
                        <Col md={1}>
                            Access
                        </Col>
                        <Col md={2}>
                            Clicks (week)
                        </Col>
                        <Col md={1}>
                            CAPs
                        </Col>
                    </Row>
                    {builderUsersWithActions==null ? (
                        <Row key={0}>
                            <Col md={10}>
                                Loading...
                            </Col>
                        </Row>
                        ) : builderUsersWithActions.length===0 ? (
                            <Row key={0}>
                                <Col md={10}>
                                    No Users Assigend to Builder
                                </Col>
                            </Row>
                        ) : builderUsersWithActions && builderUsersWithActions.map((user,i) => (
                        <Row key={i+663453} style={user.role==="CUSTOMER" ? {} : {color:"red"}}>
                            <Col md={1} onClick={() => {
                                setActiveTabKey("userInfo")
                                setFocusedUser(user);
                            }}>
                                {user.firstName}
                            </Col>
                            <Col md={1} onClick={() => {
                                setActiveTabKey("userInfo")
                                setFocusedUser(user);
                            }}>
                                {user.lastName}
                            </Col>
                            <Col md={3} style={{fontSize:"12px"}} onClick={() => {
                                setActiveTabKey("userInfo")
                                setFocusedUser(user);
                            }}>
                                <View style={{flex:1, flexDirection:"row",justifyContent:"space-between"}}>
                                    <div>
                                        {user.email}
                                    </div>
                                    <OverlayTrigger placement="left" overlay={(
                                        <Tooltip id="copier" style={{fontSize:"10px"}}>
                                            copy email
                                        </Tooltip>
                                    )}>
                                        <CopyToClipboard
                                            text={user.email}
                                        >
                                            <Button 
                                                bsSize="small"
                                                bsStyle="default"
                                            ><Glyphicon glyph="duplicate" /></Button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                </View>
                            </Col>
                            <Col md={1}>
                                {user.userActions && user.userActions[0] && convertDate(user.userActions[0].actionDate)}
                            </Col>
                            <Col md={2}>
                                { user.userActionCount && user.userActionCount.count}
                            </Col>
                            <Col md={1}>
                                { user.caps && user.caps.length }
                            </Col>
                            <Col md={1}>
                                <OverlayTrigger placement="left" overlay={(
                                    <Tooltip id="removeUserBuilder" style={{fontSize:"12px"}}>
                                        Remove User from Builder
                                    </Tooltip>
                                )}>
                                    <Button
                                        bsStyle="danger"
                                        bsSize="small"
                                        onClick = {() => {
                                            patchUser(user.userId,{builderId:null});
                                            let thisBuilder = JSON.parse(JSON.stringify(focusedBuilder));
                                            setFocusedBuilder(null);
                                            setFocusedBuilder(thisBuilder);
                                        }}
                                    >
                                        <Glyphicon glyph="minus" />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    ))}
                    {builderCapUsers && builderCaps && builderCapUsers.length>0 && (
                        <Fragment>
                            <Row key={builderLength+1} style={{paddingTop:"15px",paddingBottom:"5px"}}>
                                <Col md={6}>
                                    <u>Other Users</u>
                                </Col>
                            </Row>
                            <Row key={builderLength+2} style={{paddingBottom:"5px"}}>
                                <Col md={3}>

                                </Col>
                                {builderCaps && builderCaps.map((cap,j) => (
                                    <Col key={j+234234} md={1} style={{fontSize:"12px"}}>
                                        <b>{cap.name}</b>
                                    </Col>
                                ))}
                            </Row>
                        </Fragment>
                    )}
                    {builderCapUsers && builderCaps && builderCapUsers.map((user,i) => (
                        <Row key={i+9392029393} style={{overflowX:scroll}}>
                            <Col md={2} onClick={() => {
                                setActiveTabKey("userInfo")
                                setFocusedUser(user);
                            }}>
                                <div style={{fontSize:"12px",color:user.role==="CUSTOMER"?"black":"red"}}>
                                    {user.email}
                                </div>
                            </Col>
                            <Col md={1}>
                                <OverlayTrigger placement="left" overlay={(
                                    <Tooltip id="addUserToBuilder" style={{fontSize:"12px"}}>
                                        Add User To Builder
                                    </Tooltip>
                                )}>
                                    <Button
                                        bsStyle="success"
                                        bsSize="small"
                                        onClick = {() => {
                                            patchUser(user.userId,{builderId:focusedBuilder.builderId});
                                            let thisBuilder = JSON.parse(JSON.stringify(focusedBuilder));
                                            setFocusedBuilder(null);
                                            setFocusedBuilder(thisBuilder);
                                        }}
                                    >
                                        <Glyphicon glyph="plus" />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                            {builderCaps && builderCaps.map((cap,j) => (
                                <Col key={j+4330423} md={1}>
                                    {calculateCapAccess(user.userId,cap.capId)}
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Grid>
            </View>
        )
    }

    function renderCapStatuses() {
        function stylePicker(health) {
            if(health==="POWER_LOSS") {
                return "danger";
            } else if(health==="ERROR") {
                return "danger";
            } else if(health==="DOWN") {
                return "default";
            } else if(health==="BATTERY") {
                return "warning";
            } else if(health==="ONLINE") {
                return "success";
            } else {
                return "default";
            }
        }
        // if(caps) {
        //     var statusSortedCaps = JSON.parse(JSON.stringify(caps));
        //     statusSortedCaps.sort((a,b) => (a.status > b.status) ? 1 : -1);
        // }
        return (
            <View>
                <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end",paddingRight:"50px"}}>
                    <OverlayTrigger placement="left" overlay={(
                        <Tooltip id="addBuilder" style={{fontSize:"18px"}}>
                            Add Builder
                        </Tooltip>
                    )}>
                        <Button
                            bsStyle="success"
                            bsSize="large"
                            onClick={() => setShowAddBuilder(true)}
                        >
                            <Glyphicon glyph="plus" />
                        </Button>
                    </OverlayTrigger>
                </View>
                <div>
                    Caps: {caps && organizedCaps? caps.length : "Loading..."}
                </div>
                <Grid>
                    {organizedCaps && organizedCaps.map((cap,i) => (
                        <Row key={i} style={{fontSize:"20px"}}>
                            {cap.capId ? (
                                <Col md={6}>
                                    <View style={{flex:1,flexDirection:"row",justifyContent:"space-between",paddingTop:"2px"}}>
                                        <LinkContainer to={`/caps/${cap.capId}`} style={{fontSize:"16px",color:cap.status==="ACTIVE" ? "black" : "red",cursor:"pointer"}}>
                                            <div style={{flex:1,color:cap.status==="ACTIVE" ? "black" : "red"}}>
                                                {cap.neighborhood ? cap.neighborhood+", "+cap.name : cap.name}
                                            </div>
                                        </LinkContainer>
                                        <div>
                                            <div>
                                                {cap.architecture.match(/2CAM/) && "(2CAM) "}
                                                {cap.architecture.match(/4CAM/) && "(4CAM) "}
                                                {cap.port.toString()+" "}
                                                <CopyToClipboard
                                                    text={cap.capId}
                                                >
                                                    <Button 
                                                        bsSize="small"
                                                        bsStyle="default"
                                                    ><Glyphicon glyph="duplicate" /></Button>
                                                </CopyToClipboard>  
                                            </div>
                                        </div>
                                    </View>
                                </Col>
                            ) : (
                                <Col md={8} style={i>0?{paddingTop:"10px",paddingRight:0,paddingLeft:0}:{paddingRight:0,paddingLeft:0}}>
                                    <View>
                                        {cap.builderId ? (
                                            <Button
                                                onClick={() => {
                                                    setFocusedBuilder(cap);
                                                    setBuilderUsersWithActions(null);
                                                    setBuilderCapUsers(null);
                                                    setBuilderCaps(null);
                                                    setActiveTabKey("builder");
                                                }}
                                                style={{width:"100%",display:"block"}}
                                            >
                                                <b>{cap.name}</b>
                                            </Button>
                                        ) : (
                                            <View style={{flex:1, flexDirection:"row",justifyContent:"space-around"}}>
                                                <b>
                                                    {cap.name}
                                                </b>
                                            </View>
                                        )}
                                    </View>
                                </Col>
                            )}
                            {cap.capId ? (
                                <Col md={2} style={{paddingTop:"5px"}}> 
                                    <Label bsStyle={stylePicker(cap.health)} style={{fontSize:"16px"}}>
                                        {cap.health}
                                    </Label>
                                </Col>
                            ) : (
                                <Col md={3}>
                                </Col>
                            )}
                        </Row>
                    ))}
                </Grid>
            </View>
        )
    }

    // function renderSharingManager() {

    //     return (
    //         <div className="wrapper"> {/*style={{ width: "70vw", height: "70vh", overflow: "auto" }}>*/}
    //             {/* <AutoResizer>
    //                 {({ width, height }) => (
    //                 <BaseTable fixed columns={capRow} data={userGrid ? userGrid : []} width={width} height={height} >

    //                 </BaseTable>
    //                 )}  
    //             </AutoResizer> */}
    //             <table className="table table-bordered">
    //                 <thead>
    //                     <tr>
    //                         {/* <th style={{position:"sticky",top:"0px", background:"white",zIndex:9999,left:"0px"}}></th> */}
    //                         <th></th>
    //                         {caps && caps.map((cap,i) => (
    //                             // <th key={i} style={{fontSize:"10px",position:"sticky",top:"0",background:"white",zIndex:9999}}>
    //                             <th key={i} style={{fontSize:"10px"}}>
    //                                 {cap.neighborhood ? cap.name+", "+cap.neighborhood : cap.name}
    //                             </th>
    //                         ))}
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {userGrid && Object.keys(userGrid).map((userId,i) => (
    //                         <tr key={i}>
    //                             {/* <td style={{fontSize:"10px",left:"0",background:"white",zIndex:9999}}> */}
    //                             <td style={{fontSize:"10px"}}>
    //                                 {userGrid[userId].email}
    //                             </td>
    //                         {caps && caps.map((cap,j) => (
    //                             <td key={j}>
    //                                 <form>
    //                                     <FormGroup controlId={userId+cap.capId}>
    //                                         <Button 
    //                                                 bsStyle={getBsStyle(lookupAccess)} 
    //                                                 style={{width:"100%"}}
    //                                                 disabled={userGrid[userId].role==="ADMIN"}
    //                                                 onClick={() => handleUpdateAccess(userId,cap.capId)}
    //                                             >
    //                                             {userGrid[userId].role==="ADMIN" ? "ADMIN" : userGrid[userId][cap.capId] ? userGrid[userId][cap.capId].accessType : "DENIED"}
    //                                         </Button> 
    //                                         {userGrid[userId][cap.capId] && (
    //                                             <Checkbox checked={userGrid[userId][cap.capId].powerNotifications} onChange={() => handleUpdatePowerNotifications(userId,cap.capId)}>
    //                                                 <Glyphicon glyph="envelope" />
    //                                             </Checkbox>
    //                                         )}
    //                                     </FormGroup>
    //                                 </form>
    //                             </td>
    //                         ))}
    //                         </tr>
    //                     ))}
    //                 </tbody>
    //             </table>
    //         </div>
    //     )
    // }

    // function renderUserCaps() {
    //     return (
    //         <View style={{maxHeight:"600px"}}>
    //             { capData && axes && (
    //                 <React.Fragment>
    //                     <PageHeader>Cap Access By User</PageHeader>
    //                     {/* <Chart data={capData} axes={capAxes} series={hourSeries} tooltip /> */}
    //                 </React.Fragment>
    //             )}
    //         </View>
    //     )
    // }

    // function renderUserHours() {
    //     return (
    //         <View style={{maxHeight:"600px"}}>
    //             { hourData && axes && (
    //                 <React.Fragment>
    //                     <PageHeader>User Access By Hour</PageHeader>
    //                     {/* <Chart data={hourData} axes={hourAxes} series={hourSeries} tooltip /> */}
    //                 </React.Fragment>
    //             )}
    //         </View>
    //     )
    // }

    function renderUsers() {
        var todayLogins = {};
        var yesterdayLogins = {}
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate()-1);
        const twoDays = new Date();
        twoDays.setDate(twoDays.getDate()-2);
        if(data) {
            for(var i=0; i<data.length; i++) {
                for(var j=0; j<data[i].data.length; j++) {
                    const d = new Date(data[i].data[j][0]);
                    if(d > yesterday) {
                        todayLogins[data[i].label] = data[i].data[j][1];
                    }
                    if(d > twoDays && d < yesterday) {
                        yesterdayLogins[data[i].label] = data[i].data[j][1];
                    }
                }
            }
        }
        return (
            <View style={{maxHeight:"600px", maxWidth: "800px"}}>
                {todayLogins && (
                    <Grid style={{paddingBottom:"10px"}}>
                        <Row key={-1} style={{paddingBottom:"5px"}}>
                            <Col md={10}>
                                <b>These users logged in today:</b>
                            </Col>
                        </Row>
                        {Object.keys(todayLogins).sort().map((email,i) => (
                            <Row key={i}>
                                <Col md={5} style={{paddingLeft:"20px",backgroundColor:i%2==0 ? "#EEEEEE" : "#FFFFFF"}}>
                                    {email}
                                </Col>
                                <Col md={1} style={{backgroundColor:i%2==0 ? "#EEEEEE" : "#FFFFFF"}}>
                                    {todayLogins[email]}
                                </Col>
                            </Row>
                        ))}
                </Grid>
                )}
                {yesterdayLogins && (
                    <Grid>
                        <Row key={-1} style={{paddingBottom:"5px"}}>
                            <Col md={10}>
                                <b>These users logged in yesterday:</b>
                            </Col>
                        </Row>
                        {Object.keys(yesterdayLogins).sort().map((email,i) => (
                            <Row key={i}>
                                <Col md={5} style={{paddingLeft:"20px",backgroundColor:i%2==0 ? "#EEEEEE" : "#FFFFFF"}}>
                                    {email}
                                </Col>
                                <Col md={1} style={{backgroundColor:i%2==0 ? "#EEEEEE" : "#FFFFFF"}}>
                                    {yesterdayLogins[email]}
                                </Col>
                            </Row>
                        ))}
                    </Grid>
                )}
                {/* <Button
                    bsStyle="info"
                    bsSize="large"
                    onClick={() => setShowAdmin(!showAdmin)}
                >
                    <Glyphicon glyph={showAdmin ? "eye-open" : "eye-close"} style={{paddingRight:"5px"}} />Admins
                </Button> */}
                {/* { data && axes && (
                    <React.Fragment>
                        <PageHeader>User Access By Date</PageHeader>
                        <Chart data={data} axes={axes} series={series} tooltip />
                    </React.Fragment>
                )} */}
            </View>
        )
    }

    function renderBadAccess() {
        props.history.push(`/dashboard`)
    }

    return (
        <div className="Account" style={{paddingTop:"10px"}}>
            {getUser().role === "ADMIN" ? renderStatsMenus() : renderBadAccess()}
            {getUser().role === "ADMIN" && (
                <AddBuilderModal 
                    show={showAddBuilder}
                    setShowAddBuilder={setShowAddBuilder}
                />
            )}
        </div>
    )
}